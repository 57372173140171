import React, { useEffect, useRef, useState } from 'react';


const Carousel = ({
  onClickItem,
}) => {
  const row1Ref = useRef(null);

  const [data, setData] = useState(null);

  const loadData = () => {
    fetch(
      'https://realsmart.global.ssl.fastly.net/line.json'
    )
    .then(resp => {
      return resp.json();
    }).then(json => {
      setData(json);
    }).catch(err => {
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const scroll = (ref, speed) => {
    if (ref.current) {
      ref.current.scrollLeft += speed;
      if (speed > 0 && ref.current.scrollLeft >= ref.current.scrollWidth - ref.current.clientWidth) {
        ref.current.scrollLeft = 0;
      } else if (speed < 0 && ref.current.scrollLeft <= 0) {
        ref.current.scrollLeft = ref.current.scrollWidth - ref.current.clientWidth;
      }
    }
  };

  useEffect(() => {
    const interval1 = setInterval(() => scroll(row1Ref, -1), 22);
    return () => {
      clearInterval(interval1);
    };
  }, []);

  const ellipsize = (str) => str.slice(0, 28) + (str.length > 28 ? '…' : '');

  const now = new Date();

  const formatDaysAgo = (ts) => {
    const diff = now.getTime() - ts;
    const daysDiff = Math.round(diff / (24 * 3600000));
    if (daysDiff > 30) {
      const isLastMonth = (now.getMonth() - new Date(ts).getMonth()) % 12 === 1;
      const mth = Math.floor(daysDiff / 30);
      return isLastMonth ? 'last month' : `${mth.toFixed(0)} month${mth > 1 ? 's' : ''} ago`;
    }
    if (daysDiff > 12) {
      return `${Math.round(daysDiff / 7).toFixed(0)} weeks ago`;
    }
    return daysDiff > 0 ? `${daysDiff.toLocaleString()} day${daysDiff > 1 ? 's' : ''} ago` : ' yesterday';
  };

  const formatMoney = (value) => {
    if (value > 1000000) return `$${(value / 1000000).toFixed(2)}M`;
    if (value > 1000) return `$${(value / 1000).toFixed(1)}K`;
    return `$${value}`;
  };

  const generateText = (text) => {
    if (text.t === 'ns')
      return <span className="lh-15">
        <span className="text-blue-1 fw-500">{ellipsize(text.n)}</span>
        <br />{`${text.c ?? 1} new sale${text.c ? 's' : ''} ${formatDaysAgo(text.ts)} (${formatMoney(text.min)}${text.max ? ` - ${formatMoney(text.max)}` : ''})`}
      </span>;
    if (text.t === 'rs')
      return <span className="lh-15">
        <span className="text-blue-1 fw-500">{ellipsize(text.n)}</span>
        <br />{`${text.c ?? 1} resale${text.c ? 's' : ''} ${formatDaysAgo(text.ts)} (${formatMoney(text.min)}${text.max ? ` - ${formatMoney(text.max)}` : ''})`}
      </span>;
    if (text.t === 'ss')
      return <span className="lh-15">
        <span className="text-blue-1 fw-500">{ellipsize(text.n)}</span>
        <br />{`${text.c ?? 1} sub sale${text.c ? 's' : ''} ${formatDaysAgo(text.ts)} (${formatMoney(text.min)}${text.max ? ` - ${formatMoney(text.max)}` : ''})`}
      </span>;
    if (text.t === 'r')
      return <span className="lh-15">
        <span className="text-blue-1 fw-500">{ellipsize(text.n)}</span>
        <br />{`${text.c ?? 1} rental${text.c ? 's' : ''} ${formatDaysAgo(text.ts)} (${formatMoney(text.min)}${text.max ? ` - ${formatMoney(text.max)}` : ''} monthly rent)`}
      </span>;
    if (text.t === 'p')
      return <span className="lh-15">
        <span className="text-blue-1 fw-500">{ellipsize(text.n)}</span>
        <br />
        <span className={text.p > 0 ? 'text-green-2' : 'text-red-1'}>{text.p > 0 ? '+' : ''}{text.p}%</span>
        {` annualized profit ${formatDaysAgo(text.ts)}`}
      </span>;
    return ellipsize(text.n);
  };

  const renderItems = (items) => {
    return items.map((item, i) =>
      item.t !== null ? (
        <div
          key={i}
          className={`carousel-item lp-intro-card border-light lh-12 px-10 py-10 text-dark text-12 text-center${onClickItem && item.s ? ' cursor-pointer' : ''}`}
          onClick={() => onClickItem?.(item)}
        >
          {generateText(item)}
        </div>
      ) : (
        <div
          key={i}
          className={`carousel-item carousel-sp lp-intro-card border-light lh-12 px-10 py-10 text-dark fw-500 text-12 text-center${onClickItem && item.s ? ' cursor-pointer' : ''}`}
        >
          {item.n}
        </div>
      )
    );
  };

  if (!data) {
    return (<></>);
  }

  return (
    <div className="carousel-row" ref={row1Ref}>
      {renderItems(data)}
    </div>
  );
};

export default Carousel;
