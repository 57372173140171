import PropNotificationRow from "./PropNotificationRow";

const PropNotifications = () => {
  return (
    <>
      <div className="like-props">
        <PropNotificationRow />
      </div>
    </>
  );
};

export default PropNotifications;
