import PropTypes from 'prop-types';

const TableLayoutItem = ({ item, dragHandleProps }) => {
  const { onMouseDown, onTouchStart } = dragHandleProps;

  return (
    <div
      className="justify-content-start table-layout-line text-14 mr-40 ml-40"
      style={{
        border: "1px solid rgba(0,0,0,0.1)",
        borderRadius: "12px",
        margin: "4px",
        padding: "6px 10px",
        background: "#fff",
        userSelect: "none"
      }}
    >
      <div
        className="d-flex table-layout-drag"
        onTouchStart={(e) => {
          e.preventDefault();
          document.body.style.overflow = "hidden";
          onTouchStart(e);
        }}
        onMouseDown={(e) => {
          document.body.style.overflow = "hidden";
          onMouseDown(e);
        }}
        onTouchEnd={() => {
          document.body.style.overflow = "visible";
        }}
        onMouseUp={() => {
          document.body.style.overflow = "visible";
        }}
      >
        <input
          className="table-layout-check"
          type="checkbox"
          checked={item.enabled}
          onChange={e => item.toggler(e.target.checked, item.Header)}
        />
        <div className="ml-20 mr-auto table-layout-drag table-layout-p">
          {item.Header}
        </div>
        <i className="icon-up-down table-layout-t" />
      </div>
    </div>
  );
};

TableLayoutItem.propTypes = {
  item: PropTypes.object.isRequired,
  dragHandleProps: PropTypes.object,
};

export default TableLayoutItem;
