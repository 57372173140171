import { isAdminTier, isPremium } from "@/utils/user";

const UserProfile = ({
  user
}) => {
  const userDisplayName = user?.claims?.name;
  const userId = user?.claims?.user_id;
  const displayImgUrl = user?.claims?.picture;

  const isProUser = isPremium(user);
  const isAdminUser = isAdminTier(user?.claims);

  return (
    <div className="px-10">
      <div className="d-flex">
        <img
          className="p-2"
          src={displayImgUrl ? displayImgUrl : '/img/general/icons-xl/house.png'}
          height={100}
          width={100}
          style={{ borderRadius: '50%' }}
        />
        <div className="p-2 px-10">
          <span className="text-18 fw-600">{userDisplayName}</span>
          <br />
          <span className="text-12 fw-500 text-white bg-green-2 px-10 py-5 rounded-100">
            <i className="icon-user-2 mr-5" />
            {
              isAdminUser
                ? 'Administrator'
                : (isProUser ? 'VIP Member' : 'Basic Member')
            }
          </span>
        </div>
      </div>

      <div className="px-10 py-30 fw-600 text-14 text-center">
        New features coming soon
      </div>
    </div>
  );
};

export default UserProfile;
