import { Layer, Source } from "react-map-gl/maplibre";
import * as turf from '@turf/turf';
import { radiusLabelStyle, radiusMaskStyle } from "@/utils/map";

const RadiusOverlay = ({
  id,
  target,
  altRadius,
  radiusKm = 0,
}) => {
  const altRadiusKm = altRadius ? (altRadius / 1000 + 0.01) : altRadius;
  const effectiveRadiusKm = id === 'fine'
    ? (radiusKm < 1 ? radiusKm : 0)
    : (altRadiusKm ? altRadiusKm : radiusKm);

  return (
    <>
      <Source id={`mask-data-${id ?? radiusKm}`} type="geojson" data={turf.featureCollection([
        turf.polygon([[
          [-180, -90],
          [-180, 90],
          [180, 90],
          [180, -90],
          [-180, -90]
        ], turf.circle(
          [ target.lng, target.lat ], effectiveRadiusKm,
          { units: 'kilometers' }
        ).geometry.coordinates[0]])
      ])}>
        <Layer {...radiusMaskStyle(radiusKm)} />
      </Source>

      <Source id={`mask-label-${id ?? radiusKm}`} type="geojson" data={{
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: turf.destination(
                [ target.lng, target.lat ], effectiveRadiusKm, 0,
                { units: 'kilometers' }
              ).geometry.coordinates
            },
            properties: {
              label: radiusKm > 0 ? `approx ${radiusKm < 1 ? `${radiusKm * 1000}m` : `${radiusKm}km`} radius` : ''
            }
          }
        ]
      }}>
        <Layer {...radiusLabelStyle(radiusKm)} />
      </Source>
    </>
  );
};

export default RadiusOverlay;
