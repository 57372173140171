import { getJsonFile } from "@/utils/api";
import {
  MAP_MODE_HDB,
  decompressGroupCompData,
  decompressHdbGroupCompData,
  getPropertyFileName
} from "@/utils/map";
import { useEffect, useState } from "react";
import CompareView from "./CompareView";

const CompareTab = ({
  user,
  data,
  target,
  screenDim,
  isMaximized,
  goToProperty,
  setFocusOn,
  compareList,
  setCompareList,
  viewComparePro,
  getProjectLabel,
  onCompareSearch
}) => {
  const [loading, setLoading] = useState(true);
  const [compData, setCompData] = useState({ p: {}, t: {}, d: {}, s: {} });

  const loadGroupData = () => {
    setLoading(true);

    let foldername = '';
    let filename = '';
    if (target.mode === MAP_MODE_HDB) {
      foldername = 'ch';
      filename = target.id;
    } else {
      foldername = `c/${getPropertyFileName(target.id)}`;
      filename = target.projectId;
    }

    getJsonFile(foldername, filename, json => {
      if (target.mode === MAP_MODE_HDB) {
        setCompData(decompressHdbGroupCompData(json));
      } else {
        setCompData(decompressGroupCompData(json));
      }
      
      setLoading(false);
    }, err => {
      setLoading(false);
    });
  };

  useEffect(() => {
    loadGroupData();
  }, [target]);

  return (
    <CompareView
      user={user}
      data={data}
      target={target}
      loading={loading}
      setLoading={setLoading}
      compData={compData}
      setCompData={setCompData}
      screenDim={screenDim}
      isMaximized={isMaximized}
      goToProperty={goToProperty}
      setFocusOn={setFocusOn}
      compareList={compareList}
      setCompareList={setCompareList}
      viewComparePro={viewComparePro}
      getProjectLabel={getProjectLabel}
      onCompareSearch={onCompareSearch}
    />
  );
};

export default CompareTab;
