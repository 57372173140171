import { useCallback, useState } from "react";
import { debounce } from 'lodash';

import { DEBOUNCE_TIMING, searchQuery } from "@/utils/api";
import LocationSearch from "@/components/mapv2/search/LocationSearch";

const FilterBox = ({
  onSearch,
  hasSearchResults,
  style = {},
  hint = null,
  filterType = null,
  selectedLocation = null,
  onClearSelectedLocation = null,
  allowModeSwitch = true
}) => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const onInputSearch = (value) => {
    setLoading(true);
    searchQuery(value.trim(), 20, (data) => {
      if (data) {
        setResults(data.sort((a, b) => a.type.localeCompare(b.type)));
      }
      setLoading(false);
      hasSearchResults?.(data.length > 0);
    }, () => {
      setLoading(false);
    }, filterType, allowModeSwitch ? null : 'subtype:!=HDB');
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebouncedSearch = useCallback(debounce(onInputSearch, DEBOUNCE_TIMING), []);

  const onInput = (item) => {
    setSearchValue(item);
    if (item?.length >= 3) onDebouncedSearch(item);
    else hasSearchResults?.(false);
  };

  const onSelect = (item) => {
    onSearch(item);
    setSearchValue('');
    hasSearchResults?.(false);
  };

  const onKeyDown = () => {
    if (results?.length > 0) {
      onSearch(results[0]);
      setSearchValue('');
      hasSearchResults?.(false);
    }
  };

  return (
    <>
      <div className="mainSearch bg-white rounded-4 proj-search noselect" style={style}>
        <div className="button-grid items-center d-flex justify-content-between">
          <div className="p-2 flex-fill">
            <LocationSearch
              searchValue={searchValue}
              results={results}
              loading={loading}
              onInput={onInput}
              onSelect={onSelect}
              onKeyDown={onKeyDown}
              hint={hint}
              selectedLocation={selectedLocation}
              onClearSelectedLocation={onClearSelectedLocation}
              dropdownHidden={() => hasSearchResults?.(false)}
            />
          </div>

          <div className="button-item h-full py-0 px-0 mt-0 mb-0">
            <button
              className="button -dark-1 py-15 px-20 h-full col-12 rounded-0 bg-blue-1 text-white"
              onClick={onKeyDown}
            >
              <i className="icon-search text-20 mr-10" />
              Search
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterBox;
