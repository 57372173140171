// import { useEffect, useState } from "react";
import ScrollDownButton from "./ScrollDownButton";
import { useNavigate } from "react-router-dom";
import LandingFilterBox from "./LandingFilterBox";
import { logNameSearch, trackEvent } from "@/utils/api";
import Carousel from "./Carousel";
import { MAP_MODE_CONDO, MAP_MODE_HDB, isHdbType } from "@/utils/map";
import BotPanel from "../mapv2/BotPanel";
import {
  LOCATION_PROPERTY,
  LOCATION_SCHOOL,
  LOCATION_STATION,
  LOCATION_UPCOMING
} from "@/utils/areas";

const IntroPage = ({
  onNextPage,
  session,
  allowModeSwitch
}) => {
  const navigate = useNavigate();

  const onClickItem = (item) => {
    if (item.s) {
      navigate(`/map?s=${encodeURIComponent(item.n)}`);
    }
  };

  const onSearchLocation = (searchTerm) => {
    logNameSearch('LANDING', session, searchTerm.name);
    
    if (searchTerm.type === LOCATION_PROPERTY) {
      const isHdb = isHdbType(searchTerm.subtype);
      
      trackEvent(`landing_search_${isHdbType ? 'hdb' : 'private'}_${searchTerm.marker}`);

      const url = `/map?id=${
        isHdb
          ? searchTerm.store
          : encodeURIComponent(searchTerm.marker)
      }${
        isHdb
          ? ''
          : `&p=${searchTerm.store}`
      }&mode=${isHdb ? MAP_MODE_HDB: MAP_MODE_CONDO}`;
      navigate(url);
    } else if (searchTerm.type === LOCATION_SCHOOL) {
      trackEvent(`landing_search_school_${searchTerm.names[0]}}`);

      const url = `/map?sch=${searchTerm.store}&mode=c`;
      navigate(url);
    } else if (searchTerm.type === LOCATION_STATION) {
      trackEvent(`landing_search_station_${searchTerm.marker}`);

      const url = `/map?stn=${searchTerm.store}&mode=c`;
      navigate(url);
    } else if (searchTerm.type === LOCATION_UPCOMING) {
      trackEvent(`landing_search_upcoming_${searchTerm.marker}`);

      const url = `/map?new=${searchTerm.store}`;
      navigate(url);
    }
  };

  return (
    <div className="lp-full lp-intro gif-bg">
      <div className="lp-intro-container watermark">

        <div className="lp-intro-content d-flex justify-content-center flex-column align-items-center mb-80">
          
          {/* <div className="intro-type-wrapper">
            <p className="intro-typing">Search for <span className="text-blue-1">Profitable</span> Property in SG</p>
          </div> */}

          <img className="landing-logo mt-10" loading="lazy" src="/img/general/animhome2.gif" alt="REALSMART.SG" />

          <LandingFilterBox
            onSearchLocation={onSearchLocation}
            allowModeSwitch={allowModeSwitch}
            hideClickHint
          />

          <div className="mt-10" />

          {/* {!loading && data && <div className="lp-intro-card-container pt-30 mt-10 mb-40">
            <FlyInOutText
              texts={data}
              onClickItem={onClickItem}
            />
          </div>}

          {loading && <LandingLoader />} */}

          <Carousel
            onClickItem={onClickItem}
          />
          <br />

          {/* <Link
            className="button -sm -dark-1 bg-blue-1 text-white text-16 px-20 py-10 landing-btn mt-40 mb-20"
            to="/search"
          >
            GET STARTED
          </Link> */}
          {/* <div className="text-12">
            <Link
              to="/terms?t=tos"
              className="text-blue-1"
              target="_blank"
            >
              Terms of Service
            </Link> · <Link
              to="/terms?t=privacy"
              className="text-blue-1"
              target="_blank"
            >
              Privacy Policy
            </Link>
          </div> */}
        </div>

        <ScrollDownButton onNextPage={onNextPage} showHint dark />
      </div>

      <BotPanel
        position={{
          bottom: '92px',
          right: '10px'
        }}
      />
    </div>
  );
};

export default IntroPage;
