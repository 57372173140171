import { useState } from "react";
import FairValue from "./Nearby/FairValue";
import { noSelectClass } from "@/utils/user";
import SimilarPropsTable from "./Table/SimilarPropsTable";
import NearbySchools from "./Nearby/NearbySchools";
import NearbyStations from "./Nearby/NearbyStations";
import { MAP_MODE_HDB, getProjectLabel } from "@/utils/map";
import TabButtons from "./TabButtons";
import HdbSchools from "./Nearby/HdbSchools";
import HdbStations from "./Nearby/HdbStations";
import NearbyTransactions from "./Nearby/NearbyTransactions";

const NEARBY_OPTIONS = [
  { id: 'schools', label: 'Schools'},
  { id: 'stations', label: 'MRT/LRT' },
  { id: 'properties', label: 'Similar Properties' },
  { id: 'tx', label: 'Transactions' }
];

const NearbyTab = ({
  user,
  session,
  data,
  mapData,
  basicData,
  detailData,
  goToProperty,
  goToHdbBlock,
  isMaximized,
  focus,
  setFocus,
  target,
  handleExpand,
  nearbyTxs,
  loadNearbyTxsData
}) => {
  const [display, setDisplay] = useState(NEARBY_OPTIONS[0].id);

  const property = {
    ...mapData,
    ...basicData,
    ...detailData,
    fairPsf: data.fairPsf
  };

  const goToPropertyFromRow = (data) => {
    const idx = data.cell.row.index;
    if (target.mode === MAP_MODE_HDB) {
      goToHdbBlock(data.data[idx].postal);
    } else {
      goToProperty(data.data[idx].marker, data.data[idx].projectId);
    }
  };

  const dateStrConvert = (dateStr) => {
    const chunks = dateStr.split('-');
    const day = chunks[0];
    const month = chunks[1];
    const year = chunks[2];
    return new Date(`${year}-${month}-${day}`).getTime();
  };

  const isHdb = target.mode === MAP_MODE_HDB;

  return (
    <>
      <TabButtons
        tabs={NEARBY_OPTIONS}
        selectedTab={display}
        setSelectedTab={setDisplay}
        session={session}
        markerName={target.id}
        projectName={target.project}
        onSwitchTab={() => {
          // reset focus from other tabs
          setFocus({ target });
        }}
      />

      {display === 'schools' && !isHdb
        && <section className="px-5">
          <div className={`${noSelectClass(user)}`}>
            <NearbySchools
              blocks={data.blocks}
              schools={data.schools}
              focus={focus}
              setFocus={setFocus}
              isMaximized={isMaximized}
              handleExpand={handleExpand}
            />
          </div>
        </section>
      }

      {display === 'schools' && isHdb
        && <section className="px-5">
          <div className={`${noSelectClass(user)}`}>
            <HdbSchools
              target={target}
              schools={data.schools}
              focus={focus}
              setFocus={setFocus}
              isMaximized={isMaximized}
              handleExpand={handleExpand}
            />
          </div>
        </section>
      }

      {display === 'stations' && !isHdb
        && <section className="px-5">
          <div className={`${noSelectClass(user)}`}>
            <NearbyStations
              blocks={data.blocks}
              stations={data.stations}
              focus={focus}
              setFocus={setFocus}
              isMaximized={isMaximized}
              handleExpand={handleExpand}
            />
          </div>
        </section>
      }

      {display === 'stations' && isHdb
        && <section className="px-5">
          <div className={`${noSelectClass(user)}`}>
            <HdbStations
              target={target}
              stations={data.stations}
              focus={focus}
              setFocus={setFocus}
              isMaximized={isMaximized}
              handleExpand={handleExpand}
            />
          </div>
        </section>
      }

      {display === 'properties'
        && <section className={`mb-20 pt-10 modal-table${isMaximized ? '-full' : ''}`}>
          <FairValue data={property} />

          <div className={`data-table mt-20 pt-10 border-top-light ${noSelectClass(user)}`}>
            <div className="d-flex">
              <div className="p-2">
                <h3 className="text-15 fw-500">Compare Similar Properties</h3>
              </div>
            </div>

            <SimilarPropsTable
              property={property}
              data={
                data.comps
                  .map(c => ({
                    ...c,
                    label: getProjectLabel(c.marker, c.project)
                  }))
              }
              goToProperty={goToPropertyFromRow}
              target={target}
            />
          </div>
        </section>
      }

      {display === 'tx'
        && <section className={`mb-20 pt-10 modal-table${isMaximized ? '-full' : ''}`}>
          <NearbyTransactions
            user={user}
            target={target}
            transactions={nearbyTxs?.map(row => ({
              ...row,
              'sale_date': dateStrConvert(row.saleDate),
              'transacted_price': parseInt(row.price),
            })).sort((a, b) => b.sale_date - a.sale_date)}
            // transactions={data.nearbyTxs.map(row => ({
            //   ...row,
            //   'sale_date': dateStrConvert(row.saleDate),
            //   'transacted_price': parseInt(row.price),
            // })).sort((a, b) => b.sale_date - a.sale_date)}
            goToProperty={goToPropertyFromRow}
            loadNearbyTxsData={loadNearbyTxsData}
            focus={focus}
            setFocus={setFocus}
          />
        </section>
      }
    </>
  );
};

export default NearbyTab;
