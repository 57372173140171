import Loader from "@/components/common/Loader";
import DistributionChart from "@/components/compare/DistributionChart";
import MainChart from "@/components/compare/MainChart";
import MultiChart from "@/components/compare/MultiChart";
import MultiChartSelector from "@/components/compare/MultiChartSelector";
import {
  getJsonFile,
  trackEvent
} from "@/utils/api";
import {
  AGG_TYPE_AVG,
  AGG_TYPE_COUNT,
  INTERVAL_TYPE_MONTH,
  INTERVAL_TYPE_YEAR,
  MULTICHART_SUBTYPE_BARS,
  MULTICHART_SUBTYPE_LINES,
  PERIOD_TYPE_10Y,
  PERIOD_TYPE_12M,
  PERIOD_TYPE_ALL,
  RESOLUTION_TYPE_HIGH,
  RESOLUTION_TYPE_LOW,
  VALUE_TYPE_COUNT,
  VALUE_TYPE_PERC,
  VALUE_TYPE_PRICE,
  generateMultiPropDistributionChart,
  generateMultiPropMultiChart
} from "@/utils/chart";
import {
  MAP_MODE_HDB,
  decompressHdbSingleCompData,
  decompressSingleCompData,
  getPropertyFileName
} from "@/utils/map";
import { checkMediaQuery, noSelectClass } from "@/utils/user";
import { useState } from "react";
import { Tooltip } from "react-tooltip";
import SearchCompareProp from "./Compare/SearchCompareProp";
import { getColor } from "@/utils/colors";
import { dateStrToMsec, formatShortDate } from "@/utils/time";
import { LOCATION_PROPERTY } from "@/utils/areas";
import CompareTable from "./Table/CompareTable";
import { ReactSVG } from "react-svg";
import SimilarPropsTable from "./Table/SimilarPropsTable";
import CompRecentTxTable from "./Table/CompRecentTxTable";

const PERIOD_SELECTIONS = [
  {
    label: 'All Time',
    value: PERIOD_TYPE_ALL,
  },
  {
    label: 'Past 12 Months',
    value: PERIOD_TYPE_12M,
  },
  {
    label: 'Past 10 Years',
    value: PERIOD_TYPE_10Y,
  },
];

const LEFT_Y_SELECTIONS = [
  {
    label: 'Transaction Volume',
    value: 'vol',
    agg: AGG_TYPE_COUNT,
    valueType: VALUE_TYPE_COUNT,
  }
];

const RIGHT_Y_SELECTIONS = [
  {
    label: 'Average PSF ($psf)',
    value: 'apsf',
    agg: AGG_TYPE_AVG,
    valueType: VALUE_TYPE_PRICE,
  }
];

const INTERVAL_SELECTIONS = [
  {
    label: 'Year',
    value: INTERVAL_TYPE_YEAR,
    prefix: 'Annual',
  },
  {
    label: 'Month',
    value: INTERVAL_TYPE_MONTH,
    prefix: 'Monthly',
  },
];

const RESOLUTION_SELECTIONS = [
  {
    label: 'Low Resolution',
    value: RESOLUTION_TYPE_LOW,
  },
  {
    label: 'High Resolution',
    value: RESOLUTION_TYPE_HIGH,
  },
];

const generateLabelPrefix = (interval) => {
  const currInterval = INTERVAL_SELECTIONS.find(v => v.value === interval);
  return currInterval.prefix;
};

const CompareView = ({
  user,
  data,
  target,
  loading,
  setLoading,
  compData,
  setCompData,
  screenDim,
  isMaximized,
  goToProperty,
  setFocusOn,
  compareList,
  setCompareList,
  viewComparePro,
  getProjectLabel,
  onCompareSearch
}) => {
  const [period, setPeriod] = useState(PERIOD_SELECTIONS[0].value);
  const [leftY, setLeftY] = useState(LEFT_Y_SELECTIONS[0].value);
  const [rightY, setRightY] = useState(RIGHT_Y_SELECTIONS[0].value);
  const [interval, setTimeInterval] = useState(INTERVAL_SELECTIONS[0].value);
  const [resolution, setResolution] = useState(RESOLUTION_SELECTIONS[0].value);

  const targetCompKey = target && target.type === LOCATION_PROPERTY ? (
    target.mode === MAP_MODE_HDB
      ? target.id
      : `${target.id}_${target.projectId}`
  ) : '';

  const addPropData = (markerName, projectId) => {
    const compKey = target.mode === MAP_MODE_HDB ? markerName : `${markerName}_${projectId}`;
    if (compKey in compData.t) return;

    // setLoading(true);  // for now just hide the loading since it is fast and looks weird when show for split second

    let foldername = '';
    let filename = '';
    if (target.mode === MAP_MODE_HDB) {
      foldername = 'vh';
      filename = markerName;
    } else {
      foldername = `v/${getPropertyFileName(markerName)}`;
      filename = projectId;
    }

    getJsonFile(foldername, filename, json => {
      if (target.mode === MAP_MODE_HDB) {
        const data = decompressHdbSingleCompData(json);
        setCompData({
          t: {
            ...compData.t,
            [compKey]: data.t
          },
          p: {
            ...compData.p,
            [compKey]: data.p
          },
          d: {
            ...compData.d,
            [compKey]: data.d
          },
          s: {
            ...compData.s,
            [compKey]: data.s
          }
        });
      } else {
        const data = decompressSingleCompData(json);
        setCompData({
          t: {
            ...compData.t,
            [compKey]: data.t
          },
          p: {
            ...compData.p,
            [compKey]: data.p
          },
          d: {
            ...compData.d,
            [compKey]: data.d
          },
          s: {
            ...compData.s,
            [compKey]: data.s
          }
        });
      }

      onCompareSearch?.();

      // setLoading(false);
    }, err => {
      // setLoading(false);
    });
  };

  const currentLeftY = LEFT_Y_SELECTIONS.find(v => v.value === leftY);
  const currentRightY = RIGHT_Y_SELECTIONS.find(v => v.value === rightY);

  const getMultiPropMultiChart = (period, interval, results) => {
    // format transaction data for charts
    const formattedResults = {};
    Object.keys(results).forEach(r => {
      formattedResults[r] = results[r].map(d => ({
        d: d.saleDate,
        apsf: d.unitPrice,
        vol: d.unitPrice,
      }));
    });

    // generate multi-chart results
    return generateMultiPropMultiChart({
      [MULTICHART_SUBTYPE_BARS]: {
        field: leftY,
        agg: currentLeftY.agg,
        valueType: currentLeftY.valueType,
      },
      [MULTICHART_SUBTYPE_LINES]: {
        field: rightY,
        agg: currentRightY.agg,
        valueType: currentRightY.valueType,
      },
    }, period, interval, formattedResults);
  };

  const getProfitDistributionChart = (profitResults, resolution) => {
    return generateMultiPropDistributionChart(
      'profitPerc',
      VALUE_TYPE_PERC,
      profitResults,
      resolution
    );
  };

  const changePeriod = (value) => {
    setPeriod(value);
  };

  const changeInterval = (value) => {
    setTimeInterval(value);
  };

  const removeProperty = (projectKey) => {
    if (projectKey in compData.t) {
      const newTxData = { ...compData.t };
      delete newTxData[projectKey];
      const newProfitData = { ...compData.p };
      delete newProfitData[projectKey];
      const newDetailData = { ...compData.d };
      delete newDetailData[projectKey];
      setCompData({
        t: newTxData,
        p: newProfitData,
        d: newDetailData
      });
    }
  };

  const profitDistributionData = getProfitDistributionChart(compData.p, resolution);

  const getFlatProfitResults = () => {
    const results = [];
    if (profitDistributionData?.colors && profitDistributionData.colors.length === Object.keys(compData.p).length) {
      const colors = profitDistributionData.colors;
      Object.keys(compData.p).forEach(key => {
        const prop = compData.p[key];
        const color = colors.find(c => c.name === key);
        prop.forEach(row => {
          results.push({
            name: key,
            saleDate: row.saleDate,
            profitPerc: row.profitPerc,
            color: color.color,
          });
        });
      });
    }
    return results;
  };

  const flatProfitResults = getFlatProfitResults();

  const isLandscape = screenDim.width > screenDim.height;

  const chartHeight = (isLandscape && isMaximized) ? 250 : (screenDim.height < 800 ? null : 250);

  const chartColGrid = isLandscape
    && (isMaximized ? screenDim.width > 1200 : screenDim.width > 1600)
    ? 6 : 12;

  const mediaMatches = checkMediaQuery();

  const onSearch = (location) => {
    if (location.name in compData.t) return;
    if (target.mode === MAP_MODE_HDB) {
      addPropData(location.store);

      trackEvent(`compare_search_hdb_${location.marker}`);
    } else {
      addPropData(location.marker, location.store);

      trackEvent(`compare_search_private_${location.marker}`);
    }
  }

  const onHeaderClick = (column) => {
    const id = column.id;
    setFocusOn(id);
  };

  const isHdb = target.mode === MAP_MODE_HDB;

  const dateStrConvert = (dateStr) => {
    const chunks = dateStr.split('-');
    const day = chunks[0];
    const month = chunks[1];
    const year = chunks[2];
    return new Date(`${year}-${month}-${day}`).getTime();
  };

  return (
    <>
      <section className={`pb-40 ${noSelectClass(user)}`}>
        {loading
          && <div className="loader-container">
            <Loader />
          </div>
        }

        {!loading
          && <section className="noselect watermark">
            {viewComparePro
              && <div className="container mt-20 mb-10 px-0 d-flex">
                {Object.keys(compData.t).length > 0
                  && <button
                    className={`p-2 button mr-5 mb-5 ${
                      targetCompKey in compareList
                        ? '-blue-1 bg-blue-1-05 text-blue-1'
                        : '-dark-1 bg-blue-1 text-white'
                    } h-30 px-10 rounded-100 text-12`}
                    onClick={() => {
                      if (targetCompKey in compareList) {
                        const newCompareList = { ...compareList };
                        delete newCompareList[targetCompKey];
                        setCompareList(newCompareList);

                        trackEvent('compare_remove_1', {
                          key: targetCompKey,
                          from: 'compare'
                        });
                      } else {
                        setCompareList({ ...compareList, [targetCompKey]: {
                          t: compData.t[targetCompKey],
                          p: compData.p[targetCompKey],
                          d: compData.d[targetCompKey]
                        }});

                        trackEvent('compare_add_1', {
                          key: targetCompKey,
                          from: 'compare'
                        });
                      }
                    }}
                  >
                    <i className={`${
                      targetCompKey in compareList
                        ? 'icon-minus'
                        : 'icon-plus'
                    } mr-5`} />
                    {targetCompKey in compareList
                      ? 'Compare'
                      : 'Compare'}
                  </button>
                }
                {Object.keys(compData.t).length > 0
                  && <button
                    className={`p-2 button mr-5 mb-5 ${
                      Object.keys(compData.t).every(d => d in compareList)
                        ? '-blue-1 bg-blue-1-05 text-blue-1'
                        : '-dark-1 bg-blue-1 text-white'
                    } h-30 px-10 rounded-100 text-12`}
                    onClick={() => {
                      const newCompareList = { ...compareList };
                      if (Object.keys(compData.t).every(d => d in compareList)) {
                        Object.keys(compData.t).forEach(d => delete newCompareList[d]);

                        trackEvent('compare_remove_all', {
                          keys: Object.keys(compData.t),
                          from: 'compare'
                        });
                      } else {
                        Object.keys(compData.t).forEach(d => newCompareList[d] = {
                          t: compData.t[d],
                          p: compData.p[d],
                          d: compData.d[d]
                        });

                        trackEvent('compare_add_all', {
                          keys: Object.keys(compData.t),
                          from: 'compare'
                        });
                      }
                      setCompareList(newCompareList);
                    }}
                  >
                    <i className={`${
                      Object.keys(compData.t).every(d => d in compareList)
                        ? 'icon-minus'
                        : 'icon-plus'
                    } mr-5`} />
                    {Object.keys(compData.t).every(d => d in compareList)
                      ? `Remove all ${mediaMatches ? '' : `${Object.keys(compData.t).length}`}`
                      : `Compare all ${mediaMatches ? '' : `${Object.keys(compData.t).length}`}`}
                  </button>
                }
                <button
                  className="p-2 button mr-5 mb-5 -dark-1 bg-blue-1 text-white h-30 px-10 rounded-100 text-12 cmp-pro"
                  onClick={() => viewComparePro()}
                  data-tooltip-id="proptag-tooltip"
                  data-tooltip-content="Go to Compare and compare properties you have added to the Compare list"
                  data-tooltip-variant="dark"
                  data-tooltip-place="top"
                >
                  {mediaMatches
                    ? <ReactSVG
                      src="/img/general/compare.svg"
                      beforeInjection={(svg) => {
                        svg.querySelectorAll('[fill]').forEach((element) => element.removeAttribute('fill'));
                      }}
                    />
                    : 'Go to Compare'
                  }
                </button>
              </div>
            }

            <div className="container mt-10 mb-10">
              <div className="row">
                <div className="col-12 px-5">
                  <SearchCompareProp
                    onSearch={onSearch}
                    filterType="property"
                    isHdbMode={isHdb}
                  />
                </div>
              </div>
            </div>

            <div className="container px-5">
              {Object.keys(compData.t).map((p, i) => (
                <span
                  className="p-tag text-10 rounded-100 px-10 mr-5 cursor-pointer"
                  key={`comp-container-${p}`}
                  style={{ backgroundColor: getColor(i) }}
                >
                  <span
                    data-tooltip-id="proptag-tooltip"
                    data-tooltip-html={`
                      <div class="map-tooltip-content">
                      <span class="fw-500 text-light-2">${getProjectLabel(p)}</span>
                      <br />
                      <span class="map-tooltip-hint">Click to zoom in</span></div>
                    `}
                    data-tooltip-variant="dark"
                    data-tooltip-place="bottom"
                    onClick={() => setFocusOn(p)}
                  >
                    {getProjectLabel(p)}
                  </span>
                  <i
                    className="icon-close cursor-pointer pl-10"
                    onClick={() => removeProperty(p)}
                    data-tooltip-id="proptag-tooltip"
                    data-tooltip-content="Remove property"
                    data-tooltip-variant="dark"
                    data-tooltip-place="bottom"
                  />
                </span>
              ))}
            </div>
            <Tooltip id="proptag-tooltip" style={{ zIndex: 600 }} />
          </section>
        }

        {!loading && Object.keys(compData.t).length === 0
          && <section className={`layout-pb-md ${noSelectClass(user)} watermark`}>
            <div
              id="project-details-modal"
              className={`p-2 modal-scoll-content text-center fw-500 py-40 ${
                isMaximized ? 'modal-scroll-media-full' : (mediaMatches ? 'modal-scroll-media' : '')
              }`}
            >
              No property added to compare
            </div>
          </section>
        }

        {!loading && Object.keys(compData.t).length > 0
          && <>
            <section className={`layout-pb-md ${noSelectClass(user)} watermark`}>
              <div className="px-5">
                <div className="row">

                  <div className={`col-${chartColGrid} text-center noselect`}>
                    <h5 className="text-15 mt-20 mb-10">
                      {currentLeftY.label} / {currentRightY.label}
                    </h5>
                    {/* <div className="compare-chart-selector px-5 mt-10 mb-10"> */}
                      <MultiChartSelector
                        period={period}
                        setPeriod={changePeriod}
                        periodOptions={PERIOD_SELECTIONS}
                        leftY={leftY}
                        setLeftY={setLeftY}
                        leftYOptions={LEFT_Y_SELECTIONS}
                        rightY={rightY}
                        setRightY={setRightY}
                        rightYOptions={RIGHT_Y_SELECTIONS}
                        interval={interval}
                        setTimeInterval={changeInterval}
                        intervalOptions={INTERVAL_SELECTIONS}
                      />
                    {/* </div> */}
                    <MultiChart
                      data={getMultiPropMultiChart(period, interval, compData.t)}
                      tooltipTitleCallback={
                        (tooltipItems) => {
                          const type = tooltipItems[0].dataset.type;
                          const title = tooltipItems[0].label;
                          if (type === 'line') {
                            return `${generateLabelPrefix(interval)} ${currentRightY.label} for ${title}`;
                          } else if (type === 'bar') {
                            return `${generateLabelPrefix(interval)} ${currentLeftY.label} for ${title}`;
                          }
                          return `${title}`;
                        }
                      }
                      tooltipLabelCallback={
                        (tooltipItem) => {
                          const type = tooltipItem.dataset.type;
                          const datasetLabel = getProjectLabel(tooltipItem.dataset.label || '');
                          const value = tooltipItem.raw;
                          if (type === 'line') {
                            if (rightY === 'apsf') {
                              return `${datasetLabel}: $${value}`;
                            }
                          } else if (type === 'bar') {
                            if (leftY === 'vol') {
                              return `${datasetLabel}: ${value.toFixed(0)}`;
                            }
                          }
                          return `${datasetLabel}: ${value}`;
                        }
                      }
                      yLeftLabel={currentLeftY.label}
                      yRightLabel={currentRightY.label}
                      height={chartHeight}
                    />
                    <div className="mt-10">
                      {profitDistributionData?.colors.map(p => (
                        <span
                          key={`comp-col-px-${p.color}`}
                          className="c-tag text-10 px-10 ml-5"
                          style={{ backgroundColor: p.color }}
                          data-tooltip-id="proptag-tooltip"
                          data-tooltip-content={getProjectLabel(p.name)}
                          data-tooltip-variant="dark"
                          data-tooltip-place="top"
                        >
                          {getProjectLabel(p.name)}
                        </span>
                      ))}
                    </div>
                  </div>

                  <div className={`col-${chartColGrid} text-center noselect`}>
                    <h5 className="py-10 text-15 mt-10">
                      Annualized {isHdb ? 'Price Increase' : 'Profit'} (%) Distribution
                    </h5>
                    <div className="px-10">
                      <div className="dropdown js-dropdown js-category-active compare-chart-item proj-comp-spad">
                        <div
                          className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-15 py-10 text-12 lh-12"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="true"
                          aria-expanded="false"
                          data-bs-offset="0,10"
                        >
                          <span className="js-dropdown-title">
                            <span className="text-blue-1 fw-600"></span>
                            <span className="js-dropdown-res">{
                              RESOLUTION_SELECTIONS.find(v => v.value === resolution).label
                            }</span>
                          </span>
                          <i className="icon icon-chevron-sm-down text-7 ml-10" />
                        </div>
                        <div className="toggle-element -dropdown dropdown-menu">
                          <div className="text-13 y-gap-15 js-dropdown-list">
                            {RESOLUTION_SELECTIONS.map(option => (
                              <div key={`comp-res-sel-${option.value}`}>
                                <button
                                  className={`d-block js-dropdown-link ${
                                    resolution === option.value ? "text-blue-1 " : ""
                                  }`}
                                  onClick={() => {
                                    setResolution(option.value);
                                    document.querySelector(".js-dropdown-res").textContent = option.label;
                                  }}
                                >
                                  {option.label}
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <DistributionChart
                      data={profitDistributionData}
                      tooltipTitleCallback={
                        (context) => `Annualized ${
                          parseFloat(context[0].label) <= 0
                            ? 'Loss:'
                            : `${isHdb ? 'Price Increase' : 'Gain'}:`
                          } up to ${context[0].label}%`
                      }
                      tooltipLabelCallback={
                        (context) => `${getProjectLabel(context.dataset.label || '')}: ${context.raw.toFixed(0)} transactions`
                      }
                      xAxisLabel={`Annualized ${isHdb ? 'Price Increase' : 'Profit'} (%)`}
                      yAxisLabel="No. of Transactions"
                      height={chartHeight}
                    />
                    
                    <div className="mt-10">
                      {profitDistributionData?.colors.map(p => (
                        <span
                          key={`comp-col-pfd-${p.color}`}
                          className="c-tag text-10 px-10 ml-5"
                          style={{
                            backgroundColor: p.color
                          }}
                          data-tooltip-id="proptag-tooltip"
                          data-tooltip-content={getProjectLabel(p.name)}
                          data-tooltip-variant="dark"
                          data-tooltip-place="top"
                        >
                          {getProjectLabel(p.name)}
                        </span>
                      ))}
                    </div>
                  </div>

                  <div className={`col-${chartColGrid} text-center noselect`}>
                    <h5 className="py-10 text-15 mt-20 mb-10">Annualized {isHdb ? 'Price Increase' : 'Profit'} (%)</h5>
                    <MainChart
                      label={`Annualized ${isHdb ? 'Price Increase' : 'Profit'} (%)`}
                      data={
                        flatProfitResults.map(row => ({
                          x: row.saleDate.getTime(),
                          y: row.profitPerc,
                        }))
                      }
                      colors={flatProfitResults.map(row => row.color)}
                      tooltipTitle={`Annualized ${isHdb ? 'Price Increase' : 'Profit'} (%)`}
                      tooltipLabelCallback={
                        (context) => {
                          const date = new Date(context.raw.x);
                          const formattedDate = formatShortDate(date);
                          const name = getProjectLabel(flatProfitResults[context.dataIndex].name);
                          return `${name}: ${context.raw.y.toFixed(1)}% (${formattedDate})`;
                        }
                      }
                      yAxisLabel={`Annualized ${isHdb ? 'Price Increase' : 'Profit'} (%)`}
                      height={chartHeight}
                    />
                    <div className="mt-10">
                      {profitDistributionData?.colors.map(p => (
                        <span
                          key={`comp-pdd-col-${p.color}`}
                          className="c-tag text-10 px-10 ml-5"
                          style={{
                            backgroundColor: p.color
                          }}
                          data-tooltip-id="proptag-tooltip"
                          data-tooltip-content={getProjectLabel(p.name)}
                          data-tooltip-variant="dark"
                          data-tooltip-place="bottom"
                        >
                          {getProjectLabel(p.name)}
                        </span>
                      ))}
                    </div>
                  </div>

                </div>
                </div>
            </section>

            <section className={`layout-pb-md ${noSelectClass(user)} watermark border-top-light py-10`}>
              <div className={`data-table ${noSelectClass(user)}`}>
                <div className="d-flex">
                  <div className="p-2">
                    <h3 className="text-16 fw-600">Compare Properties</h3>
                  </div>
                </div>
                <CompareTable
                  target={target}
                  data={compData.d}
                  getProjectLabel={getProjectLabel}
                  onHeaderClick={onHeaderClick}
                />
              </div>
            </section>

            <section className={`layout-pb-md ${noSelectClass(user)} watermark border-top-light py-10`}>
              <div className={`data-table ${noSelectClass(user)}`}>
                <div className="d-flex">
                  <div className="p-2">
                    <h3 className="text-16 fw-600">Recent Transactions</h3>
                  </div>
                </div>
                <CompRecentTxTable
                  target={target}
                  goToProperty={goToProperty}
                  data={
                    Object.keys(compData.s).map(k => ({ ...compData.d[k], key: k}))
                      .flatMap(d => compData.s[d.key].map(t => ({ ...d, ...t })))
                      .map(t => {
                        return {
                          ...t,
                          saleDateMs: dateStrToMsec(t.saleDate),
                          sale_date: dateStrConvert(t.saleDate),
                          transacted_price: parseInt(t.price),
                        };
                      })
                      .sort((a, b) => b.saleDateMs - a.saleDateMs)
                  }
                />
              </div>
            </section>
          </>
        }
      </section>
    </>
  );
};

export default CompareView;
