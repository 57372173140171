import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import Loader from "@/components/common/Loader";
import StyledFirebaseAuth from "@/pages/others/login/StyledFirebaseAuth";
import { logLogin } from "@/utils/api";
import { FIREBASE_UI_AUTH_CONFIG } from "@/utils/user";


const LoginBox = ({ session, onLoggedIn }) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [autoLogin, setAutoLogin] = useState(true);
  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(null);
  const paramErr = params.get('err');
  const next = params.get('next');

  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        firebase.auth()
          .setPersistence(autoLogin
              ? firebase.auth.Auth.Persistence.LOCAL
              : firebase.auth.Auth.Persistence.SESSION)
          .then(() => {
            setLoading(true);
            logLogin(session, user);
            onLoggedIn(user);
          })
          .catch((err) => {
            setErr(err);
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    });
    return () => unregisterAuthObserver();
  }, []);

  if (err === null && paramErr !== err) {
    setErr(paramErr);
  }

  return (
    <>
      <div className="px-20 py-20 sm:px-10 sm:py-10">
        {err
          && <div className="alert alert-danger" role="alert">
            {err}
          </div>
        }

        {!loading
          && <div className="row y-gap-10 noselect">
            <div className="row ml-0 mr-0">

              <div className="col-12">
                <div className="row">
                  <div className="col-12 text-center text-14 fw-600 text-blue-1">
                    Enjoy REALSMART premium features for free!
                  </div>
                  <div className="col-12 d-flex justify-content-center mb-10">
                    <StyledFirebaseAuth uiConfig={FIREBASE_UI_AUTH_CONFIG} firebaseAuth={firebase.auth()} />
                  </div>

                  <div className="col-12">
                    <div className="d-flex justify-content-center">
                      <div className="form-checkbox mt-5">
                        <input
                          type="checkbox"
                          name="name"
                          checked={autoLogin}
                          onChange={e => setAutoLogin(e.target.checked)}
                        />
                        <div className="form-checkbox__mark">
                          <div className="form-checkbox__icon icon-check" />
                        </div>
                      </div>
                      <div className="text-15 lh-15 text-light-1 ml-10">
                        Log me in automatically
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        }

        {loading
          && <Loader />
        }
      </div>
    </>
  );
};

export default LoginBox;
