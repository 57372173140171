import { trackPageView } from "@/utils/api";
import { useEffect } from "react";

const Block1 = () => {
  const content = [
    "Realsmart maximizes your profits in real estate investments by enabling you to make unbiased, smart decisions.",
    "Behind Realsmart, we are a team of engineers from Big Tech. Using our experience in building fast software for billions of users, we are democratizing real estate knowledge by bringing transparency to this industry. We want to give back to society and offer this platform for free.",
    "For life's biggest purchase and mortgages that affect your future, trust hard data—because data doesn't lie, and financial stress isn't worth the gamble.",
    "Take control of your own real estate journey by simply looking up data yourself and you would avoid mistakes that cost hundreds of thousands, even millions of $$$.",
  ];

  useEffect(() => {
    trackPageView('about');
  }, []);

  return (
    <>
      <div className="col-lg-5">
        <h2 className="text-30 fw-600">About REALSMART.SG</h2>
        <p className="text-dark-1 mt-60 lg:mt-40 md:mt-20">
          {content.map(c => (
            <>
              {c}
              <br /><br />
            </>
          ))}
          
          Have a request for new features or just want to chat?
          <br />Send an email over to <a className="text-blue-1 fw-500" href="mailto:hello@realsmart.sg">hello@realsmart.sg</a>
          <br /><br />
        </p>
      </div>

      <div className="col-lg-6">
        <img
          src="/img/general/skyline.png"
          alt="image"
          className="rounded-4 w-100 mt-50 mb-50"
        />
      </div>
    </>
  );
};

export default Block1;
