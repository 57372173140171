import {
  CHART_GREEN_BG,
  CHART_ORANGE_BG,
  CHART_RED_BG
} from "@/utils/colors";
import {
  dateStrToDateObj,
  dateStrToMsec,
  formatShortDate
} from "@/utils/time";
import DistributionChart from "@/components/project/DistributionChart";
import {
  PERIOD_TYPE_10Y,
  PERIOD_TYPE_12M,
  PERIOD_TYPE_3Y,
  PERIOD_TYPE_ALL,
  VALUE_TYPE_PERC,
  generatePropDistributionChart
} from "@/utils/chart";
import { useEffect, useState } from "react";
import Sidebar from "@/components/common/Sidebar";
import {
  FIELD_MAX_PRICE,
  FIELD_MAX_SIZE,
  FIELD_MAX_UNIT_PRICE,
  FIELD_MIN_PRICE,
  FIELD_MIN_SIZE,
  FIELD_MIN_UNIT_PRICE,
  FILTER_FULL_SALE_TYPE,
  FILTER_GAIN,
  FILTER_HDB_FLAT_TYPE,
  FILTER_PRICE,
  FILTER_PROPERTY_TYPE,
  FILTER_SIZE,
  FILTER_TRANSACTION_DATE,
  FILTER_UNIT_PRICE,
  hasFilterSelected,
  hasMaxRangeSelected,
  hasMinRangeSelected,
  hasValidInput,
  initializeFilters
} from "@/utils/filter";
import { getPropTypeFilterSelected } from "@/utils/convert";
import TransactionTable from "./Table/TransactionTable";
import ProfitTable from "./Table/ProfitTable";
import AnnotatedChart from "../chart/AnnotatedChart";
import { MAP_MODE_HDB } from "@/utils/map";

const FLOOR_COLORS = [
  {
    label: 'low',
    color: CHART_GREEN_BG,
  },
  {
    label: 'mid',
    color: CHART_ORANGE_BG,
  },
  {
    label: 'high',
    color: CHART_RED_BG,
  },
];

const TX_FILTER_OPTIONS = [
  FILTER_TRANSACTION_DATE,
  FILTER_FULL_SALE_TYPE,
  FILTER_SIZE,
  FILTER_PRICE,
  FILTER_UNIT_PRICE
];

const HDB_TX_FILTER_OPTIONS = [
  ...TX_FILTER_OPTIONS,
  FILTER_HDB_FLAT_TYPE
];

const PROFIT_FILTER_OPTIONS = [
  ...TX_FILTER_OPTIONS,
  FILTER_GAIN
];

const HDB_PROFIT_FILTER_OPTIONS = [
  ...PROFIT_FILTER_OPTIONS,
  FILTER_HDB_FLAT_TYPE
];

const PERIOD_SELECTIONS = [
  {
    label: 'All Time',
    value: PERIOD_TYPE_ALL,
    diff: null
  },
  {
    label: 'Past 1 Year',
    value: PERIOD_TYPE_12M,
    diff: 31556952000
  },
  {
    label: 'Past 3 Years',
    value: PERIOD_TYPE_3Y,
    diff: 94670856000
  },
  {
    label: 'Past 10 Years',
    value: PERIOD_TYPE_10Y,
    diff: 315569520000
  },
];

const TransactionsTab = ({
  user,
  data,
  target,
  isMaximized,
  screenDim,
  goToUnit
}) => {
  const [period, setPeriod] = useState(PERIOD_SELECTIONS[0].value);
  const [transactions, setTransactions] = useState(data.transactions);
  const [profits, setProfits] = useState(data.profits);

  const isHdb = target.mode === MAP_MODE_HDB;

  const txFilterOptions = isHdb ? HDB_TX_FILTER_OPTIONS : TX_FILTER_OPTIONS;
  const profitFilterOptions = isHdb ? HDB_PROFIT_FILTER_OPTIONS : PROFIT_FILTER_OPTIONS;

  useEffect(() => {
    // handle time filter
    const now = new Date().getTime();
    const periodDiff = now - (PERIOD_SELECTIONS.find(s => s.value === period).diff ?? 0);

    // filter transactions and profits
    const transactions = period === PERIOD_TYPE_ALL
      ? data.transactions
      : data.transactions.filter(r => dateStrToMsec(r.saleDate) >= periodDiff);

    setTransactions(transactions);
    
    const profits = period === PERIOD_TYPE_ALL
      ? data.profits
      : data.profits.filter(r => dateStrToMsec(r.saleDate) >= periodDiff);

    setProfits(profits);

    // update derived data
    setPsfChartData(transactions.filter(row => {
      const type = getFloorType(row.floor);
      return chartFloorSelect.length === 0 || chartFloorSelect.indexOf(type) >= 0;
    }));
  }, [period]);

  const profitableData = profits.filter(row => row.profitPerc > 0);
  const unprofitableData = profits.filter(row =>
    (row.type !== 'New Sale' && row.profitPerc <= 0) || (row.type === 'New Sale' && row.profitPerc < 0)
  );
  const abortedData = profits?.filter(row => row.type === 'New Sale' && row.profitPerc === 0);
  
  const params = new URLSearchParams();
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedTableFilter, setSelectedTableFilter] = useState(null);
  const [filteredTxResults, setFilteredTxResults] = useState(transactions);
  const [profitableTxResults, setProfitableTxResults] = useState(profitableData);
  const [unprofitableTxResults, setUnprofitableTxResults] = useState(unprofitableData);
  const [abortedTxResults, setAbortedTxResults] = useState(abortedData);

  const [txFilterSelected, setTxFilterSelected] = useState(initializeFilters(txFilterOptions, params));
  const [profitableSelected, setProfitableSelected] = useState(initializeFilters(profitFilterOptions, params));
  const [unprofitableSelected, setUnprofitableSelected] = useState(initializeFilters(profitFilterOptions, params));
  const [abortedSelected, setAbortedSelected] = useState(initializeFilters(profitFilterOptions, params));

  const [chartFloorSelect, setChartFloorSelect] = useState([]);
  const [psfChartData, setPsfChartData] = useState(transactions);

  const getFloorType = (floor) => {
    if (floor > midFloorMax) return 'high';
    if (floor > lowFloorMax) return 'mid';
    return 'low';
  };

  const getFloorColor = (floor) => {
    if (!hasFloorRange) return CHART_GREEN_BG;
    const floorType = getFloorType(floor);
    if (floorType === 'high') return CHART_RED_BG;
    if (floorType === 'mid') return CHART_ORANGE_BG;
    return CHART_GREEN_BG;
  };

  const getFloorRangeDesc = (floorType) => {
    if (floorType === 'high') return `${midFloorMax + 1} - ${maxFloor}`;
    if (floorType === 'mid') return `${lowFloorMax + 1} - ${midFloorMax}`;
    return `1 - ${lowFloorMax}`;
  };

  const maxFloor = Math.max(...(transactions ?? []).map(r => r['floor']), 0);
  const hasFloorRange = maxFloor >= 3;
  const lowFloorMax = Math.floor(maxFloor * 0.33);
  const midFloorMax = Math.floor(maxFloor * 0.66);
  
  const chartHeight = screenDim.height < 800 ? null : 250;

  const chartColGrid = (screenDim.width > screenDim.height)
    && (isMaximized ? screenDim.width > 1200 : screenDim.width > 1600)
    ? 6 : 12;

  const onApplyFilter = () => {
    if (selectedTableFilter.startsWith('tx-')) {
      const filteredData = filterData(txFilterSelected, data.transactions);
      setFilteredTxResults(filteredData);
    } else if (selectedTableFilter === 'profit-tb-p') {
      const filteredData = filterData(profitableSelected, profitableData);
      setProfitableTxResults(filteredData);
    } else if (selectedTableFilter === 'profit-tb-m') {
      const filteredData = filterData(unprofitableSelected, unprofitableData);
      setUnprofitableTxResults(filteredData);
    } else if (selectedTableFilter === 'profit-tb-a') {
      const filteredData = filterData(abortedSelected, abortedData);
      setAbortedTxResults(filteredData);
    }
    
    if (document.getElementById('listingSidebar').classList.contains('show')) {
      document.getElementById('tx-close-button').click();
    }
  };

  const onResetFilter = () => {
    if (selectedTableFilter.startsWith('tx-')) {
      setTxFilterSelected(initializeFilters(txFilterOptions, params));
    } else if (selectedTableFilter === 'profit-tb-p') {
      setProfitableSelected(initializeFilters(profitFilterOptions, params));
    } else if (selectedTableFilter === 'profit-tb-m') {
      setUnprofitableSelected(initializeFilters(profitFilterOptions, params));
    } else if (selectedTableFilter === 'profit-tb-a') {
      setAbortedSelected(initializeFilters(profitFilterOptions, params));
    }
  };

  const onFilterButtonClick = (name) => {
    if (name?.startsWith('tx-')) {
      setFilterOptions(txFilterOptions);
      setSelectedTableFilter(name);
    } else if (name?.startsWith('profit-')) {
      setFilterOptions(profitFilterOptions);
      setSelectedTableFilter(name);
    }
  };

  const filterData = (filterSelected, data) => {
    let filteredData = data;

    // filter sale date
    if (hasFilterSelected(FILTER_TRANSACTION_DATE, filterSelected)) {
      filteredData = filteredData.filter(row => {
        const startDate = filterSelected[FILTER_TRANSACTION_DATE.field[0]];
        const endDate = filterSelected[FILTER_TRANSACTION_DATE.field[1]];
        const saleDate = dateStrToMsec(row.saleDate);
        if (startDate !== null && saleDate < startDate.getTime()) {
          return false;
        }
        if (endDate !== null && saleDate > endDate.getTime()) {
          return false;
        }
        return true;
      });
    }

    // filter sale type
    if (hasFilterSelected(FILTER_FULL_SALE_TYPE, filterSelected)) {
      const filters = Object.keys(filterSelected[FILTER_FULL_SALE_TYPE.field])
        .filter(o => filterSelected[FILTER_FULL_SALE_TYPE.field][o]);
      filteredData = filteredData.filter(row => {
        return filters.indexOf(row.type) >= 0;
      });
    }

    // filter size
    if (hasFilterSelected(FILTER_SIZE, filterSelected)) {
      const hasMin = hasValidInput(filterSelected[FIELD_MIN_SIZE]);
      const hasMax = hasValidInput(filterSelected[FIELD_MAX_SIZE]);
      const minSize = hasMin ? filterSelected[FIELD_MIN_SIZE] : null;
      const maxSize = hasMax ? filterSelected[FIELD_MAX_SIZE] : null;
      filteredData = filteredData.filter(row => {
        const size = row.area;
        if (maxSize !== null && size > maxSize) return false;
        if (minSize !== null && size < minSize) return false;
        return true;
      });
    }

    // filter price
    if (hasFilterSelected(FILTER_PRICE, filterSelected)) {
      const hasMin = hasValidInput(filterSelected[FIELD_MIN_PRICE]);
      const hasMax = hasValidInput(filterSelected[FIELD_MAX_PRICE]);
      const minPrice = hasMin ? filterSelected[FIELD_MIN_PRICE] : null;
      const maxPrice = hasMax ? filterSelected[FIELD_MAX_PRICE] : null;
      filteredData = filteredData.filter(row => {
        const price = row.price;
        if (minPrice !== null && price < minPrice) return false;
        if (maxPrice !== null && price > maxPrice) return false;
        return true;
      });
    }

    // filter unit price
    if (hasFilterSelected(FILTER_UNIT_PRICE, filterSelected)) {
      const hasMin = hasValidInput(filterSelected[FIELD_MIN_UNIT_PRICE]);
      const hasMax = hasValidInput(filterSelected[FIELD_MAX_UNIT_PRICE]);
      const minPrice = hasMin ? filterSelected[FIELD_MIN_UNIT_PRICE] : null;
      const maxPrice = hasMax ? filterSelected[FIELD_MAX_UNIT_PRICE] : null;
      filteredData = filteredData.filter(row => {
        const price = row.unitPrice;
        if (minPrice !== null && price < minPrice) return false;
        if (maxPrice !== null && price > maxPrice) return false;
        return true;
      });
    }

    // filter property type
    if (hasFilterSelected(FILTER_PROPERTY_TYPE, filterSelected)) {
      const filters = getPropTypeFilterSelected(filterSelected, FILTER_PROPERTY_TYPE);
      filteredData = filteredData.filter(row => {
        const propType = row.propertyType;
        return filters.indexOf(propType) >= 0;
      });
    }

    // filter gain only for profit
    if (selectedTableFilter.startsWith('profit-')) {
      if (hasFilterSelected(FILTER_GAIN, filterSelected)) {
        const hasMin = hasMinRangeSelected(FILTER_GAIN, filterSelected[FILTER_GAIN.field]);
        const hasMax = hasMaxRangeSelected(FILTER_GAIN, filterSelected[FILTER_GAIN.field]);
        const minVal = hasMin ? filterSelected[FILTER_GAIN.field].value.min : null;
        const maxVal = hasMax ? filterSelected[FILTER_GAIN.field].value.max : null;
        filteredData = filteredData.filter(row => {
          const gain = row.profitPerc;
          if (minVal !== null && gain < minVal) return false;
          if (maxVal !== null && gain > maxVal) return false;
          return true;
        });
      }
    }

    // filter flat type
    if (hasFilterSelected(FILTER_HDB_FLAT_TYPE, filterSelected)) {
      const propFilter = getPropTypeFilterSelected(filterSelected, FILTER_HDB_FLAT_TYPE);
      filteredData = filteredData.filter(row => {
        if (!propFilter.some(t => t === row.flatType)) return false;
        return true;
      });
    }

    return filteredData;
  };

  const setFilterSelected = (filterSelected) => {
    if (selectedTableFilter?.startsWith('tx-')) {
      setTxFilterSelected(filterSelected);
    } else if (selectedTableFilter === 'profit-tb-p') {
      setProfitableSelected(filterSelected);
    } else if (selectedTableFilter === 'profit-tb-m') {
      setUnprofitableSelected(filterSelected);
    } else if (selectedTableFilter === 'profit-tb-a') {
      setAbortedSelected(filterSelected);
    }
  };

  const getFilterSelected = () => {
    if (selectedTableFilter?.startsWith('tx-')) {
      return txFilterSelected;
    }else if (selectedTableFilter === 'profit-tb-p') {
      return profitableSelected;
    } else if (selectedTableFilter === 'profit-tb-m') {
      return unprofitableSelected;
    } else if (selectedTableFilter === 'profit-tb-a') {
      return abortedSelected;
    }
    return {};
  };

  const distributionChartData = generatePropDistributionChart(
    VALUE_TYPE_PERC,
    profits.map(row => row.profitPerc)
  );

  return (
    <>
      <section className="pt-10 pb-20">
        <div className="noselect">
          <div className="d-flex">
            <div className="p-2 flex-grow-1">
              <h3 className="text-18 fw-500">Sales Statistics</h3>
            </div>

            <div className="p-2 dropdown js-dropdown js-category-active compare-chart-item proj-comp-spad">
              <div
                className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-15 py-10 text-12 lh-12"
                data-bs-toggle="dropdown"
                data-bs-auto-close="true"
                aria-expanded="false"
                data-bs-offset="0,10"
              >
                <span className="js-dropdown-title">
                  <span className="text-blue-1 fw-600"></span>
                  <span className="js-dropdown-time">{
                    PERIOD_SELECTIONS.find(v => v.value === period).label
                  }</span>
                </span>
                <i className="icon icon-chevron-sm-down text-7 ml-10" />
              </div>
              <div className="toggle-element -dropdown dropdown-menu">
                <div className="text-13 y-gap-15 js-dropdown-list">
                  {PERIOD_SELECTIONS.map(option => (
                    <div key={option.value}>
                      <button
                        className={`d-block js-dropdown-link ${
                          period === option.value ? "text-blue-1 " : ""
                        }`}
                        onClick={() => {
                          setPeriod(option.value);
                          document.querySelector(".js-dropdown-time").textContent = option.label;
                        }}
                      >
                        {option.label}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>

          </div>

          <div className="row y-gap-20 chart_responsive mt-10">

            {transactions.length > 0
              && <div className={`col-${chartColGrid} noselect`}>
                <div className="py-10">
                  <div className="d-flex justify-between items-center">
                    <h2 className="text-14 lh-1 fw-500">PSF Price Trend</h2>
                  </div>

                  <div className="pt-10">
                    <AnnotatedChart
                      label="Unit Price ($psf)"
                      data={
                        psfChartData.map(row => ({
                          x: dateStrToDateObj(row.saleDate).getTime(),
                          y: row.unitPrice,
                        }))
                      }
                      colors={
                        maxFloor ? psfChartData.map(row => getFloorColor(row.floor)) : null
                      }
                      tooltipTitle="PSF Price Trend ($psf)"
                      tooltipLabelCallback={
                        (context) => {
                          const date = new Date(context.raw.x);
                          const formattedDate = formatShortDate(date);
                          const area = psfChartData[context.dataIndex].area;
                          const floor = psfChartData[context.dataIndex].floor;
                          const labels = [
                            `${formattedDate}: $${context.raw.y.toLocaleString()} psf`,
                            `Area: ${area} sqft`,
                          ];
                          if (hasFloorRange) labels.push(`Floor: ${floor} (${getFloorType(floor)} floor)`);
                          return labels;
                        }
                      }
                      annotationLine={data.fairPsf !== null && data.fairPsf >= 0 ? {
                        label: `Fair Price ($${data.fairPsf.toFixed(2)})`,
                        value: parseFloat(data.fairPsf.toFixed(2)),
                      } : null}
                      yAxisLabel="PSF Price ($psf)"
                      height={chartHeight}
                    />
                    {hasFloorRange
                      && <div className="mt-15 text-center">
                        {FLOOR_COLORS.map(p => (
                          <span
                            key={p}
                            className="c-tag text-10 px-10 pr-5 ml-5 cursor-pointer"
                            style={{
                              backgroundColor: p.color,
                              opacity: chartFloorSelect.length === 0 || chartFloorSelect.indexOf(p.label) >= 0 ? 1 : 0.3
                            }}
                            onClick={() => {
                              let newFloorSelect = [];
                              if (chartFloorSelect.indexOf(p.label) >= 0) {
                                newFloorSelect = chartFloorSelect.filter(s => s !== p.label)
                              } else {
                                newFloorSelect = [...chartFloorSelect, p.label];
                              }
                              setChartFloorSelect(newFloorSelect);
                              setPsfChartData(transactions.filter(row => {
                                const type = getFloorType(row.floor);
                                return newFloorSelect.length === 0 || newFloorSelect.indexOf(type) >= 0;
                              }));
                            }}
                          >
                            {p.label.charAt(0).toUpperCase() + p.label.slice(1)} Floor ({getFloorRangeDesc(p.label)})
                          </span>
                        ))}
                      </div>
                    }
                    
                  </div>

                </div>
              </div>
            }

            {profits.length > 0
              && <div className={`col-${chartColGrid} noselect`}>
                <div className="py-10">
                  <div className="d-flex justify-between items-center">
                    <h2 className="text-14 lh-1 fw-500">Annualized {isHdb ? 'Price Increase' : 'Profit'} (%)</h2>
                  </div>

                  <div className="pt-10">
                    <AnnotatedChart
                      label={`Annualized ${isHdb ? 'Price Increase' : 'Profit'} (%)`}
                      data={
                        profits.map(row => ({
                          x: dateStrToDateObj(row.saleDate).getTime(),
                          y: row.profitPerc,
                        }))
                      }
                      tooltipTitle={`Annualized ${isHdb ? 'Price Increase' : 'Profit'} (%)"`}
                      tooltipLabelCallback={
                        (context) => {
                          const date = new Date(context.raw.x);
                          const formattedDate = formatShortDate(date);
                          return `${formattedDate}: ${context.raw.y.toFixed(1)}%`;
                        }
                      }
                      yAxisLabel={`Annualized ${isHdb ? 'Price Increase' : 'Profit'} (%)`}
                      height={chartHeight}
                    />
                  </div>

                </div>
              </div>
            }

            {profits.length > 0
              && <div className={`col-${chartColGrid} noselect`}>
                <div className="py-10">
                  <div className="d-flex justify-between items-center">
                    <h2 className="text-14 lh-1 fw-500">Annualized {isHdb ? 'Price Increase' : 'Profit'} (%) Distribution</h2>
                  </div>

                  <div className="pt-10">
                    <DistributionChart
                      label={`Annualized ${isHdb ? 'Price Increase' : 'Profit'} (%) Distribution`}
                      data={distributionChartData}
                      tooltipTitleCallback={
                        (context) => `Annualized ${parseFloat(context[0].label) <= 0 ? (isHdb ? 'Price Decrease:' : 'Loss:') : (isHdb ? 'Price Increase:' : 'Gain:')} up to ${context[0].label}%`
                      }
                      tooltipLabelCallback={
                        (context) => `Number of Transactions: ${context.raw.toFixed(0)}`
                      }
                      xAxisLabel={`Annualized ${isHdb ? 'Price Increase' : 'Profit'} (%)`}
                      yAxisLabel="Number of Transactions"
                      height={chartHeight}
                    />
                  </div>

                </div>
              </div>
            }

          </div>
        </div>
      </section>
      
      <section className={`pt-10 modal-table${isMaximized ? '-full' : ''} border-top-light`}>
        <div className={`data-table`}>
          <div className="d-flex">
            <div className="p-2">
              <h3 className="text-15 fw-500">Transactions</h3>
            </div>
          </div>
          <TransactionTable
            raw={transactions}
            data={filteredTxResults}
            target={target}
            onFilterButtonClick={onFilterButtonClick}
            goToUnit={goToUnit}
          />
        </div>
      </section>

      <section className={`mt-10 pt-10 modal-table${isMaximized ? '-full' : ''} border-top-light`}>
        <div className={`data-table`}>
          <div className="d-flex">
            <div className="p-2">
              <h3 className="text-15 fw-500">Profitable Transactions ({
                profitableTxResults.length
              })</h3>
            </div>
          </div>
          <ProfitTable
            name="profit-tb-p"
            raw={transactions}
            data={profitableTxResults}
            target={target}
            onFilterButtonClick={onFilterButtonClick}
            goToUnit={goToUnit}
          />
        </div>
      </section>

      <section className={`mt-10 pt-10 modal-table${isMaximized ? '-full' : ''} border-top-light`}>
        <div className={`data-table`}>
          <div className="d-flex">
            <div className="p-2">
              <h3 className="text-15 fw-500">Unprofitable Transactions ({
                unprofitableTxResults.length
              })</h3>
            </div>
          </div>
          <ProfitTable
            name="profit-tb-m"
            raw={transactions}
            data={unprofitableTxResults}
            target={target}
            onFilterButtonClick={onFilterButtonClick}
            goToUnit={goToUnit}
          />
        </div>
      </section>

      {!isHdb
        && <section className={`mt-10 pt-10 modal-table${isMaximized ? '-full' : ''} border-top-light`}>
          <div className={`data-table`}>
            <div className="d-flex">
              <div className="p-2">
                <h3 className="text-15 fw-500">Aborted Transactions ({
                  abortedTxResults.length
                })</h3>
              </div>
            </div>
            <ProfitTable
              name="profit-tb-a"
              raw={transactions}
              data={abortedTxResults}
              target={target}
              onFilterButtonClick={onFilterButtonClick}
              goToUnit={goToUnit}
            />
          </div>
        </section>
      }

      <section className="pb-50"></section>

      <div
        className="offcanvas offcanvas-start filter-bar-nopad mt-50"
        tabIndex="-1"
        id="listingSidebar"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasLabel">
            Filter Transactions
          </h5>
          <button
            id="tx-close-button"
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        <div className="offcanvas-body">
          <aside className="sidebar y-gap-40 xl:d-block">
            <Sidebar
              filters={getFilterSelected()}
              setFilters={setFilterSelected}
              options={filterOptions}
            />
          </aside>
        </div>

        <div className="row ml-10 mr-10 mt-10 mb-10">
          <div className="col-3">
            <button
              className="button -dark-1 py-15 px-40 h-50 col-12 rounded-0 bg-red-1 text-white w-100"
              onClick={onResetFilter}
            >
              Reset
            </button>
          </div>
          <div className="col-9">
            <button
              className="button -dark-1 py-15 px-40 h-full col-12 rounded-0 bg-blue-1 text-white w-100"
              onClick={onApplyFilter}
            >
              Apply Filter
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionsTab;
