import { Tooltip } from "react-tooltip";
import PostActionButton from "./PostActionButton";
import { checkMediaQuery } from "@/utils/user";
import { useRef, useState } from "react";
import { getTimeLabel } from "@/utils/time";

const PostView = ({
  content,
  onClose,
  setLightboxImages
}) => {
  const bmRef = useRef(null);
  const commentsRef = useRef(null);

  const [liked, setLiked] = useState(false);
  const [showReportInput, setShowReportInput] = useState(false);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [showSharePanel, setShowSharePanel] = useState(false);

  const [reported, setReported] = useState(false);
  const [commented, setCommented] = useState(false);

  const [reportInput, setReportInput] = useState('');
  const [reportInputErr, setReportInputErr] = useState(null);
  const [commentInput, setCommentInput] = useState('');
  const [commentInputErr, setCommentInputErr] = useState(null);

  const [replies, setReplies] = useState([
    {
      author: 'Test1',
      authorId: '1',
      text: 'This is a test',
      createdAt: 1736611200000
    }
  ]);

  const mediaQuery = checkMediaQuery();

  const shareUrl = `https://realsmart.sg/post?id=${content.id}`;

  const getTagLabel = (tag) => {
    const c = tag.split('#');
    return c[c.length - 1];
  };

  const onLike = () => {
    if (liked) {
      setLiked(false);
    } else {
      setLiked(true);
    }
    // TODO
  };

  const onReport = () => {
    if (reportInput && reportInput.length > 10) {
      setReportInputErr(null);
      setReported(true);
      setReportInput('');
    } else {
      setReportInputErr('Your report must be at least 10 characters long');
    }
  };

  const onComment = () => {
    if (commentInput && commentInput.length > 20) {
      const content = commentInput;
      setCommentInputErr(null);
      setCommented(true);
      setCommentInput('');
      setReplies([
        {
          authorId: 'test',
          text: content,
          createdAt: new Date().getTime()
        },
        ...replies
      ]);
      scrollInputComments();
    } else {
      setCommented(false);
      setCommentInputErr('Your comment must be at least 20 characters long');
    }
  };

  const onShare = () => {
    if (window.navigator.share) {
      window.navigator.share({
        title: `REALSMART.SG - Post by ${content.author}`,
        text: content.text ?? 'Click to view post',
        url: shareUrl
      });
    } else {
      setShowSharePanel(true);
    }
  };

  const onCopyUrl = () => {
    navigator.clipboard.writeText(shareUrl);
  };

  const hasTextImgNoGallery = content.text && content.img && !content.gallery;

  const toggleReport = () => {
    setShowCommentInput(false);
    setShowSharePanel(false);
    const isShown = showReportInput;
    setShowReportInput(!isShown);
    setCommented(false);
    if (!isShown) {
      scrollIntoInput();
    }
  };

  const toggleComment = () => {
    setShowReportInput(false);
    setShowSharePanel(false);
    const isShown = showCommentInput;
    setShowCommentInput(!isShown);
    setCommented(false);
    if (!isShown) {
      scrollIntoInput();
    }
  };

  const scrollIntoInput = () => {
    setTimeout(() => {
      bmRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 100);
  };

  const scrollInputComments = () => {
    setTimeout(() => {
      commentsRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }, 100);
  };

  return (
    <div className={`newsfeed-content newsfeed-post ${mediaQuery ? 'newsfeed-post-full' : ''} px-15 py-10`}>
      {/* header */}
      <div className="d-flex justify-content-between">
        <div
          className="d-flex cursor-pointer"
          style={{
            boxSizing: "border-box",
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
            alignItems: "center",
          }}
        >
          <img
            src={content.avatar ?? '/img/general/profile.png'}
            style={{
              width: "30px",
              height: "30px",
              objectFit: "cover",
              borderRadius: "50%",
              marginRight: "12px",
            }}
          />
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>
            {content.author}
          </span>
          <button
            className="p-2 ml-15 button -dark-1 py-5 px-10 h-30 rounded-100 bg-blue-1 text-white text-12"
          >
            Follow
          </button>
        </div>

        <div className="p-2 flex-grow-1"></div>
        
        <div className="p-2 py-10">
          <div
            className="text-12 fw-600 bg-blue-1 text-white px-10 rounded-100"
            data-tooltip-id="post-tooltip"
            data-tooltip-html={content.likes ? `${content.likes} users liked this post` : 'No likes yet, be the first to like'}
            data-tooltip-variant="dark"
            data-tooltip-place="bottom"
          >
            <i className="icon-heart mr-5" />
            {content.likes ?? 0}
          </div>
        </div>

        <button
          className="p-2 button h-30 px-10 text-16 py-20"
          onClick={onClose}
        >
          <i className="icon-close py-5" />
        </button>
      </div>

      {/* content body */}
      <div className="news-post-ch py-10">

        <div className="row">
          <div className={`col-12 ${hasTextImgNoGallery ? 'col-md-6' : ''}`}>
            {/* text */}
            {content.text
              && <div className="text-14 px-10">
                {content.text}
              </div>
            }

            {/* tags */}
            {content.tags && content.tags.length > 0
              && <div className="mt-20 px-10">
                {content.tags.map(tag => (
                  <span
                    className="text-blue-1 text-14 fw-500 cursor-pointer mr-15"
                  >
                    #{getTagLabel(tag.label)}
                  </span>
                ))}
              </div>
            }
          </div>

          {/* single image no gallery */}
          {!content.gallery && content.img
            && <img
              className={`col-12 ${hasTextImgNoGallery ? 'col-md-6' : ''}`}
              loading="lazy"
              src={content.img}
            />
          }
        </div>

        {/* gallery */}
        {content.gallery
          && <div className="p-2 mt-5">
            <div className="photo-gallery d-flex">
              {content.gallery.map((d, i) => (
                <img
                  loading="lazy"
                  src={d}
                  onClick={() => {
                    setLightboxImages({
                      idx: i,
                      gallery: content.gallery.map(p => ({ src: p }))
                    });
                  }}
                />
              ))}
            </div>
          </div>
        }
      </div>

      {/* action bar */}
      <div className="d-flex justify-content-end border-bottom-light">
        <PostActionButton label="Report" light={showReportInput} onClick={toggleReport} />
        <PostActionButton label="Share" light={showSharePanel} onClick={onShare} />
        <PostActionButton icon="heart" label={liked ? 'Unlike' : 'Like'} light={liked} onClick={onLike} />
        <PostActionButton label="Comment" light={showCommentInput} onClick={toggleComment} />
      </div>

      <div ref={bmRef} className="">
        {showReportInput
          && <div className="border-bottom-light py-10 px-15">
            <div className="d-flex items-center justify-between">
              <div className="text-12 fw-500">Report this post</div>
              <button className="pointer" onClick={() => setShowReportInput(false)}>
                <i className="icon-close text-10" />
              </button>
            </div>
            <div className="mt-10 text-12">
              {reported
                && <span>You have reported this post</span>
              }
              {!reported
                && <>
                  {reportInputErr && <span className="pl-5 text-red-1">{reportInputErr}</span>}
                  <textarea
                    className="newsfeed-input px-10 py-10 text-14"
                    placeholder="Describe what is wrong or offensive about this post"
                    value={reportInput}
                    onChange={evt => setReportInput(evt.target.value)}
                  />
                  <div className="d-flex flex-row-reverse">
                    <button
                      className="p-2 button -dark-1 py-5 px-10 h-30 rounded-100 bg-blue-1 text-white text-12"
                      onClick={onReport}
                    >
                      Send
                    </button>
                  </div>
                </>
              }
            </div>
          </div>
        }

        {showCommentInput
          && <div className="border-bottom-light py-10 px-15">
            <div className="d-flex items-center justify-between">
              <div className="text-12 fw-500">Reply to this post</div>
              <button className="pointer" onClick={() => setShowCommentInput(false)}>
                <i className="icon-close text-10" />
              </button>
            </div>
            <div className="mt-10 text-12">
              {commented && <span className="pl-5 text-green-2">You have replied to this post</span>}
              {commentInputErr && <span className="pl-5 text-red-1">{commentInputErr}</span>}
              <textarea
                className="newsfeed-input px-10 py-10 text-14"
                placeholder="Enter your comment here"
                value={commentInput}
                onChange={evt => setCommentInput(evt.target.value)}
              />
              <div className="d-flex flex-row-reverse">
                <button
                  className="p-2 button -dark-1 py-5 px-10 h-30 rounded-100 bg-blue-1 text-white text-12"
                  onClick={onComment}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        }
      </div>

      {showSharePanel
        && <div className="border-bottom-light py-10 px-15">
          <div className="d-flex items-center justify-between">
            <div className="text-12 fw-500">Share Property Link</div>
            <button className="pointer" onClick={() => setShowSharePanel(false)}>
              <i className="icon-close text-10" />
            </button>
          </div>
          <div className="d-flex mt-10 text-12">
            <div
              className="p-2 flex-grow-1 text-light-1 h-30 py-0 newsfeed-input mr-10"
              style={{
                alignContent: 'center'
              }}
            >
              {shareUrl}
            </div>
            <button
              className="p-2 button -dark-1 py-5 px-10 h-30 rounded-100 bg-blue-1 text-white text-12"
              onClick={() => onCopyUrl()}
            >
              Copy
            </button>
          </div>
        </div>
      }

      {/* replies */}
      {/* {(!replies || replies.length === 0)
        && <div className="text-center py-20 text-14">
          No comments yet
        </div>
      } */}
      <div ref={commentsRef} className="mt-10 noselect">
        {replies.map(row =>
          row.authorId === 'test'
            ? <div className="d-flex flex-row-reverse mb-5">
                <div className="fab-chat fab-user-chat">
                  <span className="fw-600 text-12">
                    You commented {getTimeLabel(row.createdAt, 'just now', ' ago')}:<br />
                  </span>
                  {row.text}
                </div>
              </div>
            : <div className="fab-chat mb-5">
                <div className="d-flex mt-10">
                  <img
                    className="p-2 px-0 py-0"
                    src={row.avatar ?? '/img/general/profile.png'}
                    style={{
                      width: "38px",
                      height: "38px",
                      objectFit: "cover",
                      borderRadius: "50%",
                      marginRight: "12px",
                    }}
                  />
                  <span className="p-2 fw-600 text-12">{row.author} commented {getTimeLabel(row.createdAt, 'just now', ' ago')}</span>
                </div>
                <div className="mt-5 py-5">
                  {row.text}
                </div>
              </div>
        )}
      </div>

      <Tooltip id="post-tooltip" />
    </div>
  );
};

export default PostView;
