import { useState } from 'react';

import CustomTable from '@/components/transactions/CustomTable';
import { convertDisplayDate, dateStrToMsec } from '@/utils/time';
import { STANDARD_PAGE_CONFIG } from '@/utils/table';
import { Link } from 'react-router-dom';
import { calculateAnnualized, getBlockUnitFromAddress } from '@/utils/areas';
import { MAP_MODE_HDB } from '@/utils/map';

const ProfitTable = ({
  raw,
  data,
  target,
  name,
  onFilterButtonClick,
  goToUnit
}) => {
  const DEFAULT_COLUMNS = target.mode === MAP_MODE_HDB
    ? [
        {
          Header: 'Sale Date',
          accessor: 'sale_date',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>{convertDisplayDate(new Date(row.cell.value))}</div>
        },
        {
          Header: 'Transacted Price ($)',
          accessor: 'transacted_price',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>${row.cell.value.toLocaleString()}</div>
        },
        {
          Header: 'Annualized Price Increase %',
          accessor: 'annualized_profit',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div
            className={
              ((row.cell.value - 10000) / 100) > 0 ? 'text-green-2' : 'text-red-1'
            }
          >
            {((row.cell.value - 10000) / 100).toFixed(1)}%
          </div>
        },
        {
          Header: 'Unit Price ($psf)',
          accessor: 'unitPrice',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>${(row.cell.value.toFixed(2))}</div>
        },
        {
          Header: 'Area (sft)',
          accessor: 'area',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>{row.cell.value.toLocaleString()}</div>
        },
        {
          Header: 'Flat Type',
          accessor: 'flatType',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Storey',
          accessor: 'storeyRange',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Flat Model',
          accessor: 'flatModel',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>{row.cell.value?.toUpperCase()}</div>
        },
      ]
    : [
        {
          Header: 'Sale Date',
          accessor: 'sale_date',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>{convertDisplayDate(new Date(row.cell.value))}</div>
        },
        {
          Header: 'Type of Sale',
          accessor: 'type',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Transacted Price ($)',
          accessor: 'transacted_price',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>${row.cell.value.toLocaleString()}</div>
        },
        {
          Header: 'Unit Price ($psf)',
          accessor: 'unitPrice',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>${row.cell.value.toFixed(2)}</div>
        },
        {
          Header: 'Area (sft)',
          accessor: 'area',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>{row.cell.value.toLocaleString()}</div>
        },
        {
          Header: 'Address',
          accessor: 'address',
          disableGlobalFilter: true,
          disableSortBy: true,
          Cell: (row) => <Link
            className="text-blue-1"
            data-bs-toggle="offcanvas"
            data-bs-target="#listingDetails"
            onClick={() => {
              const txs = raw.filter(t => t.address === row.cell.value).map(t => ({ ...t, ts: dateStrToMsec(t.saleDate) }));
              const blockInfo = getBlockUnitFromAddress(row.cell.value);
              const unit = {
                displayBlock: blockInfo.block,
                tx: txs,
                gains: txs.map((tx, i) => {
                  const gain = calculateAnnualized(i, txs);
                  return gain !== null ? {
                    ts: dateStrToMsec(tx.saleDate),
                    gain,  
                  } : null
                }),
                unit: blockInfo.unit
              };
              goToUnit(unit);
            }}
          >
            {row.cell.value}
          </Link>
        },
        {
          Header: 'Postal Code',
          accessor: 'postal',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Property Type',
          accessor: 'propertyType',
          disableGlobalFilter: true,
          width: 90,
        },
        {
          Header: 'Profit ($)',
          accessor: 'profit_value',
          disableGlobalFilter: true,
          width: 100,
          Cell: (row) => <div
            className={
              (row.cell.value - 1000000000000) > 0 ? 'text-green-2' : 'text-red-1'
            }
          >
            {
              (row.cell.value - 1000000000000) > 0 ? '' : '-'
            }${
              Math.abs(row.cell.value - 1000000000000).toLocaleString()
            }
          </div>
        },
        {
          Header: 'Annualized Profit %',
          accessor: 'annualized_profit',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div
            className={
              ((row.cell.value - 10000) / 100) > 0 ? 'text-green-2' : 'text-red-1'
            }
          >
            {((row.cell.value - 10000) / 100).toFixed(1)}%
          </div>
        },
        {
          Header: 'Prev Transacted (Years)',
          accessor: 'prevTxYears',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>{parseInt(row.cell.value)}</div>
        },
        {
          Header: 'Type of Area',
          accessor: 'areaType',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Purchaser Address Indicator',
          accessor: 'purchaserAddrIndicator',
          disableGlobalFilter: true,
          width: 80,
        },
      ];

  const [fullColumns, setFullColumns] = useState(DEFAULT_COLUMNS.map(col => ({ ...col, enabled: true })));
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);

  return (
    <CustomTable
      name={name}
      data={
        data.map(row => ({
          ...row,
          'sale_date': dateStrToMsec(row.saleDate),
          'annualized_profit': parseInt(10000 + (100 * row.profitPerc)),
          'transacted_price': parseInt(row.price),
          'profit_value': row.profit + 1000000000000,
        })).sort((a, b) => b.sale_date - a.sale_date)
      }
      tableConfig={STANDARD_PAGE_CONFIG}
      defaultColumns={DEFAULT_COLUMNS}
      fullColumns={fullColumns}
      setFullColumns={setFullColumns}
      columns={columns}
      setColumns={setColumns}
      hideSearchInput
      hidePageButton
      showFilterButton
      onFilterButtonClick={onFilterButtonClick}
    />
  );
};

export default ProfitTable;
