import { useState } from 'react';

import CustomTable from '@/components/transactions/CustomTable';
import { NA, formatDistanceLabel } from '@/utils/convert';
import { STANDARD_PAGE_CONFIG } from '@/utils/table';
import { MAP_MODE_HDB, getProjectLabel, simplifyHdbTypes } from '@/utils/map';

const SimilarPropsTable = ({
  data,
  property,
  goToProperty,
  target
}) => {
  const DEFAULT_COLUMNS = target.mode === MAP_MODE_HDB
    ? [
        {
          Header: 'HDB',
          accessor: 'name',
          disableGlobalFilter: true,
          Cell: (row) =>
            row.cell.value === undefined
              ? <div className="fw-500">
                  {target.name}
                </div>
              : <a
                  className="text-blue-1 cursor-pointer"
                  onClick={() => goToProperty(row)}
                >
                  {row.cell.value}
                </a>
        },
        {
          Header: 'Distance',
          accessor: 'distance',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>
            {formatDistanceLabel(row.cell.value ?? 0, true)}
          </div>
        },
        {
          Header: 'Profitable (%)',
          accessor: 'profitable',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div className={
            isNaN(parseFloat(row.cell.value)) || row.cell.value === property.profitable ? 'text-dark'
              : row.cell.value > property.profitable ? 'text-green-2' : 'text-red-1'
          }>
            {isNaN(parseFloat(row.cell.value)) ? '0.0%' : `${row.cell.value.toFixed(1)}%`}
          </div>
        },
        {
          Header: 'Property Types',
          accessor: 'flatTypes',
          disableGlobalFilter: true,
          disableSortBy: true,
          width: 80,
          Cell: (row) => <div>
            {simplifyHdbTypes(row.cell.value)}
          </div>
        },
        {
          Header: 'Completion',
          accessor: 'completion',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Total Units',
          accessor: 'totalUnits',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>
            {row.cell.value !== -1 ? row.cell.value?.toLocaleString() : '-'}
          </div>
        },
      ]
  : [
        {
          Header: 'Project',
          accessor: 'label',
          disableGlobalFilter: true,
          Cell: (row) =>
            row.cell.value === undefined
              ? <div className="fw-500">
                  {getProjectLabel(target.marker, target.project)}
                </div>
              : <a
                  className="text-blue-1 cursor-pointer"
                  onClick={() => goToProperty(row)}
                >
                  {row.cell.value}
                </a>
        },
        {
          Header: 'Distance',
          accessor: 'distance',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>
            {formatDistanceLabel(row.cell.value ?? 0, true)}
          </div>
        },
        {
          Header: 'Profitable (%)',
          accessor: 'profitable',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div className={
            isNaN(parseFloat(row.cell.value)) || row.cell.value === property.profitable ? 'text-dark'
              : row.cell.value > property.profitable ? 'text-green-2' : 'text-red-1'
          }>
            {isNaN(parseFloat(row.cell.value)) ? '0.0%' : `${row.cell.value.toFixed(1)}%`}
          </div>
        },
        {
          Header: 'Avg PSF (Last 3mths)',
          accessor: 'last3mAvgPsf',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div className={
            isNaN(parseFloat(row.cell.value)) || row.cell.value === property.avgPsf3m ? 'text-dark'
              : row.cell.value < property.avgPsf3m ? 'text-green-2' : 'text-red-1'
          }>
            {isNaN(parseFloat(row.cell.value)) ? NA : `$${row.cell.value.toFixed(2)}`}
          </div>
        },
        {
          Header: 'Property Types',
          accessor: 'types',
          disableGlobalFilter: true,
          disableSortBy: true,
          width: 80,
          Cell: (row) => <div>
            {row.cell.value?.join(', ')}
          </div>
        },
        {
          Header: 'HDB Buyers %',
          accessor: 'hdbBuyers',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>
            {row.cell.value !== null && row.cell.value !== undefined ? `${row.cell.value.toFixed(0)}%` : ''}
          </div>
        },
        {
          Header: 'Completion',
          accessor: 'completion',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Tenure Available',
          accessor: 'tenures',
          disableGlobalFilter: true,
          disableSortBy: true,
          width: 80,
          Cell: (row) => <div>
            {row.cell.value.length > 0 ? row.cell.value.join(', ') : '-'}
          </div>
        },
        {
          Header: 'Total Units',
          accessor: 'totalUnits',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>
            {row.cell.value !== -1 && !isNaN(row.cell.value) ? row.cell.value?.toLocaleString() : '-'}
          </div>
        },
        {
          Header: '% Sold at Launch',
          accessor: 'soldAtLaunch',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>
            {
              row.cell.value !== null && row.cell.value !== undefined && row.cell.value >= 0
                ? `${row.cell.value.toFixed(0)}%` : '-'
            }
          </div>
        },
      ];

  const [fullColumns, setFullColumns] = useState(DEFAULT_COLUMNS.map(col => ({ ...col, enabled: true })));
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);

  return (
    <CustomTable
      data={[ property, ...data ]}
      tableConfig={STANDARD_PAGE_CONFIG}
      defaultColumns={DEFAULT_COLUMNS}
      fullColumns={fullColumns}
      setFullColumns={setFullColumns}
      columns={columns}
      setColumns={setColumns}
      hideSearchInput
      hidePageButton
    />
  );
};

export default SimilarPropsTable;
