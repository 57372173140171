import { checkMediaQuery, isLoggedIn } from "@/utils/user";

const PropertyButtons = ({
  options,
  selection,
  onSelect,
  user = null
}) => {
  const isSmallScreen = window.innerWidth < 395;

  return (
    <div className="d-flex flex-wrap justify-content-start py-0 pt-10 noselect">
      {options.filter(option => !option.nonNull || selection?.[option.id] !== null || (isLoggedIn(user) && option.userOnly)).map(option =>
        <button
          key={option.id}
          className={`p-2 button mr-5 mb-5 ${
            !selection?.[option.id]
              ? '-dark-1 bg-blue-1 text-white'
              : '-blue-1 bg-blue-1-05 text-blue-1'
          } h-30 px-10 rounded-100 text-12`}
          onClick={() => option.onClick ? option.onClick() : onSelect(option.id)}
        >
          <i className={`${
            !selection?.[option.id] ? option.icon : (option.altIcon ?? option.icon)
          } text-10`} />
          {option.label
            && <span className="ml-5">
              {
                !selection?.[option.id] ? option.label : (option.altLabel ?? option.label)
              }
            </span>
          }
          {!option.label && option.altLabel && (!checkMediaQuery() || !isSmallScreen)
            && <span className="ml-5">
              {option.altLabel}
            </span>
          }
        </button>
      )
    }
    </div>
  );
};

export default PropertyButtons;
