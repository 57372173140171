import {
  HDB_TOWNS,
  LOCATION_BLOCK,
  LOCATION_MARKER,
  LOCATION_PROPERTY,
  LOCATION_SCHOOL,
  LOCATION_STATION,
  LOCATION_UPCOMING,
  MARKET_SEGMENTS,
  MARKET_SEGMENT_ID_MAP,
  POSTAL_DISTRICTS,
  REGIONS,
  convertIntToPropertyType,
  convertIntToSaleType,
  convertIntToTenureType,
  expandId
} from "./areas";
import {
  FILTER_AVG_PRICE,
  FILTER_COMPLETION_DATE,
  FILTER_HDB_BUYERS,
  FILTER_HDB_FLAT_TYPE,
  FILTER_HDB_LOCATION,
  FILTER_LAUNCH_PERC,
  FILTER_LOCATION,
  FILTER_MAP_TRANSACTION_DATE,
  FILTER_PROFITABLE,
  FILTER_PROPERTY_AGE,
  FILTER_PROPERTY_TYPE,
  FILTER_PSF_RENTAL_6M,
  FILTER_SIZE,
  FILTER_TENURE,
  FILTER_TOTAL_UNITS
} from "@/utils/filter";
import { deepCopy, getFloorFromAddress } from "./convert";
import { capitalizeAll } from "./text";
import { dateStrToDateObj, dateStrToMsec } from "./time";

export const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

export const HOME_ICON = "M13.22,2.984c-1.125,0-2.504,0.377-3.53,1.182C8.756,3.441,7.502,2.984,6.28,2.984c-2.6,0-4.714,2.116-4.714,4.716c0,0.32,0.032,0.644,0.098,0.96c0.799,4.202,6.781,7.792,7.46,8.188c0.193,0.111,0.41,0.168,0.627,0.168c0.187,0,0.376-0.041,0.55-0.127c0.011-0.006,1.349-0.689,2.91-1.865c0.021-0.016,0.043-0.031,0.061-0.043c0.021-0.016,0.045-0.033,0.064-0.053c3.012-2.309,4.6-4.805,4.6-7.229C17.935,5.1,15.819,2.984,13.22,2.984z M12.544,13.966c-0.004,0.004-0.018,0.014-0.021,0.018s-0.018,0.012-0.023,0.016c-1.423,1.076-2.674,1.734-2.749,1.771c0,0-6.146-3.576-6.866-7.363C2.837,8.178,2.811,7.942,2.811,7.7c0-1.917,1.554-3.47,3.469-3.47c1.302,0,2.836,0.736,3.431,1.794c0.577-1.121,2.161-1.794,3.509-1.794c1.914,0,3.469,1.553,3.469,3.47C16.688,10.249,14.474,12.495,12.544,13.966z";
export const SCHOOL_ICON = "M8.627,7.885C8.499,8.388,7.873,8.101,8.13,8.177L4.12,7.143c-0.218-0.057-0.351-0.28-0.293-0.498c0.057-0.218,0.279-0.351,0.497-0.294l4.011,1.037C8.552,7.444,8.685,7.667,8.627,7.885 M8.334,10.123L4.323,9.086C4.105,9.031,3.883,9.162,3.826,9.38C3.769,9.598,3.901,9.82,4.12,9.877l4.01,1.037c-0.262-0.062,0.373,0.192,0.497-0.294C8.685,10.401,8.552,10.18,8.334,10.123 M7.131,12.507L4.323,11.78c-0.218-0.057-0.44,0.076-0.497,0.295c-0.057,0.218,0.075,0.439,0.293,0.495l2.809,0.726c-0.265-0.062,0.37,0.193,0.495-0.293C7.48,12.784,7.35,12.562,7.131,12.507M18.159,3.677v10.701c0,0.186-0.126,0.348-0.306,0.393l-7.755,1.948c-0.07,0.016-0.134,0.016-0.204,0l-7.748-1.948c-0.179-0.045-0.306-0.207-0.306-0.393V3.677c0-0.267,0.249-0.461,0.509-0.396l7.646,1.921l7.654-1.921C17.91,3.216,18.159,3.41,18.159,3.677 M9.589,5.939L2.656,4.203v9.857l6.933,1.737V5.939z M17.344,4.203l-6.939,1.736v9.859l6.939-1.737V4.203z M16.168,6.645c-0.058-0.218-0.279-0.351-0.498-0.294l-4.011,1.037c-0.218,0.057-0.351,0.28-0.293,0.498c0.128,0.503,0.755,0.216,0.498,0.292l4.009-1.034C16.092,7.085,16.225,6.863,16.168,6.645 M16.168,9.38c-0.058-0.218-0.279-0.349-0.498-0.294l-4.011,1.036c-0.218,0.057-0.351,0.279-0.293,0.498c0.124,0.486,0.759,0.232,0.498,0.294l4.009-1.037C16.092,9.82,16.225,9.598,16.168,9.38 M14.963,12.385c-0.055-0.219-0.276-0.35-0.495-0.294l-2.809,0.726c-0.218,0.056-0.351,0.279-0.293,0.496c0.127,0.506,0.755,0.218,0.498,0.293l2.807-0.723C14.89,12.825,15.021,12.603,14.963,12.385";
export const SHOP_ICON = "M17.671,13.945l0.003,0.002l1.708-7.687l-0.008-0.002c0.008-0.033,0.021-0.065,0.021-0.102c0-0.236-0.191-0.428-0.427-0.428H5.276L4.67,3.472L4.665,3.473c-0.053-0.175-0.21-0.306-0.403-0.306H1.032c-0.236,0-0.427,0.191-0.427,0.427c0,0.236,0.191,0.428,0.427,0.428h2.902l2.667,9.945l0,0c0.037,0.119,0.125,0.217,0.239,0.268c-0.16,0.26-0.257,0.562-0.257,0.891c0,0.943,0.765,1.707,1.708,1.707S10,16.068,10,15.125c0-0.312-0.09-0.602-0.237-0.855h4.744c-0.146,0.254-0.237,0.543-0.237,0.855c0,0.943,0.766,1.707,1.708,1.707c0.944,0,1.709-0.764,1.709-1.707c0-0.328-0.097-0.631-0.257-0.891C17.55,14.182,17.639,14.074,17.671,13.945 M15.934,6.583h2.502l-0.38,1.709h-2.312L15.934,6.583zM5.505,6.583h2.832l0.189,1.709H5.963L5.505,6.583z M6.65,10.854L6.192,9.146h2.429l0.19,1.708H6.65z M6.879,11.707h2.027l0.189,1.709H7.338L6.879,11.707z M8.292,15.979c-0.472,0-0.854-0.383-0.854-0.854c0-0.473,0.382-0.855,0.854-0.855s0.854,0.383,0.854,0.855C9.146,15.596,8.763,15.979,8.292,15.979 M11.708,13.416H9.955l-0.189-1.709h1.943V13.416z M11.708,10.854H9.67L9.48,9.146h2.228V10.854z M11.708,8.292H9.386l-0.19-1.709h2.512V8.292z M14.315,13.416h-1.753v-1.709h1.942L14.315,13.416zM14.6,10.854h-2.037V9.146h2.227L14.6,10.854z M14.884,8.292h-2.321V6.583h2.512L14.884,8.292z M15.978,15.979c-0.471,0-0.854-0.383-0.854-0.854c0-0.473,0.383-0.855,0.854-0.855c0.473,0,0.854,0.383,0.854,0.855C16.832,15.596,16.45,15.979,15.978,15.979 M16.917,13.416h-1.743l0.189-1.709h1.934L16.917,13.416z M15.458,10.854l0.19-1.708h2.218l-0.38,1.708H15.458z";
export const SPORT_ICON = "M23.76 9.58A11.99 11.99 0 0 0 0 12a12.08 12.08 0 0 0 3.51 8.49 12.12 12.12 0 0 0 6.07 3.27A11.99 11.99 0 0 0 24 12a12 12 0 0 0-.24-2.42zm-1.51 2.32c-.15-.03-3.62-.78-7.14-.34a38.64 38.64 0 0 0-.9-2.01c4.04-1.66 5.69-4.03 5.7-4.06a10.2 10.2 0 0 1 2.34 6.4zm-3.48-7.6c-.03.05-1.49 2.27-5.35 3.72a52.06 52.06 0 0 0-3.83-5.98 10.23 10.23 0 0 1 9.18 2.27zM7.63 2.74a61.6 61.6 0 0 1 3.8 5.9A37.91 37.91 0 0 1 1.97 9.9c.67-3.18 2.8-5.8 5.66-7.16zM1.75 12l.01-.32c.18 0 5.25.11 10.52-1.46.3.57.58 1.15.83 1.74l-.4.12c-5.53 1.79-8.34 6.76-8.34 6.76A10.21 10.21 0 0 1 1.76 12zM12 22.25a10.2 10.2 0 0 1-6.53-2.35l.23.18s1.97-4.29 8.04-6.4l.07-.02a42.64 42.64 0 0 1 2.2 7.78 10.2 10.2 0 0 1-4.01.8zm5.73-1.75c-.1-.62-.65-3.63-2-7.32 3.31-.53 6.18.38 6.39.45a10.26 10.26 0 0 1-4.4 6.87z";
export const TRANSPORT_ICON = "M12 2c-4 0-8 .5-8 4v9.5C4 17.43 5.57 19 7.5 19l-1.15 1.15c-.31.31-.09.85.36.85H7.8c.13 0 .26-.05.35-.15L10 19h4l1.85 1.85c.09.09.22.15.35.15h1.09c.45 0 .67-.54.35-.85L16.5 19c1.93 0 3.5-1.57 3.5-3.5V6c0-3.5-4-4-8-4zM7.5 17c-.83 0-1.5-.67-1.5-1.5S6.67 14 7.5 14s1.5.67 1.5 1.5S8.33 17 7.5 17zm3.5-7H6V6h5v4zm5.5 7c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm1.5-7h-5V6h5v4z";
export const ATTRACTION_ICON = "M10,6.536c-2.263,0-4.099,1.836-4.099,4.098S7.737,14.732,10,14.732s4.099-1.836,4.099-4.098S12.263,6.536,10,6.536M10,13.871c-1.784,0-3.235-1.453-3.235-3.237S8.216,7.399,10,7.399c1.784,0,3.235,1.452,3.235,3.235S11.784,13.871,10,13.871M17.118,5.672l-3.237,0.014L12.52,3.697c-0.082-0.105-0.209-0.168-0.343-0.168H7.824c-0.134,0-0.261,0.062-0.343,0.168L6.12,5.686H2.882c-0.951,0-1.726,0.748-1.726,1.699v7.362c0,0.951,0.774,1.725,1.726,1.725h14.236c0.951,0,1.726-0.773,1.726-1.725V7.195C18.844,6.244,18.069,5.672,17.118,5.672 M17.98,14.746c0,0.477-0.386,0.861-0.862,0.861H2.882c-0.477,0-0.863-0.385-0.863-0.861V7.384c0-0.477,0.386-0.85,0.863-0.85l3.451,0.014c0.134,0,0.261-0.062,0.343-0.168l1.361-1.989h3.926l1.361,1.989c0.082,0.105,0.209,0.168,0.343,0.168l3.451-0.014c0.477,0,0.862,0.184,0.862,0.661V14.746z";
export const PROPERTY_ICON = "M18.121,9.88l-7.832-7.836c-0.155-0.158-0.428-0.155-0.584,0L1.842,9.913c-0.262,0.263-0.073,0.705,0.292,0.705h2.069v7.042c0,0.227,0.187,0.414,0.414,0.414h3.725c0.228,0,0.414-0.188,0.414-0.414v-3.313h2.483v3.313c0,0.227,0.187,0.414,0.413,0.414h3.726c0.229,0,0.414-0.188,0.414-0.414v-7.042h2.068h0.004C18.331,10.617,18.389,10.146,18.121,9.88 M14.963,17.245h-2.896v-3.313c0-0.229-0.186-0.415-0.414-0.415H8.342c-0.228,0-0.414,0.187-0.414,0.415v3.313H5.032v-6.628h9.931V17.245z M3.133,9.79l6.864-6.868l6.867,6.868H3.133z";

export const pinStyle = {
  cursor: 'pointer',
  fill: '#3554d1',
  stroke: 'none',
};

export const homePinStyle = {
  ...pinStyle,
  fill: '#DE3163',
};

export const blockPinStyle = {
  ...pinStyle,
  fill: '#0d2857'
};

export const radiusMaskStyle = (id) => ({
  id: `circle-mask-${id}`,
  type: 'fill',
  paint: {
    'fill-color': 'rgba(0, 0, 0, 0.1)',
  }
});

export const radiusLabelStyle = (id) => ({
  id: `circle-label-${id}`,
  type: 'symbol',
  layout: {
    'text-field': ['get', 'label'],
    'text-size': 16,
    'text-offset': [0, 1.5],
    'text-anchor': 'bottom',
  },
  paint: {
    'text-color': 'rgba(0, 0, 0, 0.8)',
  }
});

export const getMarkerIcon = (marker) => {
  if (marker.type === 'Schools') return SCHOOL_ICON;
  if (marker.type === 'Shopping') return SHOP_ICON;
  if (marker.type === 'Sports') return SPORT_ICON;
  if (marker.type === 'Transport') return TRANSPORT_ICON;
  if (marker.type === 'Property') return PROPERTY_ICON;
  return ATTRACTION_ICON;
};

export const getMarkerScale = (marker) => {
  if (marker.type === 'Schools') return 'scale(0.4),translate(20,7)';
  if (marker.type === 'Sports') return 'scale(0.25),translate(35,14)';
  if (marker.type === 'Transport') return 'scale(0.32),translate(25,10)';
  if (marker.type === 'Property') return 'scale(0.4),translate(20,6)';
  return 'scale(0.4),translate(20,8)';
};

export const getMarkerPriColor = (marker) => {
  if (marker.type === 'Schools') return '#008009';
  if (marker.type === 'Sports') return '#7e53f9';
  if (marker.type === 'Transport') return '#927238';
  if (marker.type === 'Property') return '#DE3163';
  return '#003399';
};

export const getMarkerStyle = (marker) => {
  const style = deepCopy(pinStyle);
  if (marker.type === 'Schools') style.fill = '#008009';
  if (marker.type === 'Sports') style.fill = '#7e53f9';
  if (marker.type === 'Transport') style.fill = '#927238';
  if (marker.type === 'Property') style.fill = '#DE3163';
  return style;
};

export const generateLineData = (location1, location2) => ({
  type: 'Feature',
  geometry: {
    type: 'LineString',
    coordinates: [
      [location1.lng, location1.lat],
      [location2.lng, location2.lat]
    ]
  }
});

export const generateLinesData = (property, locations) => ({
  type: 'FeatureCollection',
  features: locations.map(location => generateLineData(property, location))
});

export const generateMultipleLinesData = (properties, prop) => {
  const data = [];
  properties.forEach(property => {
    property[prop]?.forEach(p => data.push(generateLineData(property, p)))
  });
  return {
    type: 'FeatureCollection',
    features: data
  };
};

export const generateLineStyle = (marker) => {
  const color = getMarkerPriColor(marker);
  return {
    id: 'route',
    type: 'line',
    paint: {
      'line-color': color,
      'line-width': 2,
      'line-dasharray': [2, 2],
    }
  };
};

export const MAP_FILTER_OPTIONS = [
  FILTER_COMPLETION_DATE,
  FILTER_MAP_TRANSACTION_DATE,
  FILTER_PROPERTY_TYPE,
  FILTER_TENURE,
  FILTER_PROPERTY_AGE,
  FILTER_PROFITABLE,
  FILTER_AVG_PRICE,
  FILTER_SIZE,
  FILTER_HDB_BUYERS,
  FILTER_TOTAL_UNITS,
  FILTER_LAUNCH_PERC,
  FILTER_PSF_RENTAL_6M,
  FILTER_LOCATION
];

export const HDB_MAP_FILTER_OPTIONS = [
  FILTER_COMPLETION_DATE,
  FILTER_MAP_TRANSACTION_DATE,
  FILTER_HDB_FLAT_TYPE,
  FILTER_PROPERTY_AGE,
  FILTER_PROFITABLE,
  FILTER_SIZE,
  FILTER_TOTAL_UNITS,
  // FILTER_PSF_RENTAL_6M,
  FILTER_HDB_LOCATION
];

export const PROFITABLE_SCHEMES = [
  {
    id: 'red',
    label: 'Profitable - RED',
    hint: 'Most profitable properties will be red, least profitable will be blue',
    colors: {
      top: '#e74c3c',
      high: '#e67e22',
      mid: '#f1c40f',
      low: '#3498db',
      none: '#34495e',
      upcoming: '#1abc9c'
    },
    texts: {
      top: 'red',
      high: 'orange',
      mid: 'yellow',
      low: 'blue',
      none: 'grey'
    },
    icons: {
      top: 'p_high',
      high: 'p_mid',
      mid: 'p_low',
      low: 'p_lowest',
      none: 'p_none',
      upcoming: 'p_new',
    }
  },
  {
    id: 'green',
    label: 'Profitable - GREEN',
    hint: 'Most profitable properties will be green, least profitable will be red',
    colors: {
      top: '#1abc9c',
      high: '#f1c40f',
      mid: '#e67e22',
      low: '#e74c3c',
      none: '#34495e',
      upcoming: '#3498db'
    },
    texts: {
      top: 'green',
      high: 'yellow',
      mid: 'orange',
      low: 'red',
      none: 'grey'
    },
    icons: {
      top: 'p_new',
      high: 'p_low',
      mid: 'p_mid',
      low: 'p_high',
      none: 'p_none',
      upcoming: 'p_lowest',
    }
  },
];

export const PROFITABLE_SCHEMES_MAP = {
  'red': PROFITABLE_SCHEMES[0],
  'green': PROFITABLE_SCHEMES[1]
};

export const PROP_TYPES_FILTER_OPTIONS = [
  {
    id: 'nonland',
    label: 'Condo/Apartment/EC',
  },
  {
    id: 'land',
    label: 'Landed Property',
  },
];

export const getUpcomingMarkerColor = (scheme) => PROFITABLE_SCHEMES_MAP[scheme].colors.upcoming;

export const getPropertyMarkerColor = (scheme, data, field) => {
  if (field === 'avgPsf3m') {
    if (data.avgPsf3m === null || data.avgPsf3m < 0 || data.fairPsf === null || data.fairPsf < 0) return '#34495e';
    if (data.fairPsf < data.avgPsf3m) return '#e74c3c';
    return '#1abc9c';
  }
  if (field === 'profitable') {
    if (data[field] === null) return PROFITABLE_SCHEMES_MAP[scheme].colors.none;
    if (data[field] > 90) return PROFITABLE_SCHEMES_MAP[scheme].colors.top;
    if (data[field] > 70) return PROFITABLE_SCHEMES_MAP[scheme].colors.high;
    if (data[field] > 50) return PROFITABLE_SCHEMES_MAP[scheme].colors.mid;
    return PROFITABLE_SCHEMES_MAP[scheme].colors.low;
  }
  return '#DE3163';
};

export const getProfitableTextClass = (scheme, value) => {
  if (value === null || value < 0) return 'map-marker-color-' + PROFITABLE_SCHEMES_MAP[scheme].texts.none;
  if (value > 90) return 'map-marker-color-' + PROFITABLE_SCHEMES_MAP[scheme].texts.top;
  if (value > 70) return 'map-marker-color-' + PROFITABLE_SCHEMES_MAP[scheme].texts.high;
  if (value > 50) return 'map-marker-color-' + PROFITABLE_SCHEMES_MAP[scheme].texts.mid;
  return 'map-marker-color-' + PROFITABLE_SCHEMES_MAP[scheme].texts.low;
};

export const getPropertyTextColor = (scheme, data, field) => {
  if (field === 'fairPsf') {
    if (data.fairPsf === null || data.fairPsf < 0 || data.avgPsf3m === null || data.avgPsf3m < 0) return 'map-marker-color-grey';
    if (data.fairPsf < data.avgPsf3m) return 'map-marker-color-red';
    return 'map-marker-color-blue';
  }
  if (field === 'profitable') {
    if (data[field] === null || data[field] < 0) return 'map-marker-color-' + PROFITABLE_SCHEMES_MAP[scheme].texts.none;
    if (data[field] > 90) return 'map-marker-color-' + PROFITABLE_SCHEMES_MAP[scheme].texts.top;
    if (data[field] > 70) return 'map-marker-color-' + PROFITABLE_SCHEMES_MAP[scheme].texts.high;
    if (data[field] > 50) return 'map-marker-color-' + PROFITABLE_SCHEMES_MAP[scheme].texts.mid;
    return 'map-marker-color-' + PROFITABLE_SCHEMES_MAP[scheme].texts.low;
  }
  return 'map-marker-color-grey';
};

export const getPropertyBgColor = (scheme, data, field) => {
  if (field === 'fairPsf') {
    if (data[field] === null || data.avgPsf3m === null) return 'map-marker-bg-grey';
    if (data.avgPsf3m > data[field]) return 'map-marker-bg-red';
    return 'map-marker-bg-blue';
  }
  if (field === 'profitable') {
    if (data[field] === null || data[field] < 0) return 'map-marker-bg-' + PROFITABLE_SCHEMES_MAP[scheme].texts.none;
    if (data[field] > 90) return 'map-marker-bg-' + PROFITABLE_SCHEMES_MAP[scheme].texts.top;
    if (data[field] > 70) return 'map-marker-bg-' + PROFITABLE_SCHEMES_MAP[scheme].texts.high;
    if (data[field] > 50) return 'map-marker-bg-' + PROFITABLE_SCHEMES_MAP[scheme].texts.mid;
    return 'map-marker-bg-' + PROFITABLE_SCHEMES_MAP[scheme].texts.low;
  }
  return 'map-marker-bg-grey';
};

export const getPropertyFileName = (str) => {
  // Replace all non-alphanumeric characters (except letters and numbers, including accented letters) with underscores
  str = str.replace(/[^\p{L}\p{N}]+/gu, '_');
  // Replace multiple underscores with a single underscore
  str = str.replace(/_+/g, '_');
  // Strip leading and trailing underscores
  str = str.replace(/^_+|_+$/g, '');
  return str.toLowerCase();
};

const getSchoolType = (code) => {
  if (code === '0') return 'Preschool';
  if (code === '1') return 'Primary School';
  if (code === '2') return 'Secondary School';
  if (code === '3') return 'Tertiary School';
  return '';
};

const getStationType = (code) => {
  if (code === '1') return 'MRT';
  if (code === '2') return 'LRT';
  return '';
};

const getArea = (code) => {
  const c = code.split(',');
  const regionIdx = parseInt(c[0]);
  const areaIdx = parseInt(c[1]);
  return REGIONS[regionIdx].areas[areaIdx];
};

const getStationExit = (name) => name.indexOf(' EXIT ') ? name.split(' EXIT ')[1] : null;

export const groupPropsByMarker = (props) => {
  const index = {};
  const markers = [];
  props.forEach(prop => {
    let marker = null;
    if (!(prop.marker in index)) {
      marker = {
        name: prop.marker,
        district: prop.district,
        lat: prop.lat,
        lng: prop.lng,
        totalTx: 0,
        lastTxDate: null,
        profitable: null,
        properties: [],
        type: LOCATION_MARKER
      };
      index[prop.marker] = markers.length;
      markers.push(marker);
    }
    const idx = index[prop.marker];
    markers[idx].properties.push(prop);
    markers[idx].totalTx += prop.totalTx ?? 0;
    if (!markers[idx].lastTxDate || prop.lastTxDate > markers[idx].lastTxDate) {
      // only the freshest project profitability will be used as marker's profitability
      markers[idx].lastTxDate = prop.lastTxDate;
      markers[idx].profitable = prop.profitable;
    }
  });
  return markers;
};

const upcomingPropTypeMap = {
  '1': 'Apartment',
  '2': 'Semi-Detached House',
  '3': 'Detached House',
  '4': 'Terrace',
  '5': 'Condominium',
  '0': 'Executive Condominium'
};

const dataPropTypeMap = {
  '0': 'Apartment',
  '1': 'Semi-Detached House',
  '2': 'Detached House',
  '3': 'Terrace House',
  '4': 'Condominium',
  '5': 'Executive Condominium',
  '6': 'Non-landed Properties'
}

const expandPsf = (psf) => {
  if (!psf) return null;
  const l = psf.split('|');
  const data = {};
  l.forEach(d => {
    const c = d.split(':');
    if (c.length !== 2 || !c[1]) return;
    data[dataPropTypeMap[c[0]]] = parseFloat(c[1]);
  });
  return data;
};

export const getProjectLabel = (markerName, projectName) => markerName === projectName || projectName === 'NIL' ? markerName : `${markerName} (${projectName})`;

export const getHdbBlockLabel = (data) => `${data.block} ${
  data.building !== 'NIL' && data.building !== null && data.building !== undefined
    ? data.building
    : (data.street ? data.street : '')
  }`;

export const getCompatNameAsId = (name) => name.replace(/[^a-zA-Z0-9]/g, '').replace(/\s/g, '_');

export const getProjectKey = (prop) => `${prop.marker}_${prop.projectId}`;

export const getProjectKeyByTarget = (target) => `${target.id}_${target.projectId}`;

export const decompressBasicMapData = (txt) => {
  // parse text data
  const landmarks = txt.split('\n').map(l => l.split('|'));

  // data format
  const data = {
    projects: [],
    projectIndex: {},
    markers: [],
    schools: [],
    stations: [],
    upcomings: [],
    adv: 'b'  // backup advance data - this should not be used once migrated over to new L2 map data cache
  };

  // handle all landmarks in data
  landmarks.forEach(l => {
    const type = l[0];
    if (type === '0') {
      // handle property
      const markerName = expandId(l[1]);
      const projectName = expandId(l[14], markerName);
      const projectId = l[15];
      const prop = {
        marker: markerName,
        lat: parseFloat(l[2]),
        lng: parseFloat(l[3]),
        district: l[4],
        sector: l[5],
        areas: l[6].split('&').map(v => getArea(v)),
        marketSegment: MARKET_SEGMENT_ID_MAP[MARKET_SEGMENTS[parseInt(l[7])].label],
        types: convertIntToPropertyType(parseInt(l[8])),
        completion: l[9],
        profitable: (l[10] || l[10] === '0') && l[10] !== '-1' ? parseFloat(l[10]) : null,
        totalTx: l[11] ? parseInt(l[11]) : null,
        perc6ProfitTx: l[12] ? parseInt(l[12]) : null,
        tenures: convertIntToTenureType(l[13]),
        project: projectName,
        projectId,
        lastTxDate: l[16] ? dateStrToMsec(l[16]) : null,
        score: l[17] ? parseFloat(l[17]) : null,
        sharePlaceId: l[18] === '1' ? getPropertyFileName(`${markerName}_${projectId}`) : null,
        label: getProjectLabel(markerName, projectName),
        type: LOCATION_PROPERTY,
        mapMode: MAP_MODE_CONDO
      };
      const projectKey = getProjectKey(prop);
      data.projectIndex[projectKey] = data.projects.length;
      data.projects.push(prop);
    } else if (type === '1') {
      // handle school
      const names = l[1].split(',').map(v => expandId(v));
      const postal = l[5];
      const sch = {
        id: postal,
        label: names.join(', '),
        names: names,
        types: l[2].split(',').map(v => getSchoolType(v)),
        lat: parseFloat(l[3]),
        lng: parseFloat(l[4]),
        postal,
        type: LOCATION_SCHOOL
      };
      data.schools.push(sch);
    } else if (type === '2') {
      // handle station
      const stnName = expandId(l[1]);
      const exit = getStationExit(stnName);
      const stn = {
        id: getCompatNameAsId(stnName),
        name: stnName,
        subtype: getStationType(l[2].split(',')[0]),
        lat: parseFloat(l[3]),
        lng: parseFloat(l[4]),
        exit: exit ? `EXIT ${exit}` : null,
        type: LOCATION_STATION
      };
      data.stations.push(stn);
    } else if (type === '3') {
      // handle upcoming
      const projectName = expandId(l[1]);
      const streets = expandId(l[2]);
      const prop = {
        project: projectName,
        street: streets,
        district: l[3],
        types: l[4].split(',').filter(v => !!v).map(v => upcomingPropTypeMap[v]),
        lat: parseFloat(l[5]),
        lng: parseFloat(l[6]),
        totalUnits: parseInt(l[7]),
        id: l[8],
        type: LOCATION_UPCOMING
      };
      data.upcomings.push(prop);
    } else if (type === '9') {
      if (l[1]) {
        data.adv = l[1];
      }
    }
    // ignore unknown types for forward compatibility
  });

  // handle markers
  data.markers = groupPropsByMarker(data.projects);

  return data;
};

export const decompressAdvMapData = (txt) => txt.split('\n')
  .map(l => l.split('|'))
  .map(l => {
    const markerName = expandId(l[13]);
    const projectName = expandId(l[14], markerName);
    return {
      minPrice: l[0] ? parseFloat(l[0]) : null,
      maxPrice: l[1] ? parseFloat(l[1]) : null,
      minSize: l[2] ? parseFloat(l[2]) : null,
      maxSize: l[3] ? parseFloat(l[3]) : null,
      hdbBuyers: l[4] ? parseFloat(l[4]) : null,
      totalUnits: l[5] ? parseInt(l[5]) : null,
      soldAtLaunch: l[6] ? parseFloat(l[6]) : null,
      last6mAvgRentPsf: l[7] ? parseFloat(l[7]) : null,
      minRent: l[8] ? parseFloat(l[8]) : null,
      maxRent: l[9] ? parseFloat(l[9]) : null,
      tenureDate: l[10],
      last3mAvgPsf: parseFloat(l[11]),
      fairPsf: parseFloat(l[12]),
      marker: markerName,
      project: projectName,
      projectId: l[15]
    };
  });

export const groupMarkerByArea = (markers, mode) => {
  if (mode === MAP_MODE_HDB) {
    // handle hdb data
    return HDB_TOWNS
      .map(t => ({
        id: t.name,
        name: t.name,
        lat: t.lat,
        lng: t.lng,
        projects: markers.filter(p => p.town === t.name)?.sort((a, b) => b.profitable - a.profitable),
      }))
      .filter(a => a.projects.length > 0)
      .map(a => {
        const props = a.projects.filter(p => p.profitable !== null && p.profitable >= 0);
        return {
          ...a,
          mapMode: MAP_MODE_HDB,
          profitable: props.length > 0 ? props.reduce((s, x) => s + x.profitable, 0) / props.length : null,
          totalTx: a.projects.reduce((s, x) => s + (x.totalTx ?? 0), 0)
        };
      });
  }

  // handle private condo
  return POSTAL_DISTRICTS
    .map(a => {
      const district = a.id.toString();
      return {
        id: a.id,
        name: a.name,
        lat: a.coordinates.latitude,
        lng: a.coordinates.longitude,
        projects: markers.filter(p => p.district === district)?.sort((a, b) => b.profitable - a.profitable),
      };
    })
    .filter(a => a.projects.length > 0)
    .map(a => {
      const props = a.projects.filter(p => p.profitable !== null && p.profitable >= 0);
      return {
        ...a,
        mapMode: MAP_MODE_CONDO,
        profitable: props.length > 0 ? props.reduce((s, x) => s + x.profitable, 0) / props.length : null,
        totalTx: a.projects.reduce((s, x) => s + (x.totalTx ?? 0), 0)
      };
    });
};

const getGalleryLinks = (txt) => {
  if (txt === undefined || txt === null || txt === '-1') {
    return {
      gallery: [],
      placeIds: [],
      sharePlaceId: null
    };
  }

  const placeIds = [];
  let sharePlaceId = null;
  const gallery = txt.split(',')
    .filter(l => !!l)
    .map(l => {
      const c = l.split(':');
      if (c.length !== 2) return null;
      const placeFolderName = c[0];
      const lastIdx = parseInt(c[1]);
      placeIds.push(placeFolderName);
      if (!sharePlaceId) sharePlaceId = placeFolderName;
      return Array.from(Array(lastIdx + 1).keys()).map(i => ({
        url: `https://realsmart.global.ssl.fastly.net/i/${placeFolderName}/${i}.jpg`,
        fullsize: `https://realsmart.global.ssl.fastly.net/i/${placeFolderName}/${i + 10000}.jpg`
      }))
    })
    .filter(d => !!d)
    .flatMap(d => d);
  
  return {
    gallery,
    placeIds,
    sharePlaceId
  };
};

const decompressPAI = (v) => {
  if (v === '1') return 'HDB';
  if (v === '2') return 'Private';
  return v;
};

const decompressAreaType = (v) => {
  if (v === '1') return 'Strata';
  if (v === '2') return 'Land';
  return v;
};

const isPositiveFloat = (txt) => txt && txt !== '-1' && txt !== '-1.0';

export const decompressBasicPropertyData = (txt) => {
  const l = txt.split('|');
  const projectName = expandId(l[0]);
  const projectId = l[37];

  return {
    name: projectName,
    minSize: parseFloat(l[1]),
    maxSize: parseFloat(l[2]),
    district: l[3],
    sector: l[4],
    region: l[5],
    area: REGIONS[parseInt(l[6].split(',')[0])]['areas'][parseInt(l[6].split(',')[1])],
    marketSegment: MARKET_SEGMENT_ID_MAP[MARKET_SEGMENTS[parseInt(l[7])].label],
    totalTx: l[8] ? parseInt(l[8]) : null,
    hdbBuyers: l[9] ? parseFloat(l[9]) : null,
    totalTxAmt: l[10],
    lastTxDate: l[11],
    completion: l[12],
    tenureDate: l[13],
    totalUnits: l[14] ? parseInt(l[14]) : null,
    unsoldUnits: l[15] ? parseInt(l[15]) : null,
    soldAtLaunch: l[16] ? parseFloat(l[16]) : null,
    totalProfits: l[17] ? parseFloat(l[17]) : null,
    profitTx: l[18] ? parseInt(l[18]) : null,
    unprofitTx: l[19] ? parseInt(l[19]) : null,
    abortedTx: l[20] ? parseInt(l[20]) : null,
    perc6ProfitTx: l[21] ? parseInt(l[21]) : null,
    profitable: l[22] ? parseFloat(l[22]) : null,
    last6mAvgRentPsf: isPositiveFloat(l[23]) ? parseFloat(l[23]) : null,
    last6mTotalRent: isPositiveFloat(l[24]) ? parseInt(l[24]) : null,
    last1mAvgRentPsf: isPositiveFloat(l[25]) ? parseFloat(l[25]) : null,
    last1mTotalRent: isPositiveFloat(l[26]) ? parseInt(l[26]) : null,
    lat: parseFloat(l[27]),
    lng: parseFloat(l[28]),
    types: convertIntToPropertyType(parseInt(l[29])),
    streets: expandId(l[30]).split(','),
    tenures: convertIntToTenureType(l[31]),
    tenureYears: l[32] ? l[32].split(',') : [],
    developer: l[33],
    score: l[34] ? parseFloat(l[34]) : null,
    last1yReturns: l[35] ? parseFloat(l[35]) : null,
    last1yProfitTx: l[36] ? parseInt(l[36]) : null,
    projectId,
    ...getGalleryLinks(l[38]),
    marker: expandId(l[39])
  };
};

const decompressStreet = (s, addr) => {
  for (let i = 0; i < Object.keys(s).length; i++) {
    const key = Object.keys(s)[i];
    addr = addr.replace(key, s[key]);
  }
  return addr;
};

export const decompressPrivateNearbyTxData = (s, txt) => {
  const props = txt.split('|');
  const nearbyTxs = [];
  props.forEach(p => {
    const [prop, ...txs] = p.split('\\');
    const projectLines = prop.split(',');
    const project = {
      name: expandId(projectLines[0]),
      marker: expandId(projectLines[1]),
      projectId: projectLines[2]
    };
    txs.forEach(r => {
      const t = r.split(',');
      nearbyTxs.push({
        ...project,
        saleDate: t[0],
        address: expandId(decompressStreet(s, t[1])),
        type: convertIntToSaleType(parseInt(t[2])),
        price: parseFloat(t[3]),
        unitPrice: parseFloat(t[3]) / parseFloat(t[4]),
        area: parseFloat(t[4]),
        areaType: decompressAreaType(t[5]),
        purchaserAddrIndicator: decompressPAI(t[6]),
        postal: t[7],
        propertyType: getPropertyTypeSimplified(convertIntToPropertyType(parseInt(t[8]))),
        floor: getFloorFromAddress(expandId(decompressStreet(s, t[1]))),
      });
    })
  });
  return nearbyTxs;
};

export const decompressAdvPropertyData = (streets, txt) => {
  const l = txt.split('\n');
  const s = {};
  streets.forEach((st, i) => s[`%${i}%`] = st);
  return {
    fairPsf: expandPsf(l[0]),
    transactions: l[1] ? l[1].split('|').map(t => t.split(',')).map(t => ({
      saleDate: t[0],
      address: expandId(decompressStreet(s, t[1])),
      type: convertIntToSaleType(parseInt(t[2])),
      price: parseFloat(t[3]),
      unitPrice: parseFloat(t[4]),
      area: parseFloat(t[5]),
      areaType: decompressAreaType(t[6]),
      purchaserAddrIndicator: decompressPAI(t[7]),
      postal: t[8],
      propertyType: convertIntToPropertyType(parseInt(t[9])),
      floor: getFloorFromAddress(expandId(decompressStreet(s, t[1])))
    })) : [],
    profits: l[2] ? l[2].split('|').map(t => t.split(',')).map(t => ({
      saleDate: t[0],
      address: expandId(decompressStreet(s, t[1])),
      type: convertIntToSaleType(parseInt(t[2])),
      price: parseFloat(t[3]),
      unitPrice: parseFloat(t[4]),
      area: parseFloat(t[5]),
      areaType: decompressAreaType(t[6]),
      purchaserAddrIndicator: decompressPAI(t[7]),
      postal: t[8],
      propertyType: convertIntToPropertyType(parseInt(t[9])),
      profitPerc: parseFloat(t[10]),
      profit: parseFloat(t[11]),
      prevTxYears: t[12]
    })) : [],
    rentals: l[3] ? l[3].split('|').map(t => t.split(',')).map(t => ({
      leaseDate: t[0],
      address: expandId(decompressStreet(s, t[1])),
      propertyType: convertIntToPropertyType(parseInt(t[2])),
      bedrooms: t[3] ? parseInt(t[3]) : null,
      monthlyRent: parseFloat(t[4]),
      area: t[5],
      minArea: parseFloat(t[6]),
      maxArea: parseFloat(t[7]),
      estPsf: parseFloat(t[8])
    })) : [],
    comps: l[4] ? l[4].split('|').map(t => t.split(',')).map(t => ({
      marker: expandId(t[0]),
      project: expandId(t[1]),
      projectId: expandId(t[2]),
      distance: parseFloat(t[3]),
      profitable: t[4] ? parseFloat(t[4]) : null,
      last3mAvgPsf: t[5] ? parseFloat(t[5]) : null,
      types: convertIntToPropertyType(parseInt(t[6])),
      hdbBuyers: t[7] ? parseFloat(t[7]) : null,
      completion: t[8],
      tenures: convertIntToTenureType(t[9]),
      totalUnits: t[10] ? parseInt(t[10]) : null,
      soldAtLaunch: t[11] ? parseFloat(t[11]) : null,
    })) : [],
    blocks: l[5] ? l[5].split('|').map(t => t.split(',')).map(t => {
      return {
        address: expandId(decompressStreet(s, t[0])),
        block: t[1],
        postal: t[2],
        lat: parseFloat(t[3]),
        lng: parseFloat(t[4]),
        type: LOCATION_BLOCK
      };
    }) : [],
    schools: l[6] ? l[6].split('|').map(l => {
      const t = l.split(',');
      return {
        name: t[1],
        postal: t[2],
        subtype: t[3],
        lat: parseFloat(t[4]),
        lng: parseFloat(t[5]),
        blocks: t[6] ? t[6].split('+') : null,
        type: LOCATION_SCHOOL
      };
    }) : [],
    stations: l[7] ? l[7].split('|').map(t => t.split(',')).map(t => ({
      name: expandId(t[0]),
      lat: parseFloat(t[1]),
      lng: parseFloat(t[2]),
      type: LOCATION_STATION
    })) : [],
    nearbyTxs: l[8] ? decompressPrivateNearbyTxData(s, l[8]) : [],
  };
};

export const decompressUpcomingData = (txt) => {
  if (txt.includes('NoSuchKey')) return null;
  const l = txt.split('|');
  return {
    developer: l[0] && l[0].length === 1 ? txt.replaceAll('|', '') : l[0]
  };
};

export const decompressAmenityData = (type, txt) => txt.split('|')
  .map(line => {
    const l = line.split(',');
    const markerName = expandId(l[0]);
    const projectName = expandId(l[1], markerName);
    if (type === LOCATION_STATION) {
      return {
        marker: markerName,
        project: projectName,
        label: getProjectLabel(markerName, projectName),
        projectId: l[2],
        postal: l[3],
        address: expandId(l[4]),
        lat: parseFloat(l[5]),
        lng: parseFloat(l[6])
      }
    } else if (type === LOCATION_SCHOOL) {
      return {
        marker: markerName,
        project: projectName,
        label: getProjectLabel(markerName, projectName),
        projectId: l[2],
        postal: l[3],
        address: expandId(l[4]),
        lat: parseFloat(l[5]),
        lng: parseFloat(l[6]),
        distance: parseInt(l[7])
      };
    }
    return {};
  });

export const decompressHdbAmenityData = (type, txt) => txt.split('|')
  .map(line => {
    const l = line.split(',');
    if (type === LOCATION_STATION || type === LOCATION_SCHOOL) {
      const block = l[1];
      const street = expandId(l[3]);
      return {
        name: `${block} ${street}`,
        building: expandId(l[0]),
        block,
        postal: l[2],
        street,
        lat: parseFloat(l[4]),
        lng: parseFloat(l[5])
      }
    }
    return {};
  });

const decompressProjectCompTx = (txt, mode) => txt.split('|').map(l => {
  const v = l.split(',');
  if (mode === MAP_MODE_HDB) {
    return {
      saleDate: dateStrToDateObj(v[0]),
      unitPrice: v[1] ? parseFloat(v[1]) : null
    };
  }
  return {
    saleDate: dateStrToDateObj(v[0]),
    saleType: v[1],
    unitPrice: v[2] ? parseFloat(v[2]) : null
  };
});

const decompressProjectCompProfit = (txt, mode) => txt.split('|').map(l => {
  const v = l.split(',');
  if (mode === MAP_MODE_HDB) {
    return {
      saleDate: dateStrToDateObj(v[0]),
      profitPerc: v[1] ? parseFloat(v[1]) : null
    };
  }
  return {
    saleDate: dateStrToDateObj(v[0]),
    saleType: v[1],
    profitPerc: v[2] ? parseFloat(v[2]) : null
  };
});

const decompressProjectCompRecent = (txt, mode) => txt === '' ? [] : txt.split('|').map(l => {
  const t = l.split(',');
  if (mode === MAP_MODE_HDB) {
    return {
      flatType: HDB_TYPES_INTS[parseInt(t[0]) - 1],
      flatTypeRaw: parseInt(t[0]),
      storeyRange: decompressHdbStoreyRange(t[1]),
      storeyMin: parseInt(decompressHdbStoreyRange(t[1]).split(' TO ')[0]),
      area: sqmToSft(parseFloat(t[2])),
      flatModel: t[3],
      price: parseFloat(t[4]),
      saleDate: t[5],
      unitPrice: parseFloat(t[4]) / sqmToSft(parseFloat(t[2]))
    };
  }

  return {
    saleDate: t[0],
    address: expandId(t[1]),
    type: convertIntToSaleType(parseInt(t[2])),
    price: parseFloat(t[3]),
    unitPrice: parseFloat(t[4]),
    area: parseFloat(t[5]),
    areaType: decompressAreaType(t[6]),
    purchaserAddrIndicator: decompressPAI(t[7]),
    postal: t[8],
    propertyType: convertIntToPropertyType(parseInt(t[9]))
  };
});

export const decompressSingleCompData = (json) => ({
  t: decompressProjectCompTx(json.t),
  p: decompressProjectCompProfit(json.p),
  d: decompressBasicPropertyData(json.d),
  s: decompressProjectCompRecent(json.s)
});

export const decompressHdbSingleCompData = (json) => ({
  t: decompressProjectCompTx(json.t, MAP_MODE_HDB),
  p: decompressProjectCompProfit(json.p, MAP_MODE_HDB),
  d: decompressHdbL1Data(json.d),
  s: decompressProjectCompRecent(json.s, MAP_MODE_HDB)
});

export const decompressGroupCompData = (json) => {
  // handle transactions data
  const txData = {};
  Object.keys(json.t).forEach(projKey => {
    // for now just skip those with comma in key
    if (projKey.includes(',')) return;
    txData[projKey] = decompressProjectCompTx(json.t[projKey]);
  });

  // handle profit data
  const profitData = {};
  Object.keys(json.p).forEach(projKey => {
    // for now just skip those with comma in key
    if (projKey.includes(',')) return;
    profitData[projKey] = decompressProjectCompProfit(json.p[projKey]);
  });

  // handle projects detail
  const detailData = {};
  Object.keys(json.d).forEach(projKey => {
    // for now just skip those with comma in key
    if (projKey.includes(',')) return;
    detailData[projKey] = decompressBasicPropertyData(json.d[projKey]);
  });

  // handle recent transactions
  const recentData = {};
  Object.keys(json.s).forEach(projKey => {
    // for now just skip those with comma in key
    if (projKey.includes(',')) return;
    recentData[projKey] = decompressProjectCompRecent(json.s[projKey]);
  });

  return {
    t: txData,
    p: profitData,
    d: detailData,
    s: recentData
  };
};

export const decompressHdbGroupCompData = (json) => {
  // handle transactions data
  const txData = {};
  Object.keys(json.t).forEach(projKey => {
    // for now just skip those with comma in key
    if (projKey.includes(',')) return;
    txData[projKey] = decompressProjectCompTx(json.t[projKey], MAP_MODE_HDB);
  });

  // handle profit data
  const profitData = {};
  Object.keys(json.p).forEach(projKey => {
    // for now just skip those with comma in key
    if (projKey.includes(',')) return;
    profitData[projKey] = decompressProjectCompProfit(json.p[projKey], MAP_MODE_HDB);
  });

  // handle projects detail
  const detailData = {};
  Object.keys(json.d).forEach(projKey => {
    // for now just skip those with comma in key
    if (projKey.includes(',')) return;
    detailData[projKey] = decompressHdbL1Data(json.d[projKey]);
  });

  // handle recent transactions
  const recentData = {};
  Object.keys(json.s).forEach(projKey => {
    // for now just skip those with comma in key
    if (projKey.includes(',')) return;
    recentData[projKey] = decompressProjectCompRecent(json.s[projKey], MAP_MODE_HDB);
  });

  return {
    t: txData,
    p: profitData,
    d: detailData,
    s: recentData
  };
};

export const decompressGroupCompTxData = (txt) => txt.split('|')
  .map(line => {
    const l = line.split(',');
    return {
      saleDate: l[0],
      saleType: l[1],
      unitPrice: l[2] ? parseFloat(l[2]) : null
    };
  });

export const decompressGroupCompProfitData = (txt) => txt.split('|')
  .map(line => {
    const l = line.split(',');
    return {
      saleDate: l[0],
      saleType: l[1],
      profitPerc: l[2] ? parseFloat(l[2]) : null
    };
  });

export const OVERLAY_TYPES_MAP = {
  "0": "OPEN SPACE",
  "1": "PLACE OF WORSHIP",
  "2": "COMMERCIAL",
  "3": "BUSINESS 2",
  "4": "UTILITY",
  "5": "SPORTS & RECREATION",
  "6": "RESERVE SITE",
  "7": "SPECIAL USE",
  "8": "RESIDENTIAL",
  "9": "TRANSPORT FACILITIES",
  "10": "COMMERCIAL & RESIDENTIAL",
  "11": "CIVIC & COMMUNITY INSTITUTION",
  "12": "EDUCATIONAL INSTITUTION",
  "13": "PARK",
  "14": "HEALTH & MEDICAL CARE",
  "15": "RESIDENTIAL WITH COMMERCIAL AT 1ST STOREY",
  "16": "BUSINESS 1",
  "17": "MASS RAPID TRANSIT (MRT)",
  "18": "LIGHT RAPID TRANSIT (LRT)",
  "19": "CEMETERY",
  "20": "AGRICULTURE",
  "21": "HOTEL",
  "22": "BUSINESS PARK",
  "23": "WHITE",
  "24": "PORT / AIRPORT",
  "25": "BUSINESS 2 - WHITE",
  "26": "BUSINESS 1 - WHITE",
  "27": "RESIDENTIAL / INSTITUTION",
  "28": "COMMERCIAL / INSTITUTION",
  "29": "BUSINESS PARK - WHITE"
};

export const OVERLAY_TYPES_HINT = {
  "1": "For religious buildings",
  "2": "For commercial development such as office, bank and even mixed uses",
  "3": "For clean industry, light industry, general industry, warehouse, public utilities, telecom and other public installations",
  "4": "For public utilities, telecom infrastructure, e.g. water works, sewage disposal, electrical substations",
  "5": "For sports & recreation purposes such as sports complex, swimming complex, golf course, chalet",
  "6": "Specific use yet to be determined",
  "7": "For special purposes",
  "8": "For residential development",
  "9": "For parking of vehicles, garages and at-grade structure of underground road tunnel and rapid transit system",
  "10": "For mixed residential and commercial development",
  "11": "For civic, community or cultural facilities or other similar purposes",
  "12": "For educational purposes including tertiary education",
  "13": "For parks or gardens for the enjoyment of the general public and includes pedestrian linkages",
  "14": "For medical services such as hospital, polyclinic, dental clinic, veterinary clinic, nursing home",
  "15": "For residential development with commercial use at the 1st storey only",
  "16": "For clean industry, light industry, warehouse, public utilities, telecom and other public installations (does not impose a nuisance buffer greater than 50m)",
  "20": "For agricultural purposes and includes plant nursery",
  "21": "For hotel development",
  "22": "For business park operation",
  "23": "Commercial, hotel, residential, sports & recreation, and 2 or more such uses as mixed development",
  "24": "For airport/airfield or dock/port purposes",
  "25": "For uses permissible under B2 zone and White zone as a mixed use development",
  "26": "For uses permissible under B1 zone and White zone as a mixed use development",
  "27": "For residential purpose, community institution facilities or other similar purposes",
  "28": "For commercial purpose, community institution facilities or other similar purposes",
  "29": "For business park operations and uses permissible under White zone as a mixed use development",
};

export const OVERLAY_GROUPS = [
  {
    label: "OPEN SPACE",
    ids: ["0"]
  },
  {
    label: "PLACE OF WORSHIP",
    ids: ["1"]
  },
  {
    label: "BUSINESS / COMMERCIAL",
    ids: ["2","3","16","22","23","25","26","28","29"]
  },
  {
    label: "UTILITY",
    ids: ["4"]
  },
  {
    label: "SPORTS & RECREATION",
    ids: ["5"]
  },
  {
    label: "RESERVE SITE",
    ids: ["6"]
  },
  {
    label: "SPECIAL USE",
    ids: ["7"]
  },
  {
    label: "RESIDENTIAL",
    ids: ["8","10","15","27"]
  },
  {
    label: "TRANSPORT FACILITIES",
    ids: ["9"]
  },
  {
    label: "CIVIC & COMMUNITY",
    ids: ["11"]
  },
  {
    label: "EDUCATIONAL",
    ids: ["12"]
  },
  {
    label: "PARK",
    ids: ["13"]
  },
  {
    label: "HEALTH & MEDICAL CARE",
    ids: ["14"]
  },
  {
    label: "MRT / LRT",
    ids: ["17","18"]
  },
  {
    label: "CEMETERY",
    ids: ["19"]
  },
  {
    label: "AGRICULTURE",
    ids: ["20"]
  },
  {
    label: "HOTEL",
    ids: ["21"]
  },
  {
    label: "PORT / AIRPORT",
    ids: ["24"]
  },
];

export const getOverlayFill = () => [
  'match', 
  ['get', 'id'], 
  "0", "#1abc9c",
  "1", "#34dbca",
  "2", "#4a59df",
  "3", "#7581e7",
  "4", "#d35400",
  "5", "#16a085",
  "6", "#95a5a6",
  "7", "#7f8c8d",
  "8", "#3498db",
  "9", "#e67e22",
  "10", "#1d6fa5",
  "11", "#2980b9",
  "12", "#9b59b6",
  "13", "#2ecc71",
  "14", "#8e44ad",
  "15", "#217dbb",
  "16", "#5f6de3",
  "17", "#f1c40f",
  "18", "#f39c12",
  "19", "#bdc3c7",
  "20", "#27ae60",
  "21", "#e74c3c",
  "22", "#8dd125",
  "23", "#3445db",
  "24", "#c0392b",
  "25", "#2536d1",
  "26", "#2131bb",
  "27", "#258cd1",
  "28", "#1d2ba5",
  "29", "#7ebb21",
  '#808080',
];

export const getOverlaysOpacity = (hiddenOverlayIds, overlayOpacity) => {
  if (hiddenOverlayIds.length === 0) return overlayOpacity;
  return [
    'case',
    [
      'any',
      ...hiddenOverlayIds.map(id => ['==', ['get', 'id'], id])
    ],
    0,
    overlayOpacity
  ];
};

export const isFlatType = (t) => t === 'Condominium' || t === 'Apartment' || t === 'Executive Condominium' || t === 'Non-landed Properties';
const isEC = (t) => t === 'Executive Condominium';
const isHDB = (t) => t === 'HDB';

export const isHdbType = (arr) => arr.length === 1 && isHDB(arr[0]);

export const getPropertyIconByType = (arr) => {
  if (isHdbType(arr)) {
    return 'hdb';
  }

  const flatTypes = arr.reduce((s, t) => isFlatType(t) ? (s + 1) : s, 0);

  if (flatTypes === arr.length) {
    const hasEC = arr.some(isEC);
    if (hasEC) {
      return 'condo';
    }
    return 'flat';
  }
  if (flatTypes === 0) {
    return 'house';
  }
  return 'mixed';
};

export const getPropertyTypeSimplified = (arr) => {
  const icon = getPropertyIconByType(arr);
  if (icon === 'mixed') return ['Mixed'];
  if (icon === 'hdb') return ['HDB'];
  if (icon === 'house') return ['Landed'];
  if (icon === 'condo') return ['Executive Condominium'];
  return arr;
};

export const getMarkerScaleByTx = (totalTx, minScale, maxScale, minTx, maxTx) => {
  const tx = Math.max(minTx, Math.min(totalTx, maxTx));
  const normalized = (Math.sqrt(tx) - Math.sqrt(minTx)) / (Math.sqrt(maxTx) - Math.sqrt(minTx));
  return minScale + normalized * (maxScale - minScale);
};

export const MAP_MODE_CONDO = 'c';
export const MAP_MODE_LANDED = 'l';
export const MAP_MODE_HDB = 'h';
export const MAP_MODES = [
  { id: MAP_MODE_CONDO, label: 'Private', hint: 'View all private properties' },
  // { id: MAP_MODE_CONDO, label: 'Condo', hint: 'View all private apartments and condominiums' },
  // { id: MAP_MODE_LANDED, label: 'Landed', hint: 'View all landed properties' },
  { id: MAP_MODE_HDB, label: 'HDB', hint: 'View all HDB estates' },
];


export const HDB_TYPES_INTS = [
  '1 ROOM',
  '2 ROOM',
  '3 ROOM',
  '4 ROOM',
  '5 ROOM',
  'EXECUTIVE',
  'MULTI-GENERATION'
]

const decompressHdbFlatTypes = (value) => { 
  const types = [];
  if (value === '') {
    return types;
  }
  const val = parseInt(value);
  HDB_TYPES_INTS.forEach((type, i) => {
    if (val & (1 << i)) {
      types.push(type);
    }
  });
  return types;
};

const decompressHdbStoreyRange = (value) => value.split('-').join(' TO ');

export const decompressHdbBasicMapData = (txt) => {
  const groups = txt.split('\n');
  const hdbData = [];
  const index = {};
  const lastIndex = groups.length - 1;
  let advFile = 'h2';
  groups.forEach((group, i) => {
    if (i === lastIndex && group.startsWith('h')) {
      // handle last row which is the link to adv map file
      advFile = group;
    } else {
      const lines = group.split('|');
      const chunks = lines[0].split(',');
      const building = expandId(chunks[0]);
      const street = expandId(chunks[1]);
      for (let i = 1; i < lines.length; i++) {
        const l = lines[i].split(',');
        const block = l[1];
        const postal = l[2];
        const blockStreet = l[4] !== '' ? expandId(l[4]) : street;
        index[postal] = hdbData.length;
        hdbData.push({
          name: `${block} ${blockStreet}`,
          building,
          town: expandId(l[0]),
          block,
          postal,
          flatTypes: decompressHdbFlatTypes(l[3]),
          street: blockStreet,
          lat: parseFloat(l[5]),
          lng: parseFloat(l[6]),
          profitable: l[7] ? parseFloat(l[7]) * 100 : null,
          totalTx: parseInt(l[8]),
          lastTxDate: l[9],
          sharePlaceId: l[10] === '1' ? postal : null,
          mapMode: MAP_MODE_HDB
        });
      }
    }
  });
  return {
    index,
    blocks: hdbData,
    adv: advFile
  };
};

export const decompressHdbAdvMapData = (txt) => {
  const groups = txt.split('\n');
  const hdbData = [];
  groups.forEach(group => {
    const lines = group.split('|');
    for (let i = 0; i < lines.length; i++) {
      const l = lines[i].split(',');
      hdbData.push({
        minSize: l[0] ? sqmToSft(parseFloat(l[0])) : null,
        maxSize: l[1] ? sqmToSft(parseFloat(l[1])) : null,
        tenureDate: l[2],
        maxFloor: l[3] ? parseInt(l[3]) : null,
        completion: l[4],
        residential: l[5].toLowerCase() === 'y',
        commercial: l[6].toLowerCase() === 'y',
        marketHawker: l[7].toLowerCase() === 'y',
        miscellaneous: l[8].toLowerCase() === 'y',
        multistoreyCarpark: l[9].toLowerCase() === 'y',
        precinctPavilion: l[10].toLowerCase() === 'y',
        totalUnits: l[11] ? parseInt(l[11]) : null,
        postal: l[12]
      });
    }
  });
  return hdbData;
};

const sqmToSft = (val) => parseInt(val * 10.7639);

export const decompressHdbL1Data = (txt) => {
  const l = txt.split('|');
  return {
    building: expandId(l[0]),
    town: expandId(l[1]),
    block: l[2],
    postal: l[3],
    flatTypes: decompressHdbFlatTypes(l[4]),
    street: expandId(l[5]),
    lat: parseFloat(l[6]),
    lng: parseFloat(l[7]),
    profitable: l[8] ? parseFloat(l[8]) * 100 : null,
    totalTx: parseInt(l[9]),
    lastTxDate: l[10],
    minSize: l[11] ? sqmToSft(parseFloat(l[11])) : null,
    maxSize: l[12] ? sqmToSft(parseFloat(l[12])) : null,
    tenureDate: l[13],
    maxFloor: l[14] ? parseInt(l[14]) : null,
    completion: l[15],
    residential: l[16].toLowerCase() === 'y',
    commercial: l[17].toLowerCase() === 'y',
    marketHawker: l[18].toLowerCase() === 'y',
    miscellaneous: l[19].toLowerCase() === 'y',
    multistoreyCarpark: l[20].toLowerCase() === 'y',
    precinctPavilion: l[21].toLowerCase() === 'y',
    totalUnits: l[22] ? parseInt(l[22]) : null,
    profitTx: l[23] ? parseInt(l[23]) : null,
    unprofitTx: l[24] ? parseInt(l[24]) : null,
    last1yReturns: l[25] ? parseFloat(l[25]) : null,
    last1yProfitTx: l[26] ? parseInt(l[26]) : null,
    ...getGalleryLinks(l[27])
  };
};

export const decompressHdbNearbyTxData = (txt) => {
  const props = txt.split('|');
  const nearbyTxs = [];
  props.forEach(p => {
    const [prop, ...txs] = p.split('\\');
    const blockLines = prop.split(',');
    const block = {
      block: blockLines[0],
      street: expandId(blockLines[1]),
      postal: blockLines[2]
    };
    txs.map(r => {
      const t = r.split(',');
      nearbyTxs.push({
        ...block,
        flatType: HDB_TYPES_INTS[parseInt(t[0]) - 1],
        flatTypeRaw: parseInt(t[0]),
        storeyRange: decompressHdbStoreyRange(t[1]),
        storeyMin: parseInt(decompressHdbStoreyRange(t[1]).split(' TO ')[0]),
        area: sqmToSft(parseFloat(t[2])),
        flatModel: t[3],
        price: parseFloat(t[4]),
        saleDate: t[5],
        unitPrice: parseFloat(t[4]) / sqmToSft(parseFloat(t[2]))
      });
    });
  });
  return nearbyTxs;
};

export const decompressHdbL2Data = (txt) => {
  const l = txt.split('\n');
  return {
    transactions: l[0] ? l[0].split('|').map(t => t.split(',')).map(t => ({
      flatType: HDB_TYPES_INTS[parseInt(t[0]) - 1],
      flatTypeRaw: parseInt(t[0]),
      storeyRange: decompressHdbStoreyRange(t[1]),
      storeyMin: parseInt(decompressHdbStoreyRange(t[1]).split(' TO ')[0]),
      area: sqmToSft(parseFloat(t[2])),
      flatModel: t[3],
      price: parseFloat(t[4]),
      saleDate: t[5],
      unitPrice: parseFloat(t[4]) / sqmToSft(parseFloat(t[2]))
    })) : [],
    profits: l[1] ? l[1].split('|').map(t => t.split(',')).map(t => ({
      flatType: HDB_TYPES_INTS[parseInt(t[0]) - 1],
      flatTypeRaw: parseInt(t[0]),
      storeyRange: decompressHdbStoreyRange(t[1]),
      storeyMin: parseInt(decompressHdbStoreyRange(t[1]).split(' TO ')[0]),
      area: sqmToSft(parseFloat(t[2])),
      flatModel: t[3],
      price: parseFloat(t[4]),
      saleDate: t[5],
      profitPerc: parseFloat(t[6]),
      unitPrice: parseFloat(t[4]) / sqmToSft(parseFloat(t[2]))
    })) : [],
    rentals: [],
    schools: l[3] ? l[3].split('|').map(t => t.split(',')).map(t => ({
      name: expandId(t[0]),
      postal: t[1],
      lat: parseFloat(t[2]),
      lng: parseFloat(t[3])
    })) : [],
    stations: l[4] ? l[4].split('|').map(t => t.split(',')).map(t => ({
      name: expandId(t[0]),
      lat: parseFloat(t[1]),
      lng: parseFloat(t[2])
    })) : [],
    comps: l[5] ? l[5].split('|').map(t => t.split(',')).map(t => ({
      building: expandId(t[0]),
      block: t[1],
      postal: t[2],
      street: expandId(t[3]),
      distance: parseFloat(t[4]),
      flatTypes: decompressHdbFlatTypes(t[5]),
      profitable: t[6] ? parseFloat(t[6]) * 100 : null,
      completion: t[7],
      totalUnits: t[8] ? parseInt(t[8]) : null,
      name: `${t[1]} ${expandId(t[3])}`
    })) : [],
    nearbyTxs: l[6] ? decompressHdbNearbyTxData(l[6]) : [],
  };
};

export const simplifyHdbTypes = (types) => {
  const roomTypes = [];
  const others = [];
  types.forEach(t => {
    const c = t.split(' ROOM');
    if (c.length === 2) roomTypes.push(c[0]);
    else others.push(t);
  });
  const roomLabel = roomTypes.length > 0
    ? (roomTypes.sort((a, b) => a.localeCompare(b)).join('/') + ' ROOM')
    : null;
  return [roomLabel, ...others].filter(l => !!l).join(', ');
};

export const censorUnit = (unit) => {
  // replace the unit number of given unit string with 'X', but only keep the floor
  // const regex = /^#[a-zA-Z0-9]+-[a-zA-Z0-9]+$/;
  // if (regex.test(unit)) {
  //     const parts = unit.split('-');
  //     return `${parts[0]}-XX`;
  // }
  return unit;
};
