import { useState } from "react";

import {
  NOTIFICATION_OPTIONS,
  isLoggedIn
} from "@/utils/user";
import TabButtons from "../mapv2/property/TabButtons";
import PropNotifications from "./PropNotifications";
import Loader from "../common/Loader";


const NotificationPanel = ({
  user,
  session,
  onClick,
  onClose,
}) => {
  const [click, setClick] = useState(false);
  const [display, setDisplay] = useState(NOTIFICATION_OPTIONS[0].id);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const handleCarding = () => {
    if (!click) {
      onClick?.();
    } else {
      onClose?.();
      setDisplay(NOTIFICATION_OPTIONS[0].id);
    }
    setClick((prevState) => !prevState);
  };

  return (
    <>
      {/* <div className="p-2">
        <button
          className={`d-flex items-center text-20 notif-btn`}
          onClick={handleCarding}
        >
          <i className="icon-notification" />
          {data.length > 0
            && <span className="notif-badge">
              {data.length > 9 ? '∞' : data.length}
            </span>
          }
        </button>
      </div> */}

      <div className={`langMenu langMenuTop js-langMenu ${click ? "" : "is-hidden"}`}>
        <div className="currencyMenu__bg" onClick={handleCarding}></div>
        <div className="langMenu__content bg-white rounded-4 rel-content">
          <div className="d-flex items-center justify-between px-30 py-10 sm:px-15 border-bottom-light">
            <TabButtons
              tabs={NOTIFICATION_OPTIONS}
              selectedTab={display}
              setSelectedTab={setDisplay}
              session={session}
            />
            <button className="pointer" onClick={handleCarding}>
              <i className="icon-close" />
            </button>
          </div>

          {loading
            && <div className="loader-container-sm">
              <Loader />
            </div>
          }

          {!loading && isLoggedIn(user)
            && <div className="acct-bh py-10 px-10">
              {display === 'prop'
                && <PropNotifications
                  user={user}
                />
              }
            </div>
          }

        </div>

      </div>
    </>
  );
};

export default NotificationPanel;
