import React, { useEffect, useRef, useState } from 'react';
import LandingLoader from './LandingLoader';

// const ITEM_WIDTH = 238;

const ROW_PH = [
  <span>Make more profit in real estate<br />investments with data</span>,
  <span>Access almost 8000 projects<br />across Singapore</span>,
  <span>Find the nearest amenities<br />for each project</span>,
  <span>Dive into 800,000 transactions</span>,
  <span>Millions of rental transactions<br />at your fingertips</span>,
  <span>A quarter million profitability data<br />just a click away</span>,
];

const DEFAULT_ROW_ITEMS = ROW_PH.map(r => ({
  t: null,
  n: r,
}));

const Carousel = ({
  onClickItem,
}) => {
  const row1Ref = useRef(null);
  const row2Ref = useRef(null);
  const row3Ref = useRef(null);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const shuffle = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  const insertDefaults = (arr, idx) => {
    const newArrs = [];
    let ptr = 0;
    const defaults = [...DEFAULT_ROW_ITEMS];
    shuffle(defaults);
    for (let i = 0; i < arr.length; i++) {
      if (i % idx === 0) newArrs.push(defaults[ptr++ % defaults.length]);
      newArrs.push(arr[i]);
    }
    if (newArrs.length < 30) return [...newArrs, ...newArrs];
    return newArrs;
  };

  const loadData = () => {
    setLoading(true);
    fetch(
      'https://realsmart.global.ssl.fastly.net/land3.json'
    )
    .then(resp => {
      return resp.json();
    }).then(json => {
      json.t = insertDefaults(json.t, 3);
      json.p = insertDefaults(json.p, 2);
      json.r = insertDefaults(json.r, 3);
      setData(json);
      setLoading(false);
    }).catch(err => {
      setLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const scroll = (ref, speed) => {
    if (ref.current) {
      ref.current.scrollLeft += speed;
      if (speed > 0 && ref.current.scrollLeft >= ref.current.scrollWidth - ref.current.clientWidth) {
        ref.current.scrollLeft = 0;
      } else if (speed < 0 && ref.current.scrollLeft <= 0) {
        ref.current.scrollLeft = ref.current.scrollWidth - ref.current.clientWidth;
      }
    }
  };

  useEffect(() => {
    const interval1 = setInterval(() => scroll(row1Ref, -1), 22);
    const interval2 = setInterval(() => scroll(row2Ref, 1), 38);
    const interval3 = setInterval(() => scroll(row3Ref, -1), 28);
    return () => {
      clearInterval(interval1);
      clearInterval(interval2);
      clearInterval(interval3);
    };
  }, []);

  const ellipsize = (str) => str.slice(0, 28) + (str.length > 28 ? '…' : '');

  const now = new Date();

  const formatDaysAgo = (ts) => {
    const diff = now.getTime() - ts;
    const daysDiff = Math.round(diff / (24 * 3600000));
    if (daysDiff > 30) {
      const isLastMonth = (now.getMonth() - new Date(ts).getMonth()) % 12 === 1;
      const mth = Math.floor(daysDiff / 30);
      return isLastMonth ? 'last month' : `${mth.toFixed(0)} month${mth > 1 ? 's' : ''} ago`;
    }
    if (daysDiff > 12) {
      return `${Math.round(daysDiff / 7).toFixed(0)} weeks ago`;
    }
    return daysDiff > 0 ? `${daysDiff.toLocaleString()} day${daysDiff > 1 ? 's' : ''} ago` : ' yesterday';
  };

  const formatMoney = (value) => {
    if (value > 1000000) return `$${(value / 1000000).toFixed(2)}M`;
    if (value > 1000) return `$${(value / 1000).toFixed(1)}K`;
    return `$${value}`;
  };

  const generateText = (text) => {
    if (text.t === 'ns')
      return <span className="lh-15">
        <span className="text-blue-1 fw-500">{ellipsize(text.n)}</span>
        <br />{`${text.c ?? 1} new sale${text.c ? 's' : ''} ${formatDaysAgo(text.ts)} (${formatMoney(text.min)}${text.max ? ` - ${formatMoney(text.max)}` : ''})`}
      </span>;
    if (text.t === 'rs')
      return <span className="lh-15">
        <span className="text-blue-1 fw-500">{ellipsize(text.n)}</span>
        <br />{`${text.c ?? 1} resale${text.c ? 's' : ''} ${formatDaysAgo(text.ts)} (${formatMoney(text.min)}${text.max ? ` - ${formatMoney(text.max)}` : ''})`}
      </span>;
    if (text.t === 'ss')
      return <span className="lh-15">
        <span className="text-blue-1 fw-500">{ellipsize(text.n)}</span>
        <br />{`${text.c ?? 1} sub sale${text.c ? 's' : ''} ${formatDaysAgo(text.ts)} (${formatMoney(text.min)}${text.max ? ` - ${formatMoney(text.max)}` : ''})`}
      </span>;
    if (text.t === 'r')
      return <span className="lh-15">
        <span className="text-blue-1 fw-500">{ellipsize(text.n)}</span>
        <br />{`${text.c ?? 1} rental${text.c ? 's' : ''} ${formatDaysAgo(text.ts)} (${formatMoney(text.min)}${text.max ? ` - ${formatMoney(text.max)}` : ''} monthly rent)`}
      </span>;
    if (text.t === 'p')
      return <span className="lh-15">
        <span className="text-blue-1 fw-500">{ellipsize(text.n)}</span>
        <br />
        <span className={text.p > 0 ? 'text-green-2' : 'text-red-1'}>{text.p > 0 ? '+' : ''}{text.p}%</span>
        {` annualized profit ${formatDaysAgo(text.ts)}`}
      </span>;
    return ellipsize(text.n);
  };

  const renderItems = (items) => {
    return items.map((item, i) =>
      item.t !== null ? (
        <div
          key={i}
          className={`carousel-item lp-intro-card border-light lh-12 px-10 py-10 text-dark text-14 text-center${onClickItem && item.s ? ' cursor-pointer' : ''}`}
          onClick={() => onClickItem?.(item)}
        >
          {generateText(item)}
        </div>
      ) : (
        <div
          key={i}
          className={`carousel-item carousel-sp lp-intro-card border-light lh-12 px-10 py-10 text-dark fw-500 text-14 text-center${onClickItem && item.s ? ' cursor-pointer' : ''}`}
        >
          {item.n}
        </div>
      )
    );
  };

  return (
    <div className="carousel fade-intro mt-10">
      {!loading && data !== null
        && <>
          <div className="carousel-row" ref={row1Ref}>
            {renderItems(data.t)}
          </div>
          <div className="carousel-row" ref={row2Ref}>
            {renderItems(data.p)}
          </div>
          <div className="carousel-row" ref={row3Ref}>
            {renderItems(data.r)}
          </div>
        </>
      }
      {loading
        && <div className="pt-50">
          <LandingLoader />
        </div>
      }
    </div>
  );
};

export default Carousel;
