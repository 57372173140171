import { convertIntToPropertyType } from "@/utils/areas";
import { getPropertyIconByType } from "@/utils/map";

const LikedPropertyRow = ({
  idx,
  data,
  onView,
  onUnlike
}) => {
  const propTypes = convertIntToPropertyType(data.types);
  const iconType = !data.types ? 'hdb' : getPropertyIconByType(propTypes);

  const cleanUrlCode = (code) => code.replaceAll(/_/g, '').toUpperCase();

  const imgUrl = data.img
    ? `https://realsmart.global.ssl.fastly.net/ir/${cleanUrlCode(data.img)}.jpg`
    : `/img/general/thumbs/${iconType}.png`;

  const style = {
    borderRadius: (data.img ? '30%' : '0')
  };

  if (data.img) {
    style.objectFit = 'cover';
    style.aspectRatio = '1/1';
  }

  const isSmallScreen = window.innerWidth < 1000;

  return (
    <div
      key={idx}
      className={`${idx !== 0 ? 'border-top-light' : ''} px-0 py-5`}
    >
      <div className="px-0">
        <div className="d-flex px-0 align-items-center">
          <img
            className="p-2 noselect"
            src={imgUrl}
            height={60}
            width={60}
            style={style}
          />
          <div className="p-2 flex-grow-1">
            <div className="fw-600 text-dark text-16">
              {data.name}
            </div>
            <div className="text-12 text-light-1">
              <i className="icon-location-2 mr-5" />
              {
                !data.types
                  ? `${data.street} [${data.postal}]`
                  : data.street
              }
            </div>
          </div>
          <button
            className={`p-2 button ${isSmallScreen ? 'mr-5' : 'mr-10'} mb-5 -dark-1 bg-blue-1 text-white h-30 px-10 rounded-100 text-12 noselect`}
            onClick={() => onUnlike(data.id)}
          >
            <i className="icon-close px-5" />
            {!isSmallScreen
              && <span className="">
                Unlike
              </span>
            }
          </button>
          <button
            className="p-2 button mr-5 mb-5 -dark-1 bg-blue-1 text-white h-30 px-10 rounded-100 text-12 noselect"
            onClick={() => onView?.(data)}
          >
            {!isSmallScreen
              && <i className="icon-location-2 px-5" />
            }
            <span className="">
              View
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LikedPropertyRow;
