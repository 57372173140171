import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PropTypes from "prop-types";
import { useEffect } from "react";

const tabMap = {
  tos: 0,
  privacy: 1,
};

const TermsContent = ({ tab }) => {
  useEffect(() => {
    trackPageView('policy');
  }, []);

  return (
    <Tabs defaultIndex={tabMap[tab] ?? 0}>
      <div className="row y-gap-30">
        <div className="col-lg-3">
          <div className="px-30 py-30 rounded-4 border-light">
            <TabList className="tabs__controls row y-gap-10 js-tabs-controls">
              <Tab className="col-12 tabs__button js-tabs-button">
                Terms of Service
              </Tab>
              <Tab className="col-12 tabs__button js-tabs-button">
                Privacy policy
              </Tab>
            </TabList>
          </div>
        </div>
        {/* End .col-lg-3 */}

        <div className="col-lg-9">
          <TabPanel>
            <div className="tabs__content js-tabs-content" data-aos="fade">
              <h1 className="text-30 fw-600 mb-15">Terms of Service</h1>
              <h2 className="text-16 fw-500">
                1. Terms
              </h2>
              <p className="text-15 text-dark-1 mt-5">
              By accessing this Website, accessible from https://realsmart.sg, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trade mark law.
              </p>
              <h2 className="text-16 fw-500 mt-35">
                2. Use License
              </h2>
              <p className="text-15 text-dark-1 mt-5">
              Permission is granted to download temporary copy of the materials on REALSMART Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
              <br />- modify or copy the materials;
              <br />- use the materials for any commercial purpose or for any public display;
              <br />- attempt to reverse engineer any software contained on REALSMART Website;
              <br />- remove any copyright or other proprietary notations from the materials; or
              <br />- transferring the materials to another person or mirror the materials on any other server.
              <br />This will let REALSMART to terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format. These Terms of Service has been created with the help of the Terms Of Service Generator.
              </p>
              <h2 className="text-16 fw-500 mt-35">
                3. Disclaimer
              </h2>
              <p className="text-15 text-dark-1 mt-5">
              All the materials on REALSMART Website are provided as is. REALSMART makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, REALSMART does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.
              </p>
              <h2 className="text-16 fw-500 mt-35">
                4. Limitations
              </h2>
              <p className="text-15 text-dark-1 mt-5">
              REALSMART or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on REALSMART Website, even if REALSMART or an authorize representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.
              </p>
              <h2 className="text-16 fw-500 mt-35">
                5. Revisions and Errata
              </h2>
              <p className="text-15 text-dark-1 mt-5">
              The materials appearing on REALSMART Website may include technical, typographical, or photographic errors. REALSMART will not promise that any of the materials in this Website are accurate, complete, or current. REALSMART may change the materials contained on its Website at any time without notice. REALSMART does not make any commitment to update the materials.
              </p>
              <h2 className="text-16 fw-500 mt-35">
                6. Links
              </h2>
              <p className="text-15 text-dark-1 mt-5">
              REALSMART has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by REALSMART of the site. The use of any linked website is at the user own risk.
              </p>
              <h2 className="text-16 fw-500 mt-35">
                7. Terms of Use Modifications
              </h2>
              <p className="text-15 text-dark-1 mt-5">
              REALSMART may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.
              </p>
              <h2 className="text-16 fw-500 mt-35">
                8. Governing Law
              </h2>
              <p className="text-15 text-dark-1 mt-5">
              Any claim related to REALSMART Website shall be governed by the laws of Singapore without regards to its conflict of law provisions.
              </p>
            </div>
          </TabPanel>
          {/* End  General Terms of Use */}

          <TabPanel>
            <div className="tabs__content js-tabs-content" data-aos="fade">
              <h1 className="text-30 fw-600 mb-15"> Privacy policy</h1>
              <p className="text-15 text-dark-1 mt-5">
              At REALSMART, accessible from https://realsmart.sg, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by REALSMART and how we use it.
              <br />
              If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.
              </p>
              <br />
              <h2 className="text-16 fw-500">1. Information Access</h2>
              <p className="text-15 text-dark-1 mt-5">
              REALSMART accesses the following user information:
              <br />
              <ul className="text-14 ml-20">
                <li>i. <span className="fw-500">Display Name</span>: This is used to identify users in the discussion section of the app.</li>
                <li>ii. <span className="fw-500">Email Address</span>: This is used to identify users for analytics purposes and email notifications.</li>
              </ul>
              REALSMART uses the above user information to:
              <br />
              <ul className="text-14 ml-20">
                <li>i. <span className="fw-500">Display Name</span>: The display name is used solely to identify users in discussions within the app. It is not stored or used for any other purposes.</li>
                <li>ii. <span className="fw-500">Email Address</span>: The email address is used mainly for email notifications and communication, and also to identify users for analytics. This helps us understand how the app is used and improve our services.</li>
              </ul>
              REALSMART handles the above user information separately:
              <br />
              <ul className="text-14 ml-20">
                <li>i. <span className="fw-500">Display Name</span>: The display name is not stored anywhere within our systems.</li>
                <li>ii. <span className="fw-500">Email Address</span>: The email address is recorded in our analytics database. This data is securely stored and is only accessible to authorized personnel for the purpose of app analytics.</li>
              </ul>
              REALSMART does not share any user data with any parties:
              <br />
              <ul className="text-14 ml-20">
                <li>i. <span className="fw-500">Google User Data</span>: User data, including display names and email addresses, is not shared with any third parties. The data is solely used within the app for the purposes mentioned above and is not disclosed or sold to external entities.</li>
                <li>ii. <span className="fw-500">Facebook User Data</span>: User data, including display names and email addresses, is not shared with any third parties. The data is solely used within the app for the purposes mentioned above and is not disclosed or sold to external entities.</li>
              </ul>
              </p>
              <br />
              <h2 className="text-16 fw-500">2. Log Files</h2>
              <p className="text-15 text-dark-1 mt-5">
              REALSMART follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users movement on the website, and gathering demographic information.
              </p>
              <h2 className="text-16 fw-500 mt-35">
                3. Privacy Policies
              </h2>
              <p className="text-15 text-dark-1 mt-5">
                You may consult this list to find the Privacy Policy for each of the advertising partners of REALSMART.
                <br />
                Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on REALSMART, which are sent directly to users browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.
                <br />
                Note that REALSMART has no access to or control over these cookies that are used by third-party advertisers.
                <br />
                This Privacy Policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in REALSMART. This policy is not applicable to any information collected offline or via channels other than this website.
              </p>
              <h2 className="text-16 fw-500 mt-35">
                4. Third Party Privacy Policies
              </h2>
              <p className="text-15 text-dark-1 mt-5">
                REALSMART Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.
                <br />
                You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers respective websites. What Are Cookies?
              </p>
              <h2 className="text-16 fw-500 mt-35">
                5. Consent
              </h2>
              <p className="text-15 text-dark-1 mt-5">
              By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.
              </p>
            </div>
          </TabPanel>
          {/* End  Privacy policy */}

        </div>
        {/* End col-lg-9 */}
      </div>
    </Tabs>
  );
};

TermsContent.propTypes = {
  tab: PropTypes.string,
};

export default TermsContent;
