const SharePanel = ({
  url,
  clicked,
  onClose
}) => {
  const onCopy = () => {
    navigator.clipboard.writeText(url);
  };

  return (
    <div className={`langMenu langMenuTop js-langMenu ${clicked ? "" : "is-hidden"}`}>
      <div className="currencyMenu__bg" onClick={onClose}></div>
      <div className="langMenu__content bg-white rounded-4 rel-content">
        <div className="d-flex items-center justify-between px-30 py-20 sm:px-15 border-bottom-light">
          <div className="text-20 fw-500 lh-15">Share Property Link</div>
          <button className="pointer" onClick={onClose}>
            <i className="icon-close" />
          </button>
        </div>

        <div className="d-flex px-10 py-10">
          <div className="p-2 flex-grow-1 text-light-1 share-link px-10">
            {url}
          </div>
          <button
            className="p-2 button -dark-1 py-15 px-20 h-full rounded-0 bg-blue-1 text-white"
            onClick={() => onCopy()}
          >
            Copy
          </button>
        </div>
      </div>

    </div>
  );
};

export default SharePanel;
