import { formatDistanceLabel } from "@/utils/convert";
import { useState } from "react";

const MaxDistInput = ({
  defaultDist,
  setMaxDist
}) => {
  const [tmpDist, setTmpDist] = useState(defaultDist);

  return (
    <>
      <div className="fw-600 text-blue-1">Set max distance {formatDistanceLabel(tmpDist)}</div>
      <input
        type="range"
        min={0}
        max={1000}
        value={tmpDist}
        step={100}
        style={{ width: '100%' }}
        onChange={e => setTmpDist(parseInt(e.target.value))}
      />
      <button
        className="button mr-10 mb-5 -dark-1 bg-blue-1 text-white h-30 px-10 rounded-100 text-12 mt-10"
        onClick={() => setMaxDist(tmpDist)}
      >
        Apply
      </button>
    </>
  );
};

export default MaxDistInput;
