import { useEffect, useState } from 'react';
import CustomTable from '@/components/transactions/CustomTable';
import {
  MAP_MODE_HDB,
  PROP_TYPES_FILTER_OPTIONS,
  getHdbBlockLabel,
  getProjectLabel,
  getPropertyIconByType
} from '@/utils/map';
import { STANDARD_PAGE_CONFIG } from '@/utils/table';
import {
  convertDisplayDate
} from '@/utils/time';
import { noSelectClass } from '@/utils/user';
import Loader from '@/components/common/Loader';
import { formatDistanceLabel } from '@/utils/convert';
import Checkbox from '@/components/common/sidebar/Checkbox';

const DIST_SELECTIONS = [
  {
    label: '200m',
    value: 200
  },
  {
    label: '400m',
    value: 400
  },
  {
    label: '1 km',
    value: 1000
  },
];

const PERIOD_SELECTIONS = [
  {
    label: 'Past 1 Year',
    value: '12m',
    diff: 31556952000
  },
  {
    label: 'Past 6 Months',
    value: '6m',
    diff: 15778476000
  },
  {
    label: 'Past 3 Months',
    value: '3m',
    diff: 7889238000
  },
  {
    label: 'Past 1 Month',
    value: '1m',
    diff: 2629746000
  },
];

const DEFAULT_PROP_TYPES = { land: true, nonland: true };

const NearbyTransactions = ({
  user,
  target,
  transactions,
  goToProperty,
  loadNearbyTxsData,
  focus,
  setFocus
}) => {
  if (!transactions) {
    loadNearbyTxsData();
    return (
      <div className="loader-container">
        <Loader />
      </div>
    );
  }

  // let maxDistInTx = transactions.length > 0 ? Math.max(...transactions.map(t => t.distance)) : 0;
  // const distSelectable = maxDistInTx <= DIST_SELECTIONS[0].value
  //   ? [DIST_SELECTIONS[0]]
  //   : DIST_SELECTIONS.filter(d => d.value < maxDistInTx);
  const distSelectable = DIST_SELECTIONS;

  const [period, setPeriod] = useState(PERIOD_SELECTIONS[0].value);
  const [dist, setDist] = useState(distSelectable.length === 1 ? DIST_SELECTIONS[0].value : DIST_SELECTIONS[1].value);
  const [filterPropTypes, setFilterPropTypes] = useState(DEFAULT_PROP_TYPES);

  const isHdb = target.mode === MAP_MODE_HDB;

  const filter = (transactions) => {
    const selectedPeriod = PERIOD_SELECTIONS.find(s => s.value === period);
    const diff = new Date().getTime() - selectedPeriod.diff
    const data = transactions
      .filter(t => t.sale_date >= diff)
      .filter(t => t.distance <= dist)
      .filter(t => {
        if (isHdb) return true;
        const iconType = getPropertyIconByType(t.propertyType);
        const landed = iconType === 'mixed' || iconType === 'house';
        if (filterPropTypes.land && !filterPropTypes.nonland && !landed) return false;
        const nonlanded = iconType === 'condo' || iconType === 'flat' || iconType === 'mixed';
        if (filterPropTypes.nonland && !filterPropTypes.land && !nonlanded) return false;
        return true;
      });
    return data;
  };

  const [data, setData] = useState(filter(transactions));

  if (focus?.fine !== dist / 1000) {
    setFocus({
      ...focus,
      fine: dist / 1000
    });
  }

  useEffect(() => {
    setData(filter(transactions));
  }, [period, dist, filterPropTypes]);

  const DEFAULT_COLUMNS = isHdb
    ? [
        {
          Header: 'HDB',
          accessor: 'name',
          disableGlobalFilter: true,
          Cell: (row) =>
            <a
              className="text-blue-1 cursor-pointer"
              onClick={() => goToProperty(row)}
            >
              {getHdbBlockLabel(row.data[row.cell.row.index])}
            </a>
        },
        {
          Header: 'Distance',
          accessor: 'distance',
          disableGlobalFilter: true,
          width: 60,
          Cell: (row) => <div>{row.cell.value === 0 ? '-' : formatDistanceLabel(row.cell.value)}</div>
        },
        {
          Header: 'Sale Date',
          accessor: 'sale_date',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>{convertDisplayDate(new Date(row.cell.value))}</div>
        },
        {
          Header: 'Transacted Price ($)',
          accessor: 'transacted_price',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>${row.cell.value.toLocaleString()}</div>
        },
        {
          Header: 'Unit Price ($psf)',
          accessor: 'unitPrice',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>${row.cell.value.toFixed(2)}</div>
        },
        {
          Header: 'Area (sft)',
          accessor: 'area',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>{row.cell.value.toLocaleString()}</div>
        },
        {
          Header: 'Flat Type',
          accessor: 'flatType',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Storey',
          accessor: 'storeyRange',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Flat Model',
          accessor: 'flatModel',
          disableGlobalFilter: true,
          width: 120,
          Cell: (row) => <div>{row.cell.value?.toUpperCase()}</div>
        },
        {
          Header: 'Postal Code',
          accessor: 'postal',
          disableGlobalFilter: true,
          width: 80,
        },
      ]
    : [
        {
          Header: 'Project',
          accessor: 'name',
          disableGlobalFilter: true,
          Cell: (row) =>
            <a
              className="text-blue-1 cursor-pointer"
              onClick={() => goToProperty(row)}
            >
              {getProjectLabel(row.data[row.cell.row.index].marker, row.data[row.cell.row.index].name)}
            </a>
        },
        {
          Header: 'Distance',
          accessor: 'distance',
          disableGlobalFilter: true,
          width: 60,
          Cell: (row) => <div>{row.cell.value === 0 ? '-' : formatDistanceLabel(row.cell.value)}</div>
        },
        {
          Header: 'Sale Date',
          accessor: 'sale_date',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>{convertDisplayDate(new Date(row.cell.value))}</div>
        },
        {
          Header: 'Type of Sale',
          accessor: 'type',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Transacted Price ($)',
          accessor: 'transacted_price',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>${row.cell.value.toLocaleString()}</div>
        },
        {
          Header: 'Unit Price ($psf)',
          accessor: 'unitPrice',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>${row.cell.value.toFixed(2)}</div>
        },
        {
          Header: 'Area (sft)',
          accessor: 'area',
          disableGlobalFilter: true,
          width: 80,
          Cell: (row) => <div>{row.cell.value.toLocaleString()}</div>
        },
        {
          Header: 'Address',
          accessor: 'address',
          disableGlobalFilter: true,
          disableSortBy: true,
        },
        {
          Header: 'Postal Code',
          accessor: 'postal',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Property Type',
          accessor: 'propertyType',
          disableGlobalFilter: true,
          width: 90,
        },
        {
          Header: 'Type of Area',
          accessor: 'areaType',
          disableGlobalFilter: true,
          width: 80,
        },
        {
          Header: 'Purchaser Address Indicator',
          accessor: 'purchaserAddrIndicator',
          disableGlobalFilter: true,
          width: 80,
        },
      ];

  const [fullColumns, setFullColumns] = useState(DEFAULT_COLUMNS.map(col => ({ ...col, enabled: true })));
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);

  return (
    <div className="pt-10 mb-50">
      <div className={`data-table mt-20 pt-10 ${noSelectClass(user)}`}>
        <div className="mb-10 ml-5">
          <h3 className="text-15 fw-500">Recent transactions</h3>
        </div>
        
        <div className="d-flex mb-20">
          {!isHdb
            && <div className="p-2 dropdown js-dropdown js-category-active compare-chart-item proj-comp-spad">
              <div
                className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-10 py-5 text-12 lh-12 "
                data-bs-toggle="dropdown"
                data-bs-auto-close="true"
                aria-expanded="false"
                data-bs-offset="0,10"
                data-tooltip-id="map-tooltip"
                data-tooltip-html={`
                  <div class="map-tooltip-content">
                  <span class="fw-500">Filter by Property Type</span>
                  <br />
                  <span class="map-tooltip-hint">This filter overrides the map's filter</span>
                  </div>
                `}
                data-tooltip-variant="dark"
                data-tooltip-place="bottom"
              >
                <span className="js-dropdown-title text-12">{
                  filterPropTypes.land && filterPropTypes.nonland
                    ? 'All property types'
                    : (
                      filterPropTypes.land
                        ? 'Landed only'
                        : 'Condo/EC only'
                    )
                }</span>
                <i className="icon icon-chevron-sm-down text-7 ml-5" />
              </div>
              <div className="toggle-element -dropdown dropdown-menu overlay-menu">
                <div className="text-12 y-gap-5 js-dropdown-list">
                  {PROP_TYPES_FILTER_OPTIONS.map(option => (
                    <div key={option.id}>
                      <Checkbox
                        label={option.label}
                        value={filterPropTypes[option.id]}
                        setValue={v => {
                          let newFilterPropTypes = { ...filterPropTypes };
                          newFilterPropTypes[option.id] = v;
                          if (PROP_TYPES_FILTER_OPTIONS.every(opt => !newFilterPropTypes[opt.id])) {
                            newFilterPropTypes = DEFAULT_PROP_TYPES;
                          }
                          setFilterPropTypes(newFilterPropTypes);
                        }}
                        textSize="14"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          }

          <div className="p-2 dropdown js-dropdown js-category-active compare-chart-item proj-comp-spad">
            <div
              className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-15 py-10 text-12 lh-12"
              data-bs-toggle="dropdown"
              data-bs-auto-close="true"
              aria-expanded="false"
              data-bs-offset="0,10"
            >
              <span className="js-dropdown-title">
                <span className="text-blue-1 fw-600"></span>
                <span className="js-dropdown-dist">Within {
                  DIST_SELECTIONS.find(v => v.value === dist).label
                }</span>
              </span>
              <i className="icon icon-chevron-sm-down text-7 ml-10" />
            </div>
            <div className="toggle-element -dropdown dropdown-menu">
              <div className="text-13 y-gap-15 js-dropdown-list">
                {distSelectable.map(option => (
                  <div key={option.value}>
                    <button
                      className={`d-block js-dropdown-link ${
                        dist === option.value ? "text-blue-1 " : ""
                      }`}
                      onClick={() => {
                        setDist(option.value);
                        document.querySelector(".js-dropdown-dist").textContent = option.label;
                      }}
                    >
                      {option.label}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="p-2 dropdown js-dropdown js-category-active compare-chart-item proj-comp-spad">
            <div
              className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-15 py-10 text-12 lh-12"
              data-bs-toggle="dropdown"
              data-bs-auto-close="true"
              aria-expanded="false"
              data-bs-offset="0,10"
            >
              <span className="js-dropdown-title">
                <span className="text-blue-1 fw-600"></span>
                <span className="js-dropdown-time">{
                  PERIOD_SELECTIONS.find(v => v.value === period).label
                }</span>
              </span>
              <i className="icon icon-chevron-sm-down text-7 ml-10" />
            </div>
            <div className="toggle-element -dropdown dropdown-menu">
              <div className="text-13 y-gap-15 js-dropdown-list">
                {PERIOD_SELECTIONS.map(option => (
                  <div key={option.value}>
                    <button
                      className={`d-block js-dropdown-link ${
                        period === option.value ? "text-blue-1 " : ""
                      }`}
                      onClick={() => {
                        setPeriod(option.value);
                        document.querySelector(".js-dropdown-time").textContent = option.label;
                      }}
                    >
                      {option.label}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <CustomTable
          data={data}
          tableConfig={STANDARD_PAGE_CONFIG}
          defaultColumns={DEFAULT_COLUMNS}
          fullColumns={fullColumns}
          setFullColumns={setFullColumns}
          columns={columns}
          setColumns={setColumns}
          hideSearchInput
          hidePageButton
        />

      </div>
    </div>
  );
};

export default NearbyTransactions;
