const PropNotificationRow = () => {
  return (
    <div className="d-flex align-items-center align-self-center border-bottom-light py-10 px-10 text-14">
      <div className="p-2 flex-grow-1">
        <span className="text-blue-1 fw-600">Test</span> has 2 transactions 3 days ago
      </div>
      <button
        className="p-2 button mr-5 -dark-1 bg-blue-1 text-white h-30 px-5 rounded-100 text-12 px-10"
      >
        View Property
      </button>
    </div>
  );
};

export default PropNotificationRow;
