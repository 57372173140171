import PropTypes from "prop-types";

const MultiChartSelector = ({
  period, setPeriod, periodOptions,
  leftY, setLeftY, leftYOptions,
  rightY, setRightY, rightYOptions,
  interval, setTimeInterval, intervalOptions,
}) => {

  return (
    <>
      {/* <div className="dropdown js-dropdown js-category-active compare-chart-item proj-comp-spad">
        <div
          className="dropdown__button dropdown__button_sm d-flex items-center bg-white border-light rounded-100 px-15 py-10 text-12 lh-12"
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          aria-expanded="false"
          data-bs-offset="0,10"
        >
          <span className="js-dropdown-title">
            <span className="text-blue-1 fw-600">BAR</span>&nbsp;&nbsp; 
            <span className="js-dropdown-lefty">{
              leftYOptions.find(v => v.value === leftY).label
            }</span>
          </span>
          <i className="icon icon-chevron-sm-down text-7 ml-10" />
        </div>
        <div className="toggle-element -dropdown dropdown-menu">
          <div className="text-13 y-gap-15 js-dropdown-list">
            {leftYOptions.map(option => (
              <div key={option.value}>
                <button
                  className={`d-block js-dropdown-link ${
                    leftY === option.value ? "text-blue-1 " : ""
                  }`}
                  onClick={() => {
                    setLeftY(option.value);
                    document.querySelector(".js-dropdown-lefty").textContent = option.label;
                  }}
                >
                  {option.label}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="dropdown js-dropdown js-category-active compare-chart-item proj-comp-spad">
        <div
          className="dropdown__button dropdown__button_sm d-flex items-center bg-white border-light rounded-100 px-15 py-10 text-12 lh-12"
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          aria-expanded="false"
          data-bs-offset="0,10"
        >
          <span className="js-dropdown-title">
            <span className="text-blue-1 fw-600">LINE</span>&nbsp;&nbsp; 
            <span className="js-dropdown-righty">{
              rightYOptions.find(v => v.value === rightY).label
            }</span>
          </span>
          <i className="icon icon-chevron-sm-down text-7 ml-10" />
        </div>
        <div className="toggle-element -dropdown dropdown-menu">
          <div className="text-13 y-gap-15 js-dropdown-list">
            {rightYOptions.map(option => (
              <div key={option.value}>
                <button
                  className={`d-block js-dropdown-link ${
                    rightY === option.value ? "text-blue-1 " : ""
                  }`}
                  onClick={() => {
                    setRightY(option.value);
                    document.querySelector(".js-dropdown-righty").textContent = option.label;
                  }}
                >
                  {option.label}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div> */}

      <div className="dropdown js-dropdown js-category-active compare-chart-item proj-comp-spad">
        <div
          className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-15 py-10 text-12 lh-12"
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          aria-expanded="false"
          data-bs-offset="0,10"
        >
          <span className="js-dropdown-title">
            <span className="text-blue-1 fw-600"></span>
            <span className="js-dropdown-time">{
              periodOptions.find(v => v.value === period).label
            }</span>
          </span>
          <i className="icon icon-chevron-sm-down text-7 ml-10" />
        </div>
        <div className="toggle-element -dropdown dropdown-menu">
          <div className="text-13 y-gap-15 js-dropdown-list">
            {periodOptions.map(option => (
              <div key={option.value}>
                <button
                  className={`d-block js-dropdown-link ${
                    period === option.value ? "text-blue-1 " : ""
                  }`}
                  onClick={() => {
                    setPeriod(option.value);
                    document.querySelector(".js-dropdown-time").textContent = option.label;
                  }}
                >
                  {option.label}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="dropdown js-dropdown js-category-active compare-chart-item proj-comp-spad">
        <div
          className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-15 py-10 text-12 lh-12"
          data-bs-toggle="dropdown"
          data-bs-auto-close="true"
          aria-expanded="false"
          data-bs-offset="0,10"
        >
          <span className="js-dropdown-title">
            <span className="text-blue-1 fw-600"></span>
            <span className="js-dropdown-interval">{
              intervalOptions.find(v => v.value === interval).label
            }</span>
          </span>
          <i className="icon icon-chevron-sm-down text-7 ml-10" />
        </div>
        <div className="toggle-element -dropdown dropdown-menu">
          <div className="text-13 y-gap-15 js-dropdown-list">
            {intervalOptions.map(option => (
              <div key={option.value}>
                <button
                  className={`d-block js-dropdown-link ${
                    interval === option.value ? "text-blue-1 " : ""
                  }`}
                  onClick={() => {
                    setTimeInterval(option.value);
                    document.querySelector(".js-dropdown-interval").textContent = option.label;
                  }}
                >
                  {option.label}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

    </>
  );
};

MultiChartSelector.propTypes = {
  interval: PropTypes.string.isRequired,
  setTimeInterval: PropTypes.func.isRequired,
  intervalOptions: PropTypes.array.isRequired, 
  period: PropTypes.string.isRequired,
  setPeriod: PropTypes.func.isRequired,
  periodOptions: PropTypes.array.isRequired,
  leftY: PropTypes.string.isRequired,
  setLeftY: PropTypes.func.isRequired,
  leftYOptions: PropTypes.array.isRequired,
  rightY: PropTypes.string.isRequired,
  setRightY: PropTypes.func.isRequired,
  rightYOptions: PropTypes.array.isRequired,
};

export default MultiChartSelector;
