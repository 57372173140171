import { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Table as BootstrapTable } from 'react-bootstrap';
import {
  useTable,
  useGlobalFilter,
  useFlexLayout,
  usePagination,
  useSortBy,
  useResizeColumns,
  useRowSelect,
} from 'react-table';

import ReactTableHeader from './ReactTableHeader';
import BodyReactTable from './BodyReactTable';
import ReactTableFilter from './ReactTableFilter';
import ReactTablePagination from './ReactTablePagination';

const CustomTable = ({
  name, defaultColumns, data, tableConfig,
  hideFilter, hidePage, hideSearchInput,
  pageLimit, setPageLimit, customSort, onCustomSort,
  fullColumns, setFullColumns,
  columns, setColumns,
  style,
  showFilterButton,
  onFilterButtonClick,
  groupOptions, selectedGroupOption, setSelectedGroupOption, originalColumns,
  hidePageButton,
  disableCustomColumns,
  onHeaderClick
}) => {
  const [filterValue, setFilterValue] = useState(null);

  const tableOptions = {
    columns,
    data,
    setFilterValue,
    defaultColumn: {},
    dataLength: data?.length,
    initialState: {
      pageIndex: 0,
      pageSize: pageLimit ?? 10,
      globalFilter: filterValue ?? undefined,
    },
  };

  const tableOptionalHook = [useFlexLayout];
  const {
    placeholder,
    manualPageSize,
  } = tableConfig;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    state,
    rows,
    prepareRow,
    page,
    pageCount,
    pageOptions,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    setPageSize,
    setGlobalFilter,
    // withDragAndDrop,
    dataLength,
    state: { pageIndex, pageSize },
  } = useTable(
    tableOptions,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useResizeColumns,
    useRowSelect,
    ...tableOptionalHook,
  );

  const customizeColumns = (newColumns) => {
    setFullColumns(newColumns);
    setColumns(newColumns.filter(c => c.enabled));
  }

  const resetColumns = () => {
    setFullColumns(defaultColumns.map(col => ({ ...col, enabled: true })));
    setColumns(defaultColumns);
  };

  const toggleColumn = (toggle, id) => {
    const newColumns = fullColumns;
    for (let i = 0; i < newColumns.length; i += 1) {
      if (newColumns[i].Header === id) {
        newColumns[i].enabled = toggle;
      }
    }
    setFullColumns([...newColumns]);
    setColumns(newColumns.filter(c => c.enabled));
  };

  return (
    <div className="">
      {!hideFilter
        && <ReactTableFilter
          name={name}
          rows={rows}
          setGlobalFilter={setGlobalFilter}
          setFilterValue={tableOptions.setFilterValue}
          globalFilter={state.globalFilter}
          placeholder={placeholder}
          dataLength={dataLength}
          currentColumns={fullColumns.map(c => ({ ...c, toggler: toggleColumn }))}
          setColumns={customizeColumns}
          resetColumns={resetColumns}
          hideSearchInput={hideSearchInput}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          customizeColumns={customizeColumns}
          hidePage={hidePage}
          showFilterButton={showFilterButton}
          onFilterButtonClick={onFilterButtonClick}
          groupOptions={groupOptions}
          originalColumns={originalColumns}
          selectedGroupOption={selectedGroupOption}
          setSelectedGroupOption={setSelectedGroupOption}
          hidePageButton={hidePageButton}
          disableCustomColumns={disableCustomColumns}
        />
      }
      <TableWrap className="tx-table" style={style}>
        <Table
          {...getTableProps()}
          bordered
        >
          <ReactTableHeader
            name={name}
            headerGroups={headerGroups}
            isSortable={true}
            isResizable={true}
            customSort={customSort}
            onCustomSort={onCustomSort}
            onHeaderClick={onHeaderClick}
          />
          <BodyReactTable
            page={page}
            getTableBodyProps={getTableBodyProps}
            prepareRow={prepareRow}
            // updateDraggableData={updateDraggableData}
            // updateEditableData={updateEditableData}
            isEditable={false}
            // withDragAndDrop={withDragAndDrop}
          />
          {data.length === 0
            && <div className="w100 text-center py-10">No data</div>
          }
          {/* {pageCount === (pageIndex + 1) && (
            <ReactTableFooter
              footerGroups={footerGroups}
            />
          )} */}
        </Table>
      </TableWrap>
      {!hidePage && rows?.length > 0
        && <ReactTablePagination
          page={page}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageSize={pageSize}
          pageIndex={pageIndex}
          pageCount={pageCount}
          setPageSize={setPageSize}
          manualPageSize={manualPageSize}
          dataLength={dataLength}
        />
      }
    </div>
  );
};

const TableWrap = styled.div`
  overflow-x: auto;
  margin-bottom: 1rem;

  &::-webkit-scrollbar {
    width: 8px;
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar-track {
      background: #fff;
  }
  &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb:hover {
      background: #555;
  }
`;

const Table = styled(BootstrapTable)`
  text-align: left;

  &:last-child {
    margin-bottom: 0;
  }

  table {
    width: inherit;
  }

  th:first-child {
    position: sticky !important;
    left: 0;
    z-index: 1;
  }

  td:first-child {
    position: sticky !important;
    left: 0;
    background-color: #fff !important;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  thead tr {
    position: sticky;
    top: 0;
    border-top: none;
  }

  thead th {
    min-height: 45px;
    min-width: 100px;
    padding: 10px 0 10px 0;
    top: 0;
    position: sticky;
    color: #000;
    border-bottom: 2px solid #ebecff;
    border-bottom-color: #ebecff !important;
    background: #fff !important;

    &:first-child {
      min-width: 45px;
    }
  }

  tbody td,
  tfoot td,
  th {
    border: none;
    padding: 10px 5px;
  }

  &.table tbody td,
  &.table tfoot td,
  th {
    color: #595959;
  }

  th, td {
    vertical-align: middle;
    background-color: transparent !important;

    &:first-child {
      padding-left: 10px;
    }

    &:last-child {
      padding-right: 10px;
    }
  }

  .badge {
    font-size: 100%;
  }

  tfoot {
    
    tr {
      border-top: 1px solid #eff1f5;
      border-bottom: none;
    }
    
    td {
      min-height: 45px;
      min-width: 100px;
      padding: 10px 5px 10px 5px;
      font-family: 'Roboto', sans-serif;
      color: #000;
      font-weight: 700;

      &:first-child {
        min-width: 45px;
        white-space: nowrap
      }
    }
  }

  &.table-hover {

    tbody tr {
      transition: all 0.3s;
      cursor: pointer;

      &:hover {
        background-color: #c7c7c7;
      }
    }
  }

  &.react-table {

    thead tr {
      position: sticky;
      top: 0;
      background: white;
    }
  }

  .react-table__column-header {
    display: flex;
    flex-wrap: wrap;
    align-content: center;

    &:first-child{
      margin-right: 10px;
    }

    &.sortable {
      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;
        right: -20px;
      }
    }
  }

  &.resizable-table {

    thead {
      min-width: 100px;
      overflow-x: hidden;

      &:first-child {
        min-width: 45px;
      }
    }

    tbody {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    th,
    td {
      min-width: 100px !important;

      &:first-child {
        max-width: 60px;
        min-width: 60px !important;
      }

      .resizer {
        top: 5px;
        right: 10px;
        width: 4px;
        height: 70%;
        position: absolute;
        touch-action :none;
        background: #ebecff;

        &.isResizing {
          background: #c7c7c7;
        }
      }
    }
  }

  .react-dnd--off {
    pointer-events: none;
  }

  .react-dnd-draggable {
    z-index: 98 !important;

    td {
      width: inherit;

      &:first-child {
        width: 70px;
        min-width: 70px !important;
      }
    }
  }

  &.editable-table {
    user-select: none; /* standard syntax */
    -webkit-user-select: none; /* webkit (safari, chrome) browsers */
    -moz-user-select: none; /* mozilla browsers */
    -ms-user-select: none; /* IE10+ */

    thead {
      min-width: 100px;
      overflow-x: hidden;
    }

    th,
    td {
      min-width: 100px;

      &:first-child {
        max-width: 60px;
        min-width: 60px;
      }
    }

    .table__editable-table--cell-active {
      max-width: inherit;
      border: none;
      outline: 2px solid #8cddff;
      margin: -7px 10px -6px 0;
    }

    .table__edit-form {
      max-width: fit-content;
      border: none;
      border-radius: 0;
      font-size: 13px;
      font-family: 'Roboto', sans-serif;
      padding-left: 0;
      background-color: #fff;
      color: #8cddff;

      &:active {
        border: none;
        outline: 1px solid #006e9c;
      }
    }

    .form-control {

      &:focus {
        box-shadow: none;
      }
    }
  }
`;

CustomTable.propTypes = {
  name: PropTypes.string,
  tableConfig: PropTypes.object,
  data: PropTypes.array,
  defaultColumns: PropTypes.array,
  hideFilter: PropTypes.bool,
  hidePage: PropTypes.bool,
  hideSearchInput: PropTypes.bool,
  pageLimit: PropTypes.number,
  setPageLimit: PropTypes.func,
  customSort: PropTypes.object,
  onCustomSort: PropTypes.func,
  fullColumns: PropTypes.array,
  setFullColumns: PropTypes.func,
  columns: PropTypes.array,
  setColumns: PropTypes.func,
  style: PropTypes.object,
  showFilterButton: PropTypes.bool,
  groupOptions: PropTypes.array,
  setSelectedGroupOption: PropTypes.func,
};

CustomTable.defaultProps = {
  name: 'default',
  hideFilter: false,
  hidePage: false,
  hideSearchInput: false,
  onCustomSort: null,
  fullColumns: [],
  setFullColumns: null,
  columns: [],
  setColumns: null,
  style: {},
  showFilterButton: false,
  groupOptions: null,
  setSelectedGroupOption: null,
};

export default CustomTable;
