const PostActionButton = ({
  label,
  icon,
  onClick,
  light
}) => {
  return (
    <button
      className={`text-12 ${light ? 'text-blue-2' : 'text-blue-1'} fw-600 px-10 py-10 ml-5 mr-5 noselect`}
      onClick={() => onClick?.()}
    >
      {icon && <i className={`icon-${icon} mr-5`} />}
      {label?.toUpperCase()}
    </button>
  );
};

export default PostActionButton;
