import { getDataConnect, queryRef, executeQuery, mutationRef, executeMutation, validateArgs } from 'firebase/data-connect';

export const connectorConfig = {
  connector: 'default',
  service: 'Realsmart',
  location: 'asia-southeast1'
};

export function createPropertyLikeRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'CreatePropertyLike', inputVars);
}
export function createPropertyLike(dcOrVars, vars) {
  return executeMutation(createPropertyLikeRef(dcOrVars, vars));
}
export function deletePropertyLikeRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'DeletePropertyLike', inputVars);
}
export function deletePropertyLike(dcOrVars, vars) {
  return executeMutation(deletePropertyLikeRef(dcOrVars, vars));
}
export function createPropertyLikesRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'CreatePropertyLikes', inputVars);
}
export function createPropertyLikes(dcOrVars, vars) {
  return executeMutation(createPropertyLikesRef(dcOrVars, vars));
}
export function deletePropertyLikesRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return mutationRef(dcInstance, 'DeletePropertyLikes', inputVars);
}
export function deletePropertyLikes(dcOrVars, vars) {
  return executeMutation(deletePropertyLikesRef(dcOrVars, vars));
}
export function listLikesRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return queryRef(dcInstance, 'ListLikes', inputVars);
}
export function listLikes(dcOrVars, vars) {
  return executeQuery(listLikesRef(dcOrVars, vars));
}
export function getLikeByIdRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return queryRef(dcInstance, 'GetLikeById', inputVars);
}
export function getLikeById(dcOrVars, vars) {
  return executeQuery(getLikeByIdRef(dcOrVars, vars));
}
export function listPropertyLikesRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return queryRef(dcInstance, 'ListPropertyLikes', inputVars);
}
export function listPropertyLikes(dcOrVars, vars) {
  return executeQuery(listPropertyLikesRef(dcOrVars, vars));
}
export function getPropertyLikeByIdRef(dcOrVars, vars) {
  const { dc: dcInstance, vars: inputVars} = validateArgs(connectorConfig, dcOrVars, vars, true);
  if('_useGeneratedSdk' in dcInstance) {
    dcInstance._useGeneratedSdk();
  } else {
    console.error('Please update to the latest version of the Data Connect SDK by running `npm install firebase@dataconnect-preview`.');
  }
  return queryRef(dcInstance, 'GetPropertyLikeById', inputVars);
}
export function getPropertyLikeById(dcOrVars, vars) {
  return executeQuery(getPropertyLikeByIdRef(dcOrVars, vars));
}
