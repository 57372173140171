import { NA } from "@/utils/convert";
import {
  getPropertyIconByType, getUpcomingMarkerColor,
} from "@/utils/map";
import { checkMediaQuery } from "@/utils/user";
import { Marker } from "react-map-gl/maplibre";

const UpcomingMarker = ({
  idx,
  property,
  goToProperty,
  onMouseEnter,
  onMouseLeave,
  scheme
}) => {
  // handle icon with text
  const iconType = getPropertyIconByType(property.types);
  const hasText = iconType === 'condo' || iconType === 'flat';
  const iconText = hasText ? (
    iconType === 'flat'
      ? 'CONDO'
      : 'EC'
  ) : null;

  return (
    <Marker
      key={`upm-${idx}`}
      latitude={property.lat}
      longitude={property.lng}
      anchor="bottom"
      onClick={e => {
        e.originalEvent.stopPropagation();
        goToProperty(property);
      }}
    >
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-bs-toggle="offcanvas"
        data-bs-target="#propSidebar"
        {...(checkMediaQuery() ? {} : {
          'data-tooltip-id': "upcoming-tooltip",
          'data-tooltip-html': checkMediaQuery() ? `<div class="map-tooltip-content"><span class="fw-500">${property.project}</span></div>` : `
            <div class="map-tooltip-content">
            <div class="text-10 text-light-1 fw-600">NEW / UPCOMING PROJECT</div>
            <span class="fw-500">${property.project}</span>
            <div class="text-10 fw-600">${property.street}</div>
            <div class="map-tooltip-l-content">
            Total Units: ${property.totalUnits !== null ? property.totalUnits.toLocaleString() : NA}
            <br />
            Property Types: ${property.types.join(', ')}
            </div>
            <!-- span class="map-tooltip-hint">Click to view details</span -->
            </div>
          `,
          'data-tooltip-variant': "dark",
          'data-tooltip-place': "bottom"
        })}
        style={{
          transform: 'scale(0.7)'
        }}
      >
        <svg
          height={60}
          viewBox="0 0 24 24"
          style={{
            cursor: 'pointer',
            stroke: 'none',
          }}
        >
          <circle cx="12" cy="10" r="10" fill={getUpcomingMarkerColor(scheme)} />
          <circle cx="12" cy="10" r="8" fill="#fff" />
          <image
            href={`/img/general/${iconType}.png`}
            x={hasText ? "8" : "7"} 
            y={hasText ? "3" : "5"}
            width={hasText ? "9" : "10"}
            height={hasText ? "9" : "10"}
          />
          {hasText
            && <text
              x="12"
              y="15"
              textAnchor="middle"
              dominantBaseline="middle"
              fontSize={iconType === 'flat' ? "2.8" : "4"}
              fill="#000"
              fontWeight="500"
              fontFamily="'Arial Black', sans-serif"
            >
              {iconText}
            </text>
          }
        </svg>
      </div>
    </Marker>
  );
};

export default UpcomingMarker;
