import { useState, useEffect, useRef, useCallback } from "react";
import {
  useNavigate,
  useLocation,
  useSearchParams
} from "react-router-dom";

import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import DefaultHeader from "@/components/header/default-header";
import MetaComponent from "@/components/common/MetaComponent";
import Loader from "@/components/common/Loader";
import ErrorContent from "@/components/mapv2/error/ErrorContent";
import {
  continueIfAllowedUser,
  getCachedPropLikes,
  isLoggedIn,
  noSelectClass,
  setCachedPropLikes
} from "@/utils/user";
import MainMap from "@/components/mapv2/MainMap";
import {
  getFile,
  getJsonFile,
  likeProperty,
  logComparePro,
  logMapMarkerSelect,
  logPageView,
  trackEvent,
  trackPageView,
  unlikeProperty
} from "@/utils/api";
import {
  HDB_MAP_FILTER_OPTIONS,
  MAP_FILTER_OPTIONS,
  MAP_MODE_CONDO,
  MAP_MODE_HDB,
  MAP_MODE_LANDED,
  decompressAdvMapData,
  decompressBasicMapData,
  decompressHdbAdvMapData,
  decompressHdbBasicMapData,
  decompressHdbSingleCompData,
  decompressSingleCompData,
  getProjectKey,
  getProjectLabel,
  getPropertyFileName,
  isHdbType
} from "@/utils/map";
import FilterBox from "@/components/mapv2/search/FilterBox";
import { convertMapUrl, resetMapUrl } from "@/utils/url";
import PropertyView from "@/components/mapv2/PropertyView";
import {
  LOCATION_AREA,
  LOCATION_BLOCK,
  LOCATION_COMPARE,
  LOCATION_HDB,
  LOCATION_MARKER,
  LOCATION_PROPERTY,
  LOCATION_SCHOOL,
  LOCATION_STATION,
  LOCATION_UPCOMING,
  convertPropertyTypeToInt
} from "@/utils/areas";
import { initializeFilters } from "@/utils/filter";
import AreaView from "@/components/mapv2/AreaView";
import AmenityView from "@/components/mapv2/AmenityView";
import CompareProView from "@/components/mapv2/CompareProView";
import UpcomingView from "@/components/mapv2/UpcomingView";
import BotPanel from "@/components/mapv2/BotPanel";
import LoginPopup from "@/components/login/LoginPopup";
import { calculateDist } from "@/utils/convert";

const DEFAULT_METADATA = {
  title: "REALSMART.SG | Search Properties | Supercharge your property search",
  description: "REALSMART.SG - Supercharge your property search",
};

/*
URL filter cases:
  http://localhost:5173/map?mode=c&prop_type=Detached%20House
  http://localhost:5173/map?mode=h&flat_type=2%20ROOM
*/

const HomeMap = ({
  user,
  session,
  allowModeSwitch = false,
  allowBot = false,
}) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const getDefaultTarget = (params) => {
    if (params.get('id')) {
      let queryMode = MAP_MODE_CONDO;
      if (params.get('mode')) {
        queryMode = params.get('mode');
      }
      if (params.get('p')) {
        return {
          id: params.get('id'),
          projectId: params.get('p'),
          type: LOCATION_PROPERTY,
          mode: queryMode
        };
      } else {
        return {
          id: params.get('id'),
          type: queryMode === MAP_MODE_HDB ? LOCATION_PROPERTY : LOCATION_MARKER,
          mode: queryMode
        };
      }
    } else if (params.get('sch')) {
      return {
        id: params.get('sch'),
        type: LOCATION_SCHOOL
      };
    } else if (params.get('stn')) {
      return {
        id: params.get('stn'),
        type: LOCATION_STATION
      };
    } else if (params.get('new')) {
      return {
        id: params.get('new'),
        type: LOCATION_UPCOMING
      };
    } else if (params.get('s')) {
      // this search is to maintain backward compatibility with v1 table pages
      return {
        search: {
          project: params.get('s'),
          street: params.get('t')
        },
        type: 'search'
      };
    } else if (params.get('m')) {
      let queryMode = MAP_MODE_CONDO;
      if (params.get('mode')) {
        queryMode = params.get('mode');
      }
      if (params.get('m') === 'compare') {
        return {
          type: LOCATION_COMPARE,
          mode: queryMode
        };
      }
    }
    return null;
  };

  const getDefaultMode = (params) => {
    if (params.get('mode')) {
      return params.get('mode');
    }
    return MAP_MODE_CONDO;
  };

  const [userConfig, setUserConfig] = useState({
    profitableColor: 'red', // either red or green for most profitable
  });

  const [metadata, setMetadata] = useState(DEFAULT_METADATA);

  const [showLogin, setShowLogin] = useState(true);

  const [mode, setMode] = useState(getDefaultMode(params)); // default map mode is condo

  const [lightboxImages, setLightboxImages] = useState(null);

  const [actionSignal, setActionSignal] = useState(null); // as a quick way to trigger child actions without ref

  // handle liked props in cache
  const defaultLikedProps = getCachedPropLikes() ?? {};
  const [likedProps, setLikedProps] = useState(defaultLikedProps);

  const [target, setTarget] = useState(getDefaultTarget(params)); // actual target to load data
  const [focus, setFocus] = useState(null);                       // zoom in on map only
  const [compareList, setCompareList] = useState({});             // data list to use for compare pro
  
  const [hasSearchResults, setHasSearchResults] = useState(false);

  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(null);

  // private condo
  const [mapData, setMapData] = useState(null);
  const [hasAdvMapData, setHasAdvMapData] = useState(false);
  const [filterSelected, setFilterSelected] = useState(initializeFilters(mode === MAP_MODE_HDB ? HDB_MAP_FILTER_OPTIONS : MAP_FILTER_OPTIONS, params));
  const [incomingFilter, setIncomingFilter] = useState(null);

  // private landed
  // TODO
  const [landedMapData, setLandedMapData] = useState(null);
  const [hasAdvLandedMapData, sethasAdvLandedMapData] = useState(false);

  // hdb
  const [hdbMapData, setHdbMapData] = useState(null);
  const [hasAdvHdbMapData, setHasAdvHdbMapData] = useState(false);

  const [isMinimized, setIsMinimized] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false);
  const isMinimizedRef = useRef(isMinimized);
  const [screenDim, setScreenDim] = useState({ height: window.innerHeight, width: window.innerWidth });

  const [gpsLocation, setGpsLocation] = useState(null);

  // handle URL search parameters to load correct place
  const loadTarget = (target, mapData) => {
    if (target?.type === LOCATION_PROPERTY) {
      if (target?.mode === MAP_MODE_HDB) {
        loadHdbMapData(target);
      } else {
        const property = mapData.projects.find(p => p.marker === target.id && p.projectId === target.projectId);
        const marker = mapData.markers.find(m => m.name === target.id);
        if (marker && property) goToPropertyUsingData(marker, property);
        else setTarget(null);
      }
    } else if (target?.type === LOCATION_MARKER) {
      const marker = mapData.markers.find(m => m.name === target.id);
      if (marker) goToMarkerUsingData(marker);
      else setTarget(null);
    } else if (target?.type === LOCATION_SCHOOL) {
      const schoolData = mapData.schools.find(s => s.id === target.id);
      if (schoolData) goToSchool(schoolData);
      else setTarget(null);
    } else if (target?.type === LOCATION_STATION) {
      const stationData = mapData.stations.find(s => s.id === target.id);
      if (stationData) goToStation(stationData);
      else setTarget(null);
    } else if (target?.type === LOCATION_UPCOMING) {
      const upcomingData = mapData.upcomings.find(s => s.id === target.id);
      if (upcomingData) goToUpcoming(upcomingData);
      else setTarget(null);
    } else if (target?.type === 'search') {
      const projects = mapData.projects.filter(p => p.project === target.search.project);
      if (projects.length > 0) {
        // for now just take the first matching project
        const project = projects[0];
        const markers = mapData.markers.filter(m => m.name === project.marker);
        if (markers.length > 0) {
          const marker = markers[0];
          goToPropertyUsingData(marker, project);
        }
      } else {
        setTarget(null);
      }
    } else if (target?.type === LOCATION_COMPARE) {
      viewComparePro();
    }
  };

  // handler for history stack pop
  const handleHistoryPop = useCallback(() => {
    const currentUrl = new URL(window.location.href);
    const params = currentUrl.searchParams;
    const target = getDefaultTarget(params);
    if (target) {
      loadTarget(target, mapData);
    }
  }, [mapData]);

  /* Load map */
  const loadMapData = () => {
    setErr(null);

    getFile('m', 'm', txt => {
      // set the default map data
      const mapData = decompressBasicMapData(txt);
      setMapData(mapData);

      setLoading(false);

      // handle url if query parameters specified
      loadTarget(target, mapData);
    }, err => {
      setLoading(false);
      setErr('Failed to load map');
    });
  };

  const loadAdvMapData = (mapData, callback) => {
    getFile('m', mapData.adv, txt => {
      const data = decompressAdvMapData(txt);
      const newMapData = { ...mapData };
      data.forEach(r => {
        const idx = newMapData.projectIndex[getProjectKey(r)];
        if (idx === undefined) return;  // skip projects not found
        newMapData.projects[idx] = {
          ...newMapData.projects[idx],
          ...r
        };
      });
      setMapData(newMapData);
      setHasAdvMapData(true);
      callback?.(newMapData.projects);
    });
  };

  const loadHdbMapData = (target, callBack) => {
    setLoading(true);
    setErr(null);

    getFile('m', 'h1', txt => {
      // set the default map data
      const mapData = decompressHdbBasicMapData(txt);
      setHdbMapData(mapData);

      setLoading(false);

      // resume target state
      if (target?.type === LOCATION_PROPERTY && target?.mode === MAP_MODE_HDB) {
        if (target.id in mapData.index) {
          const block = mapData.blocks[mapData.index[target.id]];
          if (block) goToHdbUsingData(block);
        }
      }

      callBack?.(mapData);
    }, err => {
      setLoading(false);
      setErr('Failed to load map');
    });
  };

  const loadHdbAdvMapData = (mapData, callback) => {
    if (hasAdvHdbMapData) {
      callback?.(hdbMapData.blocks);
      return;
    }
    getFile('m', hdbMapData.adv, txt => {
      const data = decompressHdbAdvMapData(txt);
      const newMapData = { ...mapData };
      data.forEach(r => {
        const idx = newMapData.index[r.postal];
        if (idx === undefined) return;  // skip projects not found
        newMapData.blocks[idx] = {
          ...newMapData.blocks[idx],
          ...r
        };
      });
      setHdbMapData(newMapData);
      setHasAdvHdbMapData(true);
      callback?.(newMapData.blocks);
    });
  };

  const queryGpsLocation = (callback) => {
    // TODO: think of how to show iPhone Safari users who did not allow GPS
    // on Safari to enable it if they need GPS
    // https://whatpwacando.today/geolocation

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          // do a check on gps location to ensure only singapore coords will be persisted
          if (latitude < 1.15
              || latitude >> 1.55
              || longitude < 103.45
              || longitude > 104.2
            ) {
              // not in singapore
              return;
          }
          
          // set new gps location
          const newGpsLocation = {
            lat: latitude,
            lng: longitude
          };
          setGpsLocation(newGpsLocation);
          callback?.(newGpsLocation);
        },
        (error) => {
          switch(error.code) {
            case error.PERMISSION_DENIED:
            case error.POSITION_UNAVAILABLE:
            case error.TIMEOUT:
            case error.UNKNOWN_ERROR:
              setGpsLocation({
                err: true,
                errCode: error.code
              });
              break;
          }
          console.error('Error obtaining gps location:', error.message);
        },
        {
          enableHighAccuracy: true,
          timeout: 6000,    // 6 seconds timeout
          maximumAge: 0     // disable cached location
        }
      );
    }
  };

  const fetchGpsLocation = async (callback) => {
    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (result.state === 'granted' || result.state === 'prompt') {
          queryGpsLocation(callback);
        }
      });
    } else {
      queryGpsLocation(callback);
    }
  };

  // useEffect(() => {
  //   continueIfAllowedUser(user, () => {
  //     logPageView('MAP', session);
  //   }, navigate, pathname);
  // }, [user]);

  /**
   * Load map data right at the start - if the map data can be cached and user is a guest
   * user will revisit map with the cached map data anyway
   */
  useEffect(() => {
    // load map data right at the start
    if (!mapData) loadMapData();

    // load user config from localStorage
    // handle local + remote config in future
    // for now, just handle local
    if (localStorage.getItem("rsMapPref")) {
      setUserConfig(JSON.parse(localStorage.getItem("rsMapPref")));
    }

    // handle user gps location if exists
    fetchGpsLocation();

    // page view analytics
    logPageView('MAP', session);
    trackPageView('map');
  }, []);

  useEffect(() => {
    // TODO
    // if (likedProps && Object.keys(likedProps).length > 0) {
    //   setCachedPropLikes(likedProps);
    // }
  }, [likedProps]);

  useEffect(() => {
    // reset compare list
    setCompareList({});

    if (mode === MAP_MODE_HDB) {
      // handle hdb
      if (!hdbMapData) {
        loadHdbMapData();
      }
    } else if (mode === MAP_MODE_LANDED) {
      // handle private landed
      // TODO
    }

    // update the url
    resetMapUrl(mode);
  }, [mode]);

  const setProfitableColorScheme = (setting) => {
    const updatedConfig = {
      ...userConfig,
      profitableColor: setting,
      last_update: new Date().getTime()
    };
    setUserConfig(updatedConfig);
    localStorage.setItem("rsMapPref", JSON.stringify(updatedConfig));
  };

  useEffect(() => {
    window.addEventListener('popstate', handleHistoryPop);

    // clean up on unmount of component
    return () => {
      window.removeEventListener('popstate', handleHistoryPop);
    };
  }, [handleHistoryPop]);

  /* Handle screen sizing */
  useEffect(() => { isMinimizedRef.current = isMinimized }, [isMinimized]);

  const handleResize = () => {
    setScreenDim({ height: window.innerHeight, width: window.innerWidth });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /* Handle property window */
  const handleMinimize = () => {
    setIsMinimized(true);
    setIsMaximized(false);
  };

  const handleDefaultGivenTarget = (target) => {
    setIsMinimized(false);
    setIsMaximized(false);
  };

  const handleDefault = () => {
    handleDefaultGivenTarget(target);
  };

  const handleMaximize = () => {
    setIsMaximized(true);
    setIsMinimized(false);
  };

  const closePropertyDetails = () => {
    setTarget(null);
    setFocus(null);
    convertMapUrl(null);
  }

  /* Map interaction */
  const goToPropertyUsingData = (marker, property) => {
    if (!property || !marker) return;
    const markerName = property.marker;
    const projectId = property.projectId;

    // set new target
    const newTarget = {
      ...property,
      id: markerName,
      projectId,
      markerData: marker,
      type: LOCATION_PROPERTY
    };
    setTarget(newTarget);

    // set UI layout
    if (isMaximized || isMinimized) handleDefaultGivenTarget(newTarget);

    // set focus target on map
    setFocus({
      target: { ...newTarget }
    });

    // update page URL
    convertMapUrl(newTarget);

    // log for analytics
    logMapMarkerSelect(session, { type: LOCATION_PROPERTY, marker: markerName, project: property.project, id: projectId });

    // handle guest
    showLoginIfGuest();
  };

  const goToProperty = (markerName, projectId) => {

    // do nothing if already selected this property
    if (target?.type === LOCATION_PROPERTY && markerName === target?.id && projectId === target?.projectId) return;

    // find property
    const property = mapData.projects.find(p => p.marker === markerName && p.projectId === projectId);
    if (!property) return;

    // find marker
    const marker = mapData.markers.find(m => m.name === markerName);
    if (!marker) return;

    goToPropertyUsingData(marker, property);
  };

  const goToMarkerUsingData = (marker) => {
    if (!marker) return;
    const markerName = marker.name;

    // check if only 1 property in marker
    // if so it means that no need to view marker, just jump straight to property
    if (marker.properties.length > 1) {
      // set new target
      const newTarget = {
        ...marker,
        id: markerName,
        projects: marker.properties,
        isMarker: true,
        type: LOCATION_MARKER,
        mode
      };
      setTarget(newTarget);

      // set UI layout
      if (isMaximized || isMinimized) handleDefaultGivenTarget(newTarget);

      // set focus target on map
      setFocus({
        target: { ...newTarget }
      });

      // update page URL
      convertMapUrl(newTarget);

      // log for analytics
      logMapMarkerSelect(session, { type: LOCATION_MARKER, marker: markerName });
    } else {
      goToPropertyUsingData(marker, marker.properties[0]);
    }
  };

  const goToMarker = (markerName) => {
    // do nothing if already selected this marker
    if (target?.type === LOCATION_MARKER && markerName === target?.id) return;

    // find marker
    const marker = mapData.markers.find(m => m.name === markerName);
    if (!marker) return;

    goToMarkerUsingData(marker);
  };

  const goToBlock = (block) => {
    // set focus target on map
    setFocus({
      target: {
        ...block,
        id: block.address,
        type: LOCATION_BLOCK,
        mode
      },
      links: [target]
    });
  };

  const goToArea = (area) => {
    // set new target
    const newTarget = {
      ...area,
      id: area.id,
      type: LOCATION_AREA,
      mode
    };
    setTarget(newTarget);

    // set UI layout
    if (isMaximized || isMinimized) handleDefaultGivenTarget(newTarget);

    // set focus target on map
    setFocus({
      target: area,
      type: LOCATION_AREA
    });

    // update page URL
    convertMapUrl(null);
  };

  const goToStation = (station) => {
    // check station data
    if (!station) return;

    // set new target
    const newTarget = {
      ...station,
      type: LOCATION_STATION,
      mode
    };
    setTarget(newTarget);

    // set UI layout
    if (isMaximized || isMinimized) handleDefaultGivenTarget(newTarget);
    
    // set focus target on map
    setFocus({
      target: { ...station }
    });

    // update page URL
    convertMapUrl(newTarget);

    // log for analytics
    logMapMarkerSelect(session, { type: LOCATION_STATION, name: station.name });

    // handle guest
    showLoginIfGuest();
  };

  const goToSchool = (school) => {
    // check school data
    if (!school) return;

    // set new target
    const newTarget = {
      ...school,
      type: LOCATION_SCHOOL,
      mode
    };
    setTarget(newTarget);

    // set UI layout
    if (isMaximized || isMinimized) handleDefaultGivenTarget(newTarget);

    // set focus target on map
    setFocus({
      target: { ...school }
    });

    // update page URL
    convertMapUrl(newTarget);

    // log for analytics
    logMapMarkerSelect(session, { type: LOCATION_SCHOOL, name: school.label });

    // handle guest
    showLoginIfGuest();
  };

  const goToUpcoming = (upcoming) => {
    // check upcoming property data
    if (!upcoming) return;

    // set new target
    const newTarget = {
      ...upcoming,
      type: LOCATION_UPCOMING,
      mode
    };
    setTarget(newTarget);

    // set UI layout
    if (isMaximized || isMinimized) handleDefaultGivenTarget(newTarget);

    // set focus target on map
    setFocus({
      target: { ...upcoming }
    });
    
    // update page URL
    convertMapUrl(newTarget);

    // log for analytics
    logMapMarkerSelect(session, { type: LOCATION_UPCOMING, project: upcoming.project, street: upcoming.street });

    // handle guest
    showLoginIfGuest();
  };

  const goToHdbUsingData = (block) => {
    // set new target
    const newTarget = {
      ...block,
      id: block.postal,
      type: LOCATION_PROPERTY,
      mode: MAP_MODE_HDB
    };
    setTarget(newTarget);

    // set UI layout
    if (isMaximized || isMinimized) handleDefaultGivenTarget(newTarget);

    // set focus target on map
    setFocus({
      target: { ...block }
    });

    // update page URL
    convertMapUrl(newTarget);

    // log for analytics
    logMapMarkerSelect(session, { type: LOCATION_HDB, postal: block.postal });

    // handle guest
    showLoginIfGuest();
  };

  const goToHdbBlock = (postal) => {
    // if hdb data not loaded yet, set the target and load data first
    if (!hdbMapData) {
      setLoading(true);
      loadHdbMapData({
        id: postal,
        type: LOCATION_PROPERTY,
        mode: MAP_MODE_HDB
      });
      return;
    }

    // get hdb block data if available
    if (!(postal in hdbMapData.index)) return;
    const block = hdbMapData.blocks[hdbMapData.index[postal]];
    if (!block) return;

    goToHdbUsingData(block);
  };

  /* Search bar interaction */
  const onSearch = (location) => {
    closePropertyDetails();

    if (location.type === LOCATION_PROPERTY) {
      const isHdb = isHdbType(location.subtype);
      if (isHdb) {
        goToHdbBlock(location.postal[0]);
      } else {
        goToProperty(location.marker, location.store);
      }

      // set the mode so that it will use correct map mode to show the property
      setMode(
        isHdb
          ? MAP_MODE_HDB
          : MAP_MODE_CONDO
      );

      trackEvent(`map_search_${isHdb ? 'hdb' : 'private'}_${location.marker}`);
    } else if (location.type === LOCATION_SCHOOL) {
      const school = mapData.schools.find(s => s.id === location.store);
      if (school) goToSchool(school);

      trackEvent(`map_search_school_${location.names[0]}`);
    } else if (location.type === LOCATION_STATION) {
      const station = mapData.stations.find(s => s.id === location.store);
      if (station) goToStation(station);

      trackEvent(`map_search_station_${location.marker}`);
    } else if (location.type === LOCATION_UPCOMING) {
      const upcoming = mapData.upcomings.find(s => s.id === location.store);
      if (upcoming) goToUpcoming(upcoming);

      trackEvent(`map_search_upcoming_${location.marker}`);
    }
  };

  const setFocusOn = (projectName) => {
    const propIndex = mapData?.projectIndex?.[projectName];
    if (propIndex === undefined || propIndex === null) return;
    const prop = mapData.projects[propIndex];
    if (prop) {
      setFocus({ target: prop });
    }
  };

  const getProjectLabelFromCompKey = (compKey) => {
    if (!compKey) return compKey;
    if (mode === MAP_MODE_HDB) {
      const block = hdbMapData.blocks[hdbMapData.index[compKey]];
      return block.name;
    } else {
      const c = compKey.split('_');
      if (c.length !== 2) return compKey;
      const idx = mapData.projectIndex[getProjectKey({ marker: c[0], projectId: c[1] })];
      const project = mapData.projects[idx];
      return project ? getProjectLabel(c[0], project.project) : compKey;
    }
  };

  const addToCompareList = (projKey) => {
    if (projKey in compareList) return;

    if (mode === MAP_MODE_HDB) {
      getJsonFile('vh', projKey, json => {
        const data = decompressHdbSingleCompData(json);
        setCompareList({
          ...compareList,
          [projKey]: data
        });
      }, err => {
        // do nothing - soft fail
      });
    } else {
      const c = projKey.split('_');
      const markerName = c[0];
      const projectId = c[1];
      getJsonFile(`v/${getPropertyFileName(markerName)}`, projectId, json => {
        const data = decompressSingleCompData(json);
        setCompareList({
          ...compareList,
          [projKey]: data
        });
      }, err => {
        // do nothing - soft fail
      });
    }
  };

  const viewComparePro = () => {
    // set new target
    const newTarget = {
      type: LOCATION_COMPARE,
      mode
    };
    setTarget(newTarget);

    // set UI layout
    if (isMaximized || isMinimized) handleDefaultGivenTarget(newTarget);

    // update page URL
    convertMapUrl(newTarget);

    // log for analytics
    logComparePro(session, { selection: Object.keys(compareList).map(projKey => compareList[projKey].d.name) });

    // handle guest
    showLoginIfGuest();
  };

  const getDistFromTarget = (type, prop) => {
    if (type === MAP_MODE_CONDO) {
      const markerName = prop.marker;
      const projectId = prop.id;
      const property = mapData.projects.find(p => p.marker === markerName && p.projectId === projectId);
      if (!property) return 0;
      return calculateDist(property.lat, property.lng, target.lat, target.lng);
    } else if (type === MAP_MODE_HDB) {
      if (!(prop.id in hdbMapData.index)) return 0;
      const block = hdbMapData.blocks[hdbMapData.index[prop.id]];
      return calculateDist(block.lat, block.lng, target.lat, target.lng);
    }
  };

  const onLoggedIn = (user) => {
    // do nothing
  };

  const onLikeProperty = (like, target, detailedData, skip) => {
    if (!isLoggedIn(user)) {
      // guest cannot like
      setShowLogin(true);
      return;
    }

    // generate id
    let id = '';
    if (target.projectId) {
      // handle private
      id = `${getPropertyFileName(target.marker)}_${target.projectId}`;
    } else {
      // handle hdb
      id = target.postal;
    }

    // load for initial state only
    if (skip) {
      setLikedProps({
        ...likedProps,
        [id]: like
      });
      return;
    }

    // check if this has been spammed
    if (`clicked-${id}` in likedProps && likedProps[`clicked-${id}`] > 5) {
      // prevent any more spams
      return;
    }

    // on like
    if (like) {
      if (target.projectId) {
        // handle private
        const data = {
          marker: target.marker,
          name: target.project,
          street: `${detailedData.streets[0]}${detailedData.streets.length > 1 ? ` +${detailedData.streets.length - 1}` : ''}`,
          types: convertPropertyTypeToInt(target.types),
        };
        if (target.sharePlaceId) {
          data.img = target.sharePlaceId;
        }
        likeProperty(id, data);
      } else {
        // handle hdb
        const data = {
          name: target.name,
          street: target.street,
          postal: target.postal
        };
        if (target.sharePlaceId) {
          data.img = target.sharePlaceId;
        }
        likeProperty(id, data);
      }
    } else {
      // on un-like
      unlikeProperty(id);
    }

    setLikedProps({
      ...likedProps,
      [id]: like,
      [`clicked-${id}`]: (likedProps[`clicked-${id}`] ?? 0) + 1
    });
  };

  const onUnlikeProperty = (id) => {
    setLikedProps({
      ...likedProps,
      [id]: false
    });
  };

  const showLoginIfGuest = () => {
    if (!isLoggedIn(user)) {
      setShowLogin(true);
    }
  };

  const onCompareSearch = () => {
    showLoginIfGuest();
  };

  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="header-margin"></div>
      <DefaultHeader
        user={user}
        session={session}
        goToProperty={(marker, id) => {
          closePropertyDetails();
          setMode(MAP_MODE_CONDO);
          goToProperty(marker, id);
        }}
        goToHdb={(postal) => {
          closePropertyDetails();
          setMode(MAP_MODE_HDB);
          goToHdbBlock(postal);
        }}
        onUnlikeProperty={onUnlikeProperty}
        isMap
      />

      {/* loading screen */}
      {loading && <div className="loader-container"><Loader /></div>}

      {/* error screen */}
      {err && <ErrorContent />}

      {!loading && !err
        && <>
          {/* search bar */}
          <div className="py-10 bg-dark-2">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <FilterBox
                    onSearch={onSearch}
                    hasSearchResults={setHasSearchResults}
                    allowModeSwitch={allowModeSwitch}
                  />
                </div>
              </div>
            </div>
          </div>

          <section className={noSelectClass(user)}>
            <div className="proj-bg-map">
              <MainMap
                user={user}
                target={target}
                focus={focus}
                setFocus={setFocus}
                mapData={mapData}
                goToProperty={goToProperty}
                goToMarker={goToMarker}
                goToArea={goToArea}
                goToStation={goToStation}
                goToSchool={goToSchool}
                goToUpcoming={goToUpcoming}
                goToHdbBlock={goToHdbBlock}
                isMinimized={isMinimized}
                isMaximized={isMaximized}
                setLoading={setLoading}
                setErr={setErr}
                closePropertyDetails={closePropertyDetails}
                loadAdvMapData={mode === MAP_MODE_HDB ? loadHdbAdvMapData : loadAdvMapData}
                hasAdvMapData={hasAdvMapData}
                filterSelected={filterSelected}
                setFilterSelected={setFilterSelected}
                compareList={compareList}
                viewComparePro={viewComparePro}
                hasSearchResults={hasSearchResults}
                userConfig={userConfig}
                setProfitableColorScheme={setProfitableColorScheme}
                gpsLocation={gpsLocation}
                fetchGpsLocation={fetchGpsLocation}
                mode={mode}
                setMode={mode => {
                  // reset all filters and states
                  setTarget(null);
                  setFocus(null);
                  setFilterSelected(initializeFilters(MAP_FILTER_OPTIONS, new URLSearchParams()));

                  // change mode
                  setMode(mode);
                }}
                hdbMapData={hdbMapData}
                allowModeSwitch={allowModeSwitch}
                incomingFilter={incomingFilter}
                actionSignal={actionSignal}
                setActionSignal={setActionSignal}
              />

              {target?.type === LOCATION_PROPERTY
                && <PropertyView
                  user={user}
                  session={session}
                  target={target}
                  focus={focus}
                  setFocus={setFocus}
                  setFocusOn={setFocusOn}
                  isMinimized={isMinimized}
                  isMaximized={isMaximized}
                  handleExpand={handleDefault}
                  handleMaximize={handleMaximize}
                  closePropertyDetails={closePropertyDetails}
                  goToProperty={goToProperty}
                  goToHdbBlock={goToHdbBlock}
                  screenDim={screenDim}
                  compareList={compareList}
                  setCompareList={setCompareList}
                  addToCompareList={addToCompareList}
                  mapData={mapData.projects[mapData.projectIndex[target.id]]}
                  viewComparePro={viewComparePro}
                  getProjectLabel={getProjectLabelFromCompKey}
                  userConfig={userConfig}
                  setLightboxImages={setLightboxImages}
                  onLikeProperty={onLikeProperty}
                  likedProps={likedProps}
                  // onSwitchPropertyTab={showLoginIfGuest}
                  onCompareSearch={onCompareSearch}
                  getDistFromTarget={getDistFromTarget}
                />
              }

              {(target?.type === LOCATION_AREA || target?.type === LOCATION_MARKER)
                && <AreaView
                  user={user}
                  mode={mode}
                  session={session}
                  target={target}
                  isMinimized={isMinimized}
                  isMaximized={isMaximized}
                  handleExpand={handleDefault}
                  handleMaximize={handleMaximize}
                  closePropertyDetails={closePropertyDetails}
                  goToProperty={goToProperty}
                  goToHdbBlock={goToHdbBlock}
                  userConfig={userConfig}
                />
              }

              {(target?.type === LOCATION_STATION || target?.type === LOCATION_SCHOOL)
                && <AmenityView
                  user={user}
                  userConfig={userConfig}
                  session={session}
                  mapData={mapData}
                  target={target}
                  setTarget={setTarget}
                  isMinimized={isMinimized}
                  isMaximized={isMaximized}
                  handleExpand={handleDefault}
                  handleMaximize={handleMaximize}
                  closePropertyDetails={closePropertyDetails}
                  goToProperty={goToProperty}
                  goToHdbBlock={goToHdbBlock}
                  goToBlock={goToBlock}
                  mode={mode}
                  setMode={setMode}
                  filterSelected={filterSelected}
                />
              }

              {target?.type === LOCATION_UPCOMING
                && <UpcomingView
                  user={user}
                  session={session}
                  target={target}
                  isMinimized={isMinimized}
                  isMaximized={isMaximized}
                  handleExpand={handleDefault}
                  handleMaximize={handleMaximize}
                  closePropertyDetails={closePropertyDetails}
                />
              }

              {target?.type === LOCATION_COMPARE
                && <CompareProView
                  user={user}
                  target={target}
                  screenDim={screenDim}
                  isMaximized={isMaximized}
                  isMinimized={isMinimized}
                  handleExpand={handleDefault}
                  handleMaximize={handleMaximize}
                  goToProperty={goToProperty}
                  setFocusOn={setFocusOn}
                  compareList={compareList}
                  setCompareList={setCompareList}
                  closeDetails={closePropertyDetails}
                  getProjectLabel={getProjectLabelFromCompKey}
                  onCompareSearch={onCompareSearch}
                />
              }
            </div>
          </section>

          <Lightbox
            open={!!lightboxImages}
            close={() => setLightboxImages(null)}
            slides={lightboxImages?.gallery ?? []}
            index={lightboxImages?.idx ?? 0}
            portal={{ target: document.body }}
            plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
          />
        </>
      }

      {allowBot
        && <BotPanel
          hidden={!!target}
          onGoToCondo={(marker, id) => {
            setMode(MAP_MODE_CONDO);
            goToProperty(marker, id);
          }}
          onGoToLanded={(marker, id) => {
            setMode(MAP_MODE_CONDO);
            goToProperty(marker, id);
          }}
          onGoToHdb={postal => {
            setMode(MAP_MODE_HDB);
            goToHdbBlock(postal);
          }}
          onGoToCondoMap={(tenure) => {
            setMode(MAP_MODE_CONDO);
            const filter = {
              prop_type: {
                'Apt/Condo': true,
                'Executive Condominium': true
              }
            };
            if (tenure) {
              filter.tenure = {};
              tenure.forEach(t => filter.tenure[t] = true);
            }
            setIncomingFilter({
              data: mapData.projects,
              filter,
              mode: MAP_MODE_CONDO
            });
          }}
          onGoToLandedMap={(tenure) => {
            setMode(MAP_MODE_CONDO);
            const filter = {
              prop_type: {
                'Detached House': true,
                'Semi-Detached House': true,
                'Terrace House': true
              }
            };
            if (tenure) {
              filter.tenure = {};
              tenure.forEach(t => filter.tenure[t] = true);
            }
            setIncomingFilter({
              data: mapData.projects,
              filter,
              mode: MAP_MODE_CONDO
            });
          }}
          onGoToHdbMap={(flatTypes) => {
            setMode(MAP_MODE_HDB);
            const filter = {
              mode: MAP_MODE_HDB
            };
            if (flatTypes) {
              filter.flat_type = {};
              flatTypes.forEach(t => filter.flat_type[t] = true);
            }
            if (!hdbMapData) {
              loadHdbMapData(null, (hdbMapData) => {
                setIncomingFilter({
                  data: hdbMapData.blocks,
                  filter,
                  mode: MAP_MODE_HDB
                });
              });
            } else {
              setIncomingFilter({
                data: hdbMapData.blocks,
                filter,
                mode: MAP_MODE_HDB
              });
            }
          }}
          onRestart={() => {
            setActionSignal('resetFilter');
          }}
        />
      }

      {user !== null && !isLoggedIn(user)
        && <LoginPopup
          session={session}
          show={showLogin}
          setShow={setShowLogin}
          onLoggedIn={onLoggedIn}
        />
      }

    </>
  );
};

export default HomeMap;
