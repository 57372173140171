import {
  checkMediaQuery,
  noSelectClass
} from "@/utils/user";
import ScrollToTop from "../project/ScrollToTop";
import AmenityTable from "./property/Table/AmenityTable";
import { useEffect, useState } from "react";
import {
  calculateDist,
  formatDistanceLabel
} from "@/utils/convert";
import { getFile, trackEvent, trackPageView } from "@/utils/api";
import {
  MAP_MODE_CONDO,
  MAP_MODE_HDB,
  PROP_TYPES_FILTER_OPTIONS,
  decompressAmenityData,
  decompressHdbAmenityData,
  getProjectKey,
  getPropertyIconByType
} from "@/utils/map";
import Loader from "../common/Loader";
import {
  LOCATION_SCHOOL,
  getBlockUnitFromAddress
} from "@/utils/areas";
import Checkbox from "../common/sidebar/Checkbox";
import MaxDistInput from "./filter/MaxDistInput";
import { convertMapUrl } from "@/utils/url";

const DEFAULT_PROP_TYPES = { land: true, nonland: true };

const DEFAULT_MAX_DIST = 1000;

const AmenityView = ({
  user,
  userConfig,
  target,
  setTarget,
  mapData,
  isMinimized,
  isMaximized,
  handleMaximize,
  handleExpand,
  closePropertyDetails,
  goToProperty,
  goToBlock,
  goToHdbBlock,
  setMode,
  filterSelected
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  
  // filters
  const [filterPropTypes, setFilterPropTypes] = useState(DEFAULT_PROP_TYPES);
  const [filterMaxDist, setFilterMaxDist] = useState(DEFAULT_MAX_DIST);

  useEffect(() => {
    setLoading(true);

    const foldername = target.mode === MAP_MODE_HDB
      ? (target.type === LOCATION_SCHOOL ? 'sh' : 'th')
      : (target.type === LOCATION_SCHOOL ? 's' : 't');

    getFile(foldername, target.id, txt => {
      if (target.mode === MAP_MODE_HDB) {
        const data = decompressHdbAmenityData(target.type, txt);

        setData(
          data
            .map(r => ({
              ...r,
              distance: calculateDist(target.lat, target.lng, r.lat, r.lng)
            }))
            .filter(r => r.distance <= DEFAULT_MAX_DIST)
            .sort((a, b) => a.distance - b.distance)
        );
      } else {
        const data = decompressAmenityData(target.type, txt);

        // calculate distance if not provided in the data
        // also do a filter again just to make sure no anomalous distance exceeding limit is displayed
        setData(
          data
            .map(r => ({
              ...r,
              distance: r.distance !== undefined
                ? r.distance
                : calculateDist(target.lat, target.lng, r.lat, r.lng),
              key: getProjectKey(r)
            }))
            .filter(r => r.distance <= DEFAULT_MAX_DIST && r.key in mapData.projectIndex)
            .sort((a, b) => a.distance - b.distance)
            .map(r => {
              const propData = mapData.projects[mapData.projectIndex[r.key]];
              if (!propData) return null;
              const iconType = getPropertyIconByType(propData.types);
              return {
                ...r,
                // profitable: propData.profitable,
                types: propData.types,
                landed: iconType === 'mixed' || iconType === 'house',
                nonlanded: iconType === 'condo' || iconType === 'flat' || iconType === 'mixed'
              };
            })
            .filter(r => !!r)
        );
      }

      setLoading(false);
    }, err => {
      setLoading(false);
    });
  }, [target]);

  useEffect(() => {
    trackPageView(`amenity_detail_${target.type === LOCATION_SCHOOL ? 'school' : 'station'}`, {
      type: target.type,
      [target.type === LOCATION_SCHOOL ? 'school' : 'station']: target.id
    });
  }, []);

  const filterProps = (r) => {
    // filter by property type
    if (target.mode !== MAP_MODE_HDB) { 
      if (filterPropTypes.land && !filterPropTypes.nonland && !r.landed) return false;
      if (filterPropTypes.nonland && !filterPropTypes.land && !r.nonlanded) return false;
    }

    // filter by max distance
    if (r.distance > filterMaxDist) return false;

    return true;
  };

  const mediaMatches = checkMediaQuery();

  const goToPropertyFromRow = (data) => {
    const idx = data.cell.row.index;
    if (target.mode === MAP_MODE_HDB) {
      goToHdbBlock(data.data[idx].postal);
    } else {
      goToProperty(data.data[idx].marker, data.data[idx].projectId);
    }
  };

  const goToBlockFromRow = (data) => {
    const idx = data.cell.row.index;
    let block = getBlockUnitFromAddress(data.data[idx].address);
    const match = block.block.match(/^\S+/);
    block = match ? match[0] : block.block;
    goToBlock({
      ...data.data[idx],
      block,
    });
  };

  const getDisplayLabel = (target) => {
    if (target.name) {
      return target.name;
    }
    if (target.names) {
      const names = target.names.filter(n => !n.includes('MOE KINDERGARTEN'));
      if (names.length > 0) {
        return names[0];
      }
    }
    return '';
  };

  const getLabelHint = (label) => {
    if (!label) return [];
    return label.split(',');
  };

  return (
    <>
      {loading
        && <div className="loader-container">
          <Loader />
        </div>
      }

      {!isMinimized && !loading && (
        <div className={`modal-container ${
          isMaximized ? "modal-full modal-container-fixed" : (
            mediaMatches ? "" : "modal-container-fixed"
          )
        }`}>

          {mediaMatches
            && <div className="d-flex justify-content-center text-10 text-light-1">
              <div className="p-2 py-0"></div>
              <div
                className={`p-2 py-0 mb-10 cursor-pointer ${
                  isMaximized ? '' : 'rotate-180'
                }`}
                onClick={() => isMaximized ? handleExpand() : handleMaximize()}
              >
                <i className="icon-chevron-sm-down py-5" />
              </div>
            </div>
          }

          <div className="d-flex align-items-center justify-content-start py-0 mt-15 mb-5 modal-menu noselect">
            <i className="p-2 icon-location-2 text-18" />
            <h3
              className="p-2 text-15 fw-500 flex-grow-1 text-truncate"
              data-tooltip-id="map-tooltip"
              data-tooltip-html={`
                <div class="map-tooltip-content">
                  ${getLabelHint(target.name ?? target.names?.join(',')).join('<br />')}
                </div>
              `}
              data-tooltip-variant="dark"
              data-tooltip-place="bottom"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {getDisplayLabel(target)}
            </h3>

            <button
              className="p-2 button h-30 px-10 text-16"
              onClick={() => closePropertyDetails()}
            >
              <i className="icon-close py-5" />
            </button>
          </div>

          {(!data || data.length === 0)
            && <div className="modal-body watermark d-flex">
              {!mediaMatches
                && <div
                  className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                  onClick={() => isMaximized ? handleExpand() : handleMaximize()}
                >
                  <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                    <i className="icon-chevron-sm-down px-5" />
                  </div>
                </div>
              }

              <div
                id="project-details-modal"
                className={`p-2 modal-scoll-content text-center fw-500 py-40 ${
                  isMaximized ? 'modal-scroll-am-full' : (mediaMatches ? 'modal-scroll-am' : 'modal-scoll-content-am')
                }`}
              >
                {target.name ?? target.names.join(', ') ?? target.label}
              </div>
            </div>
          }

          {data && data.length > 0
            && <div className="modal-body watermark d-flex">
              {!mediaMatches
                && <div
                  className="p-2 modal-expand button text-10 px-0 d-flex align-items-center cursor-pointer"
                  onClick={() => isMaximized ? handleExpand() : handleMaximize()}
                >
                  <div className={isMaximized ? 'rotate-270' : 'rotate-90'}>
                    <i className="icon-chevron-sm-down px-5" />
                  </div>
                </div>
              }

              <div
                id="project-details-modal"
                className={`p-2 modal-scoll-content ${
                  isMaximized ? 'modal-scroll-am-full' : (mediaMatches ? 'modal-scroll-am' : 'modal-scoll-content-am')
                }`}
              >

                <ScrollToTop />
              
                <section className={`modal-table${isMaximized ? '-full' : ''}`}>
                  <div className={`data-table ${noSelectClass(user)}`}>

                    <div className="d-flex noselect mt-10">
                      {target.mode !== MAP_MODE_HDB
                        && <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad">
                          <div
                            className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-10 py-5 text-12 lh-12 "
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="true"
                            aria-expanded="false"
                            data-bs-offset="0,10"
                            data-tooltip-id="map-tooltip"
                            data-tooltip-html={`
                              <div class="map-tooltip-content">
                              <span class="fw-500">Filter by Property Type</span>
                              <br />
                              <span class="map-tooltip-hint">This filter overrides the map's filter</span>
                              </div>
                            `}
                            data-tooltip-variant="dark"
                            data-tooltip-place="bottom"
                          >
                            <span className="js-dropdown-title text-12">{
                              filterPropTypes.land && filterPropTypes.nonland
                                ? 'All property types'
                                : (
                                  filterPropTypes.land
                                    ? 'Landed only'
                                    : 'Condo/EC only'
                                )
                            }</span>
                            <i className="icon icon-chevron-sm-down text-7 ml-5" />
                          </div>
                          <div className="toggle-element -dropdown dropdown-menu overlay-menu">
                            <div className="text-12 y-gap-5 js-dropdown-list">
                              {PROP_TYPES_FILTER_OPTIONS.map(option => (
                                <div key={option.id}>
                                  <Checkbox
                                    label={option.label}
                                    value={filterPropTypes[option.id]}
                                    setValue={v => {
                                      let newFilterPropTypes = { ...filterPropTypes };
                                      newFilterPropTypes[option.id] = v;
                                      if (PROP_TYPES_FILTER_OPTIONS.every(opt => !newFilterPropTypes[opt.id])) {
                                        newFilterPropTypes = DEFAULT_PROP_TYPES;
                                      }
                                      setFilterPropTypes(newFilterPropTypes);
                                    }}
                                    textSize="14"
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      }

                      <div className="p-2 dropdown js-dropdown js-category-active heatmap-selector-menu map-mode-selector proj-map-spad ml-5">
                        <div
                          className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-10 py-5 text-12 lh-12 "
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="true"
                          aria-expanded="false"
                          data-bs-offset="0,10"
                          data-tooltip-id="map-tooltip"
                          data-tooltip-html={`
                            <div class="map-tooltip-content">
                            <span class="fw-500">Filter by Distance</span>
                            <br />
                            <span class="map-tooltip-hint">Set the maximum distance of property to filter</span>
                            </div>
                          `}
                          data-tooltip-variant="dark"
                          data-tooltip-place="bottom"
                        >
                          <span className="js-dropdown-title text-12">Max distance: {formatDistanceLabel(filterMaxDist)}</span>
                          <i className="icon icon-chevron-sm-down text-7 ml-5" />
                        </div>
                        <div className="toggle-element -dropdown dropdown-menu overlay-menu">
                          <div className="text-12 y-gap-5 js-dropdown-list">
                            <MaxDistInput
                              defaultDist={filterMaxDist}
                              setMaxDist={setFilterMaxDist}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="p-2 flex-grow-1"></div>

                      <button
                        className="p-2 button -dark-1 bg-blue-1 text-white h-30 px-10 rounded-100 text-12 mt-5"
                        onClick={() => {
                          const mode = target.mode === MAP_MODE_HDB ? MAP_MODE_CONDO : MAP_MODE_HDB;
                          setMode(mode);
                          const newTarget = {
                            ...target,
                            mode
                          };
                          setTarget(newTarget);
                          setTimeout(() => {
                            convertMapUrl(newTarget);
                          }, 600);
                          trackEvent(`amenity_detail_switch_${target.mode === MAP_MODE_HDB ? 'private' : 'hdb'}`);
                        }}
                      >
                        {target.mode === MAP_MODE_HDB ? 'See Private' : 'See HDB'}
                      </button>
                    </div>
                    
                    <AmenityTable
                      data={data.filter(filterProps)}
                      userConfig={userConfig}
                      goToProperty={goToPropertyFromRow}
                      goToBlock={goToBlockFromRow}
                      mode={target.mode}
                    />
                  </div>
                </section>
              </div>
            </div>
          }
        </div>
      )}
    </>
  );
};

export default AmenityView;
