import { useEffect, useRef, useState } from "react";
import LikedPropertyRow from "./LikedPropertyRow";
import Loader from "../common/Loader";
import { getPropertyLikes, unlikeProperty } from "@/utils/api";
import { useNavigate } from "react-router-dom";


const LikedProperties = ({
  user,
  onViewProperty,
  onUnlikeProperty,
  isMap
}) => {
  const PAGE_LIMIT = 20;
  const containerRef = useRef(null);
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(0);
  const [loadIndex, setLoadIndex] = useState(1000);
  const [isFetching, setIsFetching] = useState(false);

  const loadProperties = (showLoader) => {
    setIsFetching(true);
    if (showLoader) {
      setLoading(true);
    }

    getPropertyLikes(user, v => {
      if (v.length < PAGE_LIMIT) {
        setOffset(-1);  // disable load next page
      } else {
        setOffset(offset + PAGE_LIMIT);
      }
      
      const newData = v.map(d => ({
        ...JSON.parse(d.data),
        id: d.pid
      }));
      
      const updatedData = [...(data ?? []), ...newData];
      setData(updatedData);
      const expectedLoadIndex = updatedData.length - parseInt((PAGE_LIMIT * 0.5));
      setLoadIndex(expectedLoadIndex > 1 ? expectedLoadIndex : 1000);

      if (showLoader) {
        setLoading(false);
      }
      setIsFetching(false);
    }, PAGE_LIMIT, offset);
  };

  useEffect(() => {
    loadProperties(true);
  }, []);

  const onListScroll = () => {
    if (containerRef.current && data.length > 0) {
      const scrollTop = containerRef.current.scrollTop;
      const childHeight = containerRef.current.children[0].offsetHeight;
      const index = Math.floor(scrollTop / childHeight);

      // start loading next page if hit the load index
      if (offset > 0 && index >= loadIndex && !isFetching) {
        loadProperties();
      }
    }
  };

  const onUnlike = (id) => {
    unlikeProperty(id);
    setData(data.filter(d => d.id !== id));
    onUnlikeProperty?.(data);
  };

  return (
    <div
      className="like-props"
      ref={containerRef}
      onScroll={onListScroll}
    >
      {loading
        && <div className="loader-container-sm">
          <Loader />
        </div>
      }

      {!loading
        && data?.map((row, i) => (
          <LikedPropertyRow
            idx={i}
            data={row}
            onView={data => {
              if (isMap) {
                onViewProperty(data);
              } else {
                if (!data.types) {
                  navigate(`/map?mode=h&id=${data.postal}`);
                } else {
                  const c = data.id.split('_');
                  navigate(`/map?mode=c&id=${data.marker}&p=${c[c.length - 1]}`);
                }
              }
            }}
            onUnlike={onUnlike}
          />
        ))
      }

      {!loading
        && data?.length === 0
        && <div className="text-center text-12 py-20">
            You have not liked any properties yet
          </div>
      }
    </div>
  );
};

export default LikedProperties;
