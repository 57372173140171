import {
  FILTER_DATE,
  FILTER_FULL_SALE_TYPE,
  FILTER_PRICE,
  FILTER_SIZE,
  FILTER_TYPE_CHECKBOXES,
  FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_MULTI_RANGE,
  FILTER_UNIT_PRICE
} from "@/utils/filter";
import {
  checkMediaQuery,
  noSelectClass
} from "@/utils/user";
import { useEffect, useState } from "react";
import Sidebar from "@/components/common/Sidebar";
import Loader from "@/components/common/Loader";
import {
  convertDisplayDateMsec,
  dateStrToMsec
} from "@/utils/time";
import { getBlueHue } from "@/utils/colors";
import { NA } from "@/utils/convert";
import {
  calculateAnnualized,
  getBlockUnitFromAddress,
  getHdbUnitFromTransaction
} from "@/utils/areas";
import { HDB_TYPES_INTS, MAP_MODE_CONDO, MAP_MODE_HDB, censorUnit } from "@/utils/map";
import { Tooltip } from "react-tooltip";

const FILTER_DATA_OPTIONS = [
  FILTER_DATE,
  FILTER_FULL_SALE_TYPE,
  FILTER_PRICE,
  FILTER_UNIT_PRICE,
  FILTER_SIZE,
];

const FILTER_DATA_HDB_OPTIONS = [
  FILTER_DATE,
  FILTER_PRICE,
  FILTER_UNIT_PRICE,
  FILTER_SIZE,
];

const GRADIENT_OPTIONS = [
  {
    label: 'Price per area',
    value: 'Psf',
  },
  {
    label: 'Price',
    value: 'Price',
  },
  {
    label: 'Unit Size',
    value: 'Area',
  },
  {
    label: 'Recent Transactions',
    value: 'Date',
    // reverseColor: true,
  },
];

const ZOOM_OPTIONS = [
  { class: 'sm', size: '10' },
  { class: 'md', size: '10' },
  { class: 'lg', size: '12' },
  { class: 'xl', size: '14' },
  { class: 'xxl', size: '16' },
];

const getParamValue = (params, field) => !params ? null : params.get(field);

const initFilterFromParams = (params, options) => {
  const filters = {};
  options.forEach(option => {
    if (option.type === FILTER_TYPE_DATE_RANGE) {
      const startDate = getParamValue(params, option.field[0]);
      if (startDate) filters[option.field[0]] = new Date(parseInt(startDate));
      const endDate = getParamValue(params, option.field[1]);
      if (endDate) filters[option.field[1]] = new Date(parseInt(endDate));
    } else if (option.type === FILTER_TYPE_CHECKBOXES) {
      const values = getParamValue(params, option.field);
      if (values) {
        const filterValue = {};
        values.split(',').forEach(v => {
          filterValue[v] = true;
        });
        filters[option.field] = filterValue;
      }
    } else if (option.type === FILTER_TYPE_MULTI_RANGE) {
      const min = getParamValue(params, option.field[0]);
      if (min !== null) filters[option.field[0]] = min;
      const max = getParamValue(params, option.field[1]);
      if (max !== null) filters[option.field[1]] = max;
    }
  });
  return filters;
};

const UnitsTab = ({
  user,
  data,
  isMaximized,
  setCellDetails,
  setCellDetailsLoading,
  defaultBlock,
  target
}) => {
  const isHdb = target.mode === MAP_MODE_HDB;
  const filterOptions = isHdb ? FILTER_DATA_HDB_OPTIONS : FILTER_DATA_OPTIONS;

  // filters
  const [filterSelected, setFilterSelected] = useState(initFilterFromParams(null, filterOptions));
  const [appliedFilter, setAppliedFilter] = useState(initFilterFromParams(null, filterOptions));
  const [defaultOrder, setDefaultOrder] = useState(true);

  const [dataLoading, setDataLoading] = useState(true);
  const [results, setResults] = useState({});
  const [resultsMeta, setResultsMeta] = useState({});
  const [tableData, setTableData] = useState(null);
  const [blocks, setBlocks] = useState(null);
  const [displayBlock, setDisplayBlock] = useState(null);
  const [gradient, setGradient] = useState(GRADIENT_OPTIONS[0].value);
  const [zoom, setZoom] = useState(ZOOM_OPTIONS.length - 4);

  const mediaMatches = checkMediaQuery();

  const getBlock = (address) => {
    const match = address.match(/^\S+/);
    return match ? match[0] : address;
  };

  const saveResults = (results) => {
    if (!results) return;
    
    const blocks = new Set();
    let left = {};
    let right = {};
    let top = {};
    let bottom = {};
    let minPrice = Number.POSITIVE_INFINITY;
    let maxPrice = Number.NEGATIVE_INFINITY;
    let minPsf = Number.POSITIVE_INFINITY;
    let maxPsf = Number.NEGATIVE_INFINITY;
    let minArea = Number.POSITIVE_INFINITY;
    let maxArea = Number.NEGATIVE_INFINITY;
    let minDate = Number.POSITIVE_INFINITY;
    let maxDate = Number.NEGATIVE_INFINITY;

    // extract unit number
    // find the left/right-most units for rendering
    const updatedResults = results.map(r => {
      const blockUnit = isHdb
        ? getHdbUnitFromTransaction(target, r)
        : getBlockUnitFromAddress(r.address);
      if (!blockUnit) return null;
      const unit = blockUnit.unit;
      const block = blockUnit.block;
      if (!(block in left)) left[block] = Number.POSITIVE_INFINITY;
      if (blockUnit.house < left[block]) left[block] = blockUnit.house;
      if (!(block in right)) right[block] = Number.NEGATIVE_INFINITY;
      if (blockUnit.house > right[block]) right[block] = blockUnit.house;
      if (!(block in top)) top[block] = Number.NEGATIVE_INFINITY;
      if (blockUnit.floor > top[block]) top[block] = blockUnit.floor;
      if (!(block in bottom)) bottom[block] = Number.POSITIVE_INFINITY;
      if (blockUnit.floor < bottom[block]) bottom[block] = blockUnit.floor;
      if (!blocks.has(block)) blocks.add(block);
      if (r.price < minPrice) minPrice = r.price;
      if (r.price > maxPrice) maxPrice = r.price;
      if (r.area < minArea) minArea = r.area;
      if (r.area > maxArea) maxArea = r.area;
      if (r.unitPrice < minPsf) minPsf = r.unitPrice;
      if (r.unitPrice > maxPsf) maxPsf = r.unitPrice;
      const ts = dateStrToMsec(r.saleDate);
      if (ts < minDate) minDate = ts;
      if (ts > maxDate) maxDate = ts;
      return {
        ...r,
        block,
        unit,
        floor: blockUnit.floor,
        house: blockUnit.house,
        ts,
      };
    }).filter(r => !!r);

    // if no proper units found, no results
    if (updatedResults.length === 0) return;

    // get list of blocks
    const blockList = Array.from(blocks)
      .sort((a, b) => {
        const matchA = a.match(/^(\d+)?(\D*)/);
        const matchB = b.match(/^(\d+)?(\D*)/);
        const numA = matchA[1] ? parseInt(matchA[1], 10) : null;
        const numB = matchB[1] ? parseInt(matchB[1], 10) : null;
        if (numA !== null && numB !== null) {
          if (numA !== numB) return numA - numB;
        } else if (numA !== null) {
          return -1;
        } else if (numB !== null) {
          return 1;
        }
        return a.localeCompare(b);
      })
      .map(block => ({ label: block, value: block }));

    // if no block found, no results
    if (blockList.length === 0) return;
    
    // generate the individual unit data
    const data = {};
    blockList.forEach(block => {
      data[block.value] = [];
      for (let i = bottom[block.value]; i <= top[block.value]; i += 1) {
        const row = Array(right[block.value] - left[block.value] + 1).fill(null);
        data[block.value].push(row);
      }
    });

    updatedResults.forEach(tx => {
      const floor = tx.floor;
      const house = tx.house;
      const block = tx.block;
      if (data[block][floor - bottom[block]][house - left[block]] === null) {
        data[block][floor - bottom[block]][house - left[block]] = {
          Date: tx['ts'],
          Price: tx.price,
          Area: tx.area,
          Psf: tx.unitPrice,
          sale: tx.typ,
          unit: tx['unit'],
          tx: [],
        };
      } else if (data[block][floor - bottom[block]][house - left[block]].ts < tx['ts']) {
        data[block][floor - bottom[block]][house - left[block]].ts = tx['ts'];
        data[block][floor - bottom[block]][house - left[block]].price = tx.price;
        data[block][floor - bottom[block]][house - left[block]].area = tx.area;
        data[block][floor - bottom[block]][house - left[block]].unit_price = tx.unitPrice;
        data[block][floor - bottom[block]][house - left[block]].sale = tx.type;
      }
      data[block][floor - bottom[block]][house - left[block]].tx.push(tx);
    });

    // set the default block to show
    let defaultDisplayBlock = blockList[0].value;
    if (blockList.filter(b => b.value === defaultBlock).length > 0) defaultDisplayBlock = defaultBlock;
    
    // change the default block to the next one that has > 1 units
    let firstDisplayBlock = defaultDisplayBlock;
    for (let i = 0; i < blockList.length; i++) {
      const block = blockList[i].value;
      if (data[block].length > 1 || data[block][0].length > 1) {
        firstDisplayBlock = block;
        break;
      }
    }

    // additional processing required for hdb since they are in storey range instead of individual storeys
    if (isHdb) {
      // remove floors without any data (assume that only valid floors will have data)
      Object.keys(data).forEach(block => {
        data[block] = data[block].filter(floor => floor.some(row => row !== null));
      });
    }

    // save results
    setBlocks(blockList);
    setDisplayBlock(firstDisplayBlock);
    setResults(data);
    setResultsMeta({
      left,
      right,
      top,
      bottom,
      minPrice,
      maxPrice,
      minPsf,
      maxPsf,
      minArea,
      maxArea,
      minDate,
      maxDate,
    });
  };

  useEffect(() => {
    saveResults(data.transactions);
  }, [data]);

  const selectCell = (unit) => {
    setCellDetailsLoading(true);
    setCellDetails({
      ...unit,
      displayBlock,
      mode: isHdb ? MAP_MODE_HDB : MAP_MODE_CONDO,
      gains: unit.tx?.length > 0
        ? unit.tx.map((tx, i) => {
            const gain = calculateAnnualized(i, unit.tx);
            return gain !== null ? {
              ts: tx['ts'],
              gain,  
            } : null
          })
        : []
    });
    setCellDetailsLoading(false);
  };

  const onFilterApply = () => {
    setAppliedFilter(filterSelected);
    renderTable(filterSelected, defaultOrder);

    if (document.getElementById('listingSidebar').classList.contains('show')) {
      document.getElementById('filter-close-button').click();
    }
  };

  const onFilterReset = () => {
    setFilterSelected({});
    setAppliedFilter({});
    renderTable({}, defaultOrder);

    if (document.getElementById('listingSidebar').classList.contains('show')) {
      document.getElementById('filter-close-button').click();
    }
  };

  const zoomIn = () => {
    setZoom(zoom - 1);
  };

  const zoomOut = () => {
    setZoom(zoom + 1);
  };

  const getHdbStoreyLabel = (units) => {
    for (let i = 0; i < units.length; i++) {
      if (units[i] !== null) {
        return units[i].tx[0].storeyRange;
      }
    }
    return '';
  };

  const getHdbUnitLabel = (idx) => {
    return HDB_TYPES_INTS[idx - 1];
  };

  const getRowPsfLabel = (agg, idx) => {
    const total = agg[idx].reduce((s, c) => s + (c ?? 0), 0);
    if (total === 0) return NA;
    return (total / agg[idx].reduce((s, c) => s + (c ? 1 : 0), 0)).toFixed(0);
  };

  const getColPsfLabel = (agg, idx) => {
    const total = agg.map(r => r[idx]).reduce((s, c) => s + (c ?? 0), 0);
    if (total === 0) return NA;
    return (total / agg.map(r => r[idx]).reduce((s, c) => s + (c ? 1 : 0), 0)).toFixed(0);
  };

  const renderTable = (filterSelected, defaultOrder) => {
    if (results && Object.keys(results).length === 0) return;
    setDataLoading(true);

    // filter by block
    let filteredResults = results[displayBlock];
    
    // filter by selection
    const aggPsf = [];
    filteredResults = filteredResults.map((row, i) => {
      aggPsf.push([]);
      return row.map((col, j) => {
        aggPsf[i].push(null);
        if (!col) return null;
        const tx = col.tx.filter(t => {
          if (filterSelected.start_date && t['ts'] < filterSelected.start_date.getTime())
            return false;
          if (filterSelected.end_date && t['ts'] > filterSelected.end_date.getTime())
            return false;
          if (filterSelected.sale_type && Object.entries(filterSelected.sale_type).some(e => !!e[1]) && !filterSelected.sale_type[t.type])
            return false;
          if (filterSelected.min_price && t.price < parseInt(filterSelected.min_price))
            return false;
          if (filterSelected.max_price && t.price > parseInt(filterSelected.max_price))
            return false;
          if (filterSelected.min_unit_price && t.unitPrice < parseFloat(filterSelected.min_unit_price))
            return false;
          if (filterSelected.max_unit_price && t.unitPrice > parseFloat(filterSelected.max_unit_price))
            return false;
          if (filterSelected.min_size && t.area < parseInt(filterSelected.min_size))
            return false;
          if (filterSelected.max_size && t.area > parseInt(filterSelected.max_size))
            return false;
          return true;
        }).sort((a, b) => b['ts'] - a['ts']);
        if (tx.length === 0) return null;
        aggPsf[i][j] = tx[0].unitPrice;
        return {
          Date: tx[0]['ts'],
          Price: tx[0].price,
          Area: tx[0].area,
          Psf: tx[0].unitPrice,
          sale: tx[0].type,
          unit: tx[0]['unit'],
          tx,
        };
      });
    });

    // generate table
    const rows = [];
    const headerRow = [];
    headerRow.push(
      <th
        key="hdr-X"
        className={`header-cell block-cc text-${ZOOM_OPTIONS[zoom].size}`}
      >
        Stack →
        <br />
        <span
          className="cursor-pointer"
          onClick={() => setDefaultOrder(!defaultOrder)}
        >
          Floor {defaultOrder ? '↓' : '↑'}
          {/* Storey {defaultOrder ? '↓' : '↑'} */}
        </span>
      </th>
    );
    for (let i = 0; i <= (resultsMeta.right[displayBlock] - resultsMeta.left[displayBlock]); i += 1) {
      headerRow.push(
        <th
          key={`hdr-${i}`}
          className={`header-cell block-tr text-${ZOOM_OPTIONS[zoom].size}`}
          {
            ...(
              isHdb
                ? {}
                : {
                  'data-tooltip-id': "unitsview-selection-tooltip",
                  'data-tooltip-content': "Unit number is hidden",
                  'data-tooltip-variant': "dark",
                  'data-tooltip-place': "top"
                }
            )
          }
        >
          {isHdb
            ? getHdbUnitLabel(resultsMeta.left[displayBlock] + i)
            : (resultsMeta.left[displayBlock] + i)
          }
          <br />
          <span
            className="text-10"
            style={{
              fontWeight: 'normal',
              lineHeight: '1'
            }}
          >
            Avg PSF: ${getColPsfLabel(aggPsf, i)}
          </span>
        </th>
      );
    }
    rows.push(headerRow);

    const reverseColor = !!GRADIENT_OPTIONS.find(o => o.value === gradient).reverseColor;

    if (defaultOrder) {
      filteredResults.reverse();
    }

    filteredResults.forEach((row, i) => {
      const cells = [];
      cells.push(
        <th
          key={`rX-${i}`}
          className={`header-cell block-lc text-${ZOOM_OPTIONS[zoom].size}`}
        >
          {
            isHdb
              ? getHdbStoreyLabel(row)
              : (
                  defaultOrder ? (filteredResults.length - i + resultsMeta.bottom[displayBlock] - 1) : (i + resultsMeta.bottom[displayBlock])
                )
          }
          <br />
          <span
            className="text-10"
            style={{
              fontWeight: 'normal',
              lineHeight: '1'
            }}
          >
            Avg PSF: ${getRowPsfLabel(aggPsf, filteredResults.length - i - 1)}
          </span>
        </th>
      );
      row.forEach((col, j) => {
        cells.push(
          col === null
          ? <td
              key={`r${i}-${j}`}
              className={`data-cell data-cell-${ZOOM_OPTIONS[zoom].class} text-${ZOOM_OPTIONS[zoom].size} text-center`}
              style={{ background: 'white' }}
            >
              <span></span>
            </td>
          : <td
            key={`r${i}-${j}`}
            className={`data-cell data-cell-${ZOOM_OPTIONS[zoom].class} text-${ZOOM_OPTIONS[zoom].size} text-center cursor-pointer`}
            style={{
              background: getBlueHue(
                (col[gradient] - resultsMeta[`min${gradient}`]) / (resultsMeta[`max${gradient}`] - resultsMeta[`min${gradient}`]),
                reverseColor
              )
            }}
            data-bs-toggle="offcanvas"
            data-bs-target="#listingDetails"
            onClick={() => selectCell(col)}
          >
            {ZOOM_OPTIONS[zoom].class !== 'sm'
              && col.unit !== NA && <span className={`unit-text-${ZOOM_OPTIONS[zoom].size}`}>
                <strong>{
                  isHdb
                    ? col.unit
                    : censorUnit(col.unit)
                  }</strong>
                <br />
              </span>
            }
            
            {(ZOOM_OPTIONS[zoom].class !== 'sm' || gradient === 'Date')
              && <span className={`unit-text-${ZOOM_OPTIONS[zoom].size}`}>{convertDisplayDateMsec(col.Date)}<br /></span>
            }
            
            {(ZOOM_OPTIONS[zoom].class !== 'sm' || gradient === 'Area')
              &&<span className={`unit-text-${ZOOM_OPTIONS[zoom].size}`}>{col.Area.toLocaleString()} sft<br /></span>
            }
            
            {(ZOOM_OPTIONS[zoom].class !== 'sm' || gradient === 'Psf')
              && <span className={`unit-text-${ZOOM_OPTIONS[zoom].size}`}>${col.Psf.toLocaleString()} psf<br /></span>
            }

            {(ZOOM_OPTIONS[zoom].class !== 'sm' || gradient === 'Price')
              &&<span className={`unit-text-${ZOOM_OPTIONS[zoom].size}`}>${col.Price.toLocaleString()}</span>
            }
          </td>
        );
      });

      rows.push(<tr key={i}>{cells}</tr>);
    });
    
    setTableData(rows);
    setDataLoading(false);
  };

  useEffect(() => {
    renderTable(appliedFilter, defaultOrder);
  }, [results, zoom, gradient, displayBlock, defaultOrder]);

  const getBlockLabel = (address) => {
    if (checkMediaQuery()) return getBlock(address);
    return address;
  };

  return (
    <>
      <section className={`modal-table${isMaximized ? '-full' : ''}`}>
        <div className={`data-table ${noSelectClass(user)}`}>
          <section className={`${noSelectClass(user)} watermark`}>
            <div className="maxw">

              <div className="row y-gap-0 ml-0 mr-0 px-0">

                <div
                  className="offcanvas offcanvas-start filter-bar-nopad pb-10"
                  tabIndex="-1"
                  id="listingSidebar"
                >
                  <div className="offcanvas-header">
                    <h5 className="offcanvas-title text-18" id="offcanvasLabel">
                      Filter Data
                    </h5>
                    <button
                      id="filter-close-button"
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    ></button>
                  </div>

                  <div className="offcanvas-body">
                    <aside className="sidebar y-gap-40 xl:d-block">
                      <Sidebar
                        filters={filterSelected}
                        setFilters={setFilterSelected}
                        options={filterOptions}
                      />
                    </aside>
                  </div>

                  <div className="row ml-10 mr-10 mt-10">
                    <div className="col-3">
                      <button
                        className="button -dark-1 py-15 px-40 h-50 col-12 rounded-0 bg-red-1 text-white w-100"
                        onClick={onFilterReset}
                      >
                        Reset
                      </button>
                    </div>
                    <div className="col-9">
                      <button
                        className="button -dark-1 py-15 px-40 h-full col-12 rounded-0 bg-blue-1 text-white w-100"
                        onClick={onFilterApply}
                      >
                        Apply Filter
                      </button>
                    </div>
                  </div>

                </div>

                <div className="col-12 ml-0 mr-0 px-0" id="tx-table-view">
                  {dataLoading
                    && <div className="loader-container">
                      <Loader />
                    </div>
                  }

                  {!dataLoading && Object.keys(results).length > 0
                    && <>
                      
                      <div className="d-flex justify-content-end align-items-center mb-0 filter-bar-nopad proj-map-spad noselect">

                        <div className="p-2 d-flex filter-bar-nopad pt-0 pb-0">
                          <div className="dropdown js-dropdown js-category-active p-2 filter-bar-nopad proj-map-spad">
                            <div
                              className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-15 py-10 text-12 lh-12"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="true"
                              aria-expanded="false"
                              data-bs-offset="0,10"
                              data-tooltip-id="unitsview-selection-tooltip"
                              data-tooltip-content="Set gradient: higher value will appear dark blue"
                              data-tooltip-variant="dark"
                              data-tooltip-place="top"
                            >
                              <span className="js-dropdown-title">
                                <span className="text-blue-1 fw-600"></span>
                                <span className="js-dropdown-grad">
                                  Gradient: {GRADIENT_OPTIONS.find(o => o.value === gradient).label}
                                </span>
                              </span>
                              <i className="icon icon-chevron-sm-down text-7 ml-10" />
                            </div>
                            <div className="toggle-element -dropdown dropdown-menu">
                              <div className="text-13 y-gap-15 js-dropdown-list">
                                {GRADIENT_OPTIONS.map(option => (
                                  <div key={option.value}>
                                    <button
                                      className={`d-block js-dropdown-link ${
                                        gradient === option.value ? "text-blue-1 " : ""
                                      }`}
                                      onClick={() => {
                                        setGradient(option.value);
                                        document.querySelector(".js-dropdown-grad").textContent = option.label;
                                      }}
                                    >
                                      {option.label}
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          &nbsp;
                          <div className="dropdown js-dropdown js-category-active p-2 filter-bar-nopad proj-map-spad">
                            <div
                              className="dropdown__button dropdown__button_sm d-flex items-center align-items-center bg-white border-light rounded-100 px-15 py-10 text-12 lh-12"
                              data-bs-toggle="dropdown"
                              data-bs-auto-close="true"
                              aria-expanded="false"
                              data-bs-offset="0,10"
                              data-tooltip-id="unitsview-selection-tooltip"
                              data-tooltip-content="Select block or street number"
                              data-tooltip-variant="dark"
                              data-tooltip-place="top"
                            >
                              <span className="js-dropdown-title">
                                <span className="text-blue-1 fw-600"></span>
                                <span className="js-dropdown-res">{
                                  getBlockLabel(blocks.find(v => v.value === displayBlock).label)
                                }</span>
                              </span>
                              <i className="icon icon-chevron-sm-down text-7 ml-10" />
                            </div>
                            <div className="toggle-element -dropdown dropdown-menu project-search-dropdown">
                              <div className="text-12 y-gap-15 js-dropdown-list">
                                {blocks.map(option => (
                                  <div key={option.value}>
                                    <button
                                      className={`d-block js-dropdown-link ${
                                        displayBlock === option.value ? "text-blue-1 " : ""
                                      }`}
                                      onClick={() => {
                                        setDisplayBlock(option.value);
                                        document.querySelector(".js-dropdown-res").textContent = getBlockLabel(option.label);
                                      }}
                                    >
                                      {option.label}
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>

                        <button
                          className="p-2 block-filter dropdown__button_sm button rounded-100 button -blue-1 bg-blue-1-05 text-12 text-blue-1 p-2 ml-10 mr-0 mt-0 mb-0"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#listingSidebar"
                        >
                          <i className="icon-menu text-14 mr-10" />
                          Filter
                        </button>

                      </div>

                      <div className="d-flex flex-row-reverse noselect">
                        <button
                          className="button -dark-1 py-15 px-10 h-20 rounded-100 bg-blue-1 text-white"
                          data-tooltip-id="unitsview-selection-tooltip"
                          data-tooltip-content="Zoom out"
                          data-tooltip-variant="dark"
                          data-tooltip-place="bottom"
                          onClick={zoomOut}
                          disabled={zoom === ZOOM_OPTIONS.length - 1}
                        >
                          <i className="icon-plus text-12" />
                        </button>
                        <button
                          className="button -dark-1 py-15 px-10 h-20 rounded-100 bg-blue-1 text-white mr-10"
                          data-tooltip-id="unitsview-selection-tooltip"
                          data-tooltip-content="Zoom in"
                          data-tooltip-variant="dark"
                          data-tooltip-place="bottom"
                          onClick={zoomIn}
                          disabled={zoom === 0}
                        >
                          <i className="icon-minus text-12" />
                        </button>
                      </div>

                      <div className="blocks-w d-flex justify-content-center noselect">
                        <div className={`blocks-container ${mediaMatches && !isMaximized ? 'blocks-h-m' : (
                          isMaximized ? 'blocks-h-max' : 'blocks-h'
                        )} mt-0`}>
                          <table>
                            <thead>
                              {tableData}
                            </thead>
                          </table>
                        </div>
                      </div>
                    </>
                  }

                </div>
              </div>
            </div>
          </section>
        </div>
      </section>

      <Tooltip id="unitsview-selection-tooltip" />
    </>
  );
};

export default UnitsTab;
