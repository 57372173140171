export const convertTHeaderToApiCol = (headers) => headers.map(h => h.accessor);

export const includeProjectTHeader = (headers) => headers.find(v => v === 'Project Name')
  ? headers.filter(v => v !== 'id')
  : [...headers.filter(v => v !== 'id'), 'Project Name'];

  export const includeStreetTHeader = (headers) => headers.find(v => v === 'Street' || v === 'Street Name')
    ? headers
    : [...headers, 'Street'];

export const propertyTypeConvert = (type) => {
  if (type === 'Apt/Condo') return ['Condominium', 'Apartment'];
  if (type === 'Executive Condo') return 'Executive Condominium';
  if (type === 'Condo') return 'Condominium';
  if (type === 'Executive Condo') return 'Executive Condominium';
  if (type === 'Semi-Detached') return 'Semi-Detached House';
  if (type === 'Non-Landed') return 'Non-landed Properties';
  return type;
};

export const getPropTypeFilterSelected = (filterSelected, filter) => Object.keys(filterSelected[filter.field])
  .filter(o => filterSelected[filter.field][o])
  .flatMap(o => propertyTypeConvert(o));

const commaDelimToArr = (str) => {
  if (!str) return [];
  return str.split(',').filter(s => s);
};

const commaDelimToIntArr = (str) => {
  return commaDelimToArr(str).map(s => parseInt(s));
};

const commaDelimToFloatArr = (str) => {
  return commaDelimToArr(str).map(s => parseFloat(s));
};

export const convertSingleDataCommaDelim = (entry, strColumns = [], intColumns = [], floatColumns = []) => {
  const final = { ...entry };
  strColumns.forEach(col => {
    final[col] = commaDelimToArr(entry[col]);
  });
  intColumns.forEach(col => {
    final[col] = commaDelimToIntArr(entry[col]);
  });
  floatColumns.forEach(col => {
    final[col] = commaDelimToFloatArr(entry[col]);
  });
  return final;
};

export const convertDataCommaDelim = (data, strColumns = [], intColumns = [], floatColumns = []) => {
  return data.map(entry => convertSingleDataCommaDelim(entry, strColumns, intColumns, floatColumns));
};

export const convertPropertyData = (data) => {
  if (!data) return null;
  return convertSingleDataCommaDelim(
    data,
    [
      'types_rounded_sqft_available',
      'property_types',
      'tenure_available',
    ],
    [
      'last_10_psf_price'
    ]
  );
};

export const NA = 'N.A.';

const validTenureDate = (tenureDate) => {
  if (!tenureDate) return false;
  if (typeof tenureDate === 'string') return tenureDate !== '-' && tenureDate !== 'N.A.';
  return !isNaN(tenureDate);
};

export const calculateAgeYear = (tenureDate, completionYear, offsetYear = 0) => {
  let completion = 0;
  if (validTenureDate(tenureDate)) {
    completion = parseInt(tenureDate.split('-').slice(-1)[0]);
  } else if (!completionYear || isNaN(parseInt(completionYear))) {
    return null;
  } else {
    completion = parseInt(completionYear);
  }
  return new Date().getFullYear() - completion + (!offsetYear ? 0 : offsetYear);
};

export const calculateHdbAge = (completionYear) => 99 + parseInt(completionYear) - new Date().getFullYear() + 1;

export const calculateAge = (tenureDate, completionYear, offsetYear = 0) => {
  const age = calculateAgeYear(tenureDate, completionYear, offsetYear);
  if (age === null) return NA;
  return `${age} yr${age > 1 ? 's' : ''}`;
};

export const calculateLeaseRemainV2 = (tenures, date) => {
  if (!tenures || !date) return NA;
  const formattedTenures = tenures.map(s => parseInt(s)).filter(y => !isNaN(y));
  if (formattedTenures.length === 0) return NA;
  const tenure = Math.min.apply(Math, formattedTenures);
  const year = parseInt(date.split('-').slice(-1)[0]);
  const curr = new Date().getFullYear();
  const remain = tenure - (curr - year);
  return `${remain} yr${remain > 1 ? 's' : ''}`;
};

export const calculateLeaseRemain = (tenures, date) => {
  if (!tenures || !date) return NA;
  const formattedTenures = tenures.split(',').filter(s => !!s)
    .map(s => parseInt(s)).filter(y => !isNaN(y));
  if (formattedTenures.length === 0) return NA;
  const tenure = Math.min.apply(Math, formattedTenures);
  const year = parseInt(date.split('-').slice(-1)[0]);
  const curr = new Date().getFullYear();
  const remain = tenure - (curr - year);
  return `${remain} yr${remain > 1 ? 's' : ''}`;
};

export const deepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

const toRad = (value) => value * Math.PI / 180;

const toDeg = (radians) => radians * (180 / Math.PI);

// calculate the distance between 2 coordinates (lat, lng) in meters
export const calculateDist = (lat, lng, vlat, vlng) => {
  const R = 6371; // km
  const dLat = toRad(vlat-lat);
  const dLon = toRad(vlng-lng);
  const lat1 = toRad(lat);
  const lat2 = toRad(vlat);

  const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2); 
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  const d = R * c;
  return d * 1000;
};

export const getMidpoint = (lat1, lng1, lat2, lng2) => {
  const lat1Rad = toRad(lat1);
  const lng1Rad = toRad(lng1);
  const lat2Rad = toRad(lat2);
  const lng2Rad = toRad(lng2);

  const dLng = lng2Rad - lng1Rad;

  const bx = Math.cos(lat2Rad) * Math.cos(dLng);
  const by = Math.cos(lat2Rad) * Math.sin(dLng);

  const midLat = Math.atan2(
    Math.sin(lat1Rad) + Math.sin(lat2Rad),
    Math.sqrt((Math.cos(lat1Rad) + bx) ** 2 + by ** 2)
  );
  const midLng = lng1Rad + Math.atan2(by, Math.cos(lat1Rad) + bx);

  return {
    lat: toDeg(midLat),
    lng: toDeg(midLng),
  };
};

// create a distance (in meter) label with units (auto format between meter and km) 
export const formatDistanceLabel = (dist, needSpace) => {
  if (dist >= 1000) return `${(dist / 1000).toFixed(1)}${needSpace ? ' ' : ''}km`;
  return `${dist.toFixed(0)}${needSpace ? ' ' : ''}m`;
};

export const generateGMapUrl = (name, lat, lng) => {
  if (lat && lng) {
    return `https://maps.google.com/?q=${lat},${lng}`;
  }
  return `https://maps.google.com/?q=${name}`;
};

export const getFloorFromAddress = (address) => {
  const regex = /#(\d+)-/;
  const match = address.match(regex);
  if (match && match[1]) {
    const floor = parseInt(match[1]);
    if (!isNaN(floor)) return floor;
  }
  return null;
};

export const filterTHeadersByAccessors = (headers, accessors) => {
  if (!accessors) return headers;
  return headers.filter(h => accessors.has(h.accessor));
};

export const appendCountToTHeaders = (headers) => {
  return [...headers, {
    Header: 'Volume',
    accessor: 'count',
    disableGlobalFilter: true,
    disableSortBy: true,
    pageLevelSort: true,
    width: 80,
    Cell: (row) => <div>{row.cell.value?.toLocaleString()}</div>
  }];
};

export const convertStrToMoney = (amount) => amount?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const convertFloorAreaRange = (value) => {
  if (!value) return null;
  const chunks = value.split('-');
  const range = [parseInt(chunks[0]).toLocaleString()];
  if (chunks.length === 2 && chunks[1] !== chunks[0]) range.push(parseInt(chunks[1]).toLocaleString());
  return range.join(' - ');
};
