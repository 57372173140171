import { useState } from 'react';
import PropTypes from "prop-types";
import CustomTable from '@/components/transactions/CustomTable';
import { NA } from '@/utils/convert';
import { STANDARD_PAGE_CONFIG } from '@/utils/table';
import { MAP_MODE_HDB, getProfitableTextClass } from '@/utils/map';

const AreaTable = ({
  data,
  goToProperty,
  userConfig,
  mapMode
}) => {
  const DEFAULT_COLUMNS = [
    {
      Header: mapMode === MAP_MODE_HDB ? 'HDB' : 'Project',
      accessor: mapMode === MAP_MODE_HDB ? 'name' : 'label',
      disableGlobalFilter: true,
      Cell: (row) =>
        <a
          className="text-blue-1 cursor-pointer"
          onClick={() => goToProperty(row)}
        >
          {row.cell.value}
        </a>
    },
    {
      Header: 'Profitable (%)',
      accessor: 'profitable',
      disableGlobalFilter: true,
      width: 50,
      Cell: (row) => <div className={getProfitableTextClass(userConfig.profitableColor, row.cell.value)}>
        {isNaN(parseFloat(row.cell.value)) || row.cell.value === -1 ? NA : `${row.cell.value.toFixed(1)}%`}
      </div>
    },
    {
      Header: 'Transactions',
      accessor: 'totalTx',
      disableGlobalFilter: true,
      width: 50,
      Cell: (row) => row.cell.value.toLocaleString()
    },
  ];

  const [fullColumns, setFullColumns] = useState(DEFAULT_COLUMNS.map(col => ({ ...col, enabled: true })));
  const [columns, setColumns] = useState(DEFAULT_COLUMNS);

  return (
    <CustomTable
      data={data}
      tableConfig={STANDARD_PAGE_CONFIG}
      defaultColumns={DEFAULT_COLUMNS}
      fullColumns={fullColumns}
      setFullColumns={setFullColumns}
      columns={columns}
      setColumns={setColumns}
      hideSearchInput
      hidePageButton
      hidePage
      pageLimit={1000}
      disableCustomColumns={true}
    />
  );
};

AreaTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default AreaTable;
