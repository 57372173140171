const MenuButton = ({
  img,
  color,
  label,
  size,
  onClick
}) => {
  return (
    <button
      className={`col-md-${size} col-${size*2} py-5 cursor-pointer home-btn`}
      onClick={() => {
        onClick?.();
      }}
    >
      <img
        src={`/img/general/b_${img}.png`}
        height={50}
        width={50}
      />
      <div className="text-12 mt-5 fw-600" style={{ color }}>{label}</div>
    </button>
  );
};

export default MenuButton;
