import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/functions';

import {
  getFunctions,
  httpsCallable
} from "firebase/functions";
import { getAnalytics } from "firebase/analytics";
import {
  getStorage,
  ref,
  getBlob
} from "firebase/storage";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider
} from "firebase/app-check";
import {
  connectDataConnectEmulator,
  executeQuery,
  executeMutation,
  getDataConnect
} from 'firebase/data-connect';
import {
  getFirestore,
  doc,
  setDoc,
  deleteDoc,
  updateDoc
} from "firebase/firestore";

import {
  connectorConfig,
  listLikesRef,
  getLikeByIdRef,
  createPropertyLikeRef,
  deletePropertyLikeRef
} from '../../dataconnect-generated/js/default-connector';

import { isDevMode } from './dev';

export const DEBOUNCE_TIMING = 100;

const REGION = 'asia-southeast1';

// setup firebase
const firebaseConfig = {
  apiKey: "AIzaSyA5I7E4wezPbv-133OrRf4iSjFO0HHKoM4",
  authDomain: "realsmart.sg",
  projectId: "realsmart-main",
  storageBucket: "realsmart-main.appspot.com",
  messagingSenderId: "1010308007737",
  appId: "1:1010308007737:web:9f349bdd74d0ebe56e8ae8",
  measurementId: "G-H9XW5H3Y3M"
};

// initialize firebase app
const app = firebase.initializeApp(firebaseConfig);

// initialize storage
const storage = getStorage(app);

// initialize firestore
const firestoreDb = getFirestore(app);

// setup dev env for firebase
if (isDevMode) {
  // set functions to use local emulator
  const functions = firebase.app().functions(REGION);
  functions.useEmulator('127.0.0.1', '5001');

  // set app check with debug provider
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

  // use local emulator for data connect
  // const dataConnect = getDataConnect(connectorConfig);
  // connectDataConnectEmulator(dataConnect, 'localhost', 9399);

  // disable Umami analytics for dev if not already set
  // if (!localStorage.getItem('umami.disabled')) {
  //   localStorage.setItem('umami.disabled', 1);
  // }
} else {
  // initialize analytics for non-dev
  getAnalytics(app);
}

// initialize app check
initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider('6Lf8dgMqAAAAAONjse8fQO-z_GB8cj1PzkiOykHg'),
  isTokenAutoRefreshEnabled: true
});

// api for posts create/update/delete

export const createPost = async (id, data, callback) => {
  await setDoc(doc(firestoreDb, 'posts', id), data);
  callback?.();
};

export const updatePost = async (id, data, callback) => {
  await updateDoc(doc(firestoreDb, 'posts', id), data);
  callback?.();
};

export const deletePost = async (id, callback) => {
  await deleteDoc(doc(firestoreDb, 'posts', id));
  callback?.();
};

// api for likes

export const getPropertyLikes = async (user, callback, limit, offset = 0) => {
  const ref = listLikesRef({ uid: user.claims.user_id, offset, limit });
  const { data } = await executeQuery(ref);
  callback?.(data.likes);
};

export const getPropertyLike = async (pid, callback) => {
  const ref = getLikeByIdRef({ pid });
  const { data } = await executeQuery(ref);
  callback?.(data.like);
};

export const likeProperty = async (pid, data) => {
  const ref = createPropertyLikeRef({ pid, data: JSON.stringify(data) });
  await executeMutation(ref);
};

export const unlikeProperty = async (pid) => {
  const ref = deletePropertyLikeRef({ pid });
  await executeMutation(ref);
};

/* analytics */

export const trackPageView = (title, data = {}) => {
  if (window.umami && typeof window.umami.track === 'function') {
    const { pathname, search } = window.location;
    const fullPath = `${pathname}${search}`;
    window.umami.track({
      ...data,
      title,
      url: fullPath,
      website: '03ca7ff0-525b-41fd-aef7-f37472bd118c'
    });
  }
};

export const trackEvent = (title, data = {}) => {
  if (window.umami && typeof window.umami.track === 'function') {
    window.umami.track(title, {
      ...data,
      website: '03ca7ff0-525b-41fd-aef7-f37472bd118c'
    });
  }
};

/**
 * File fetch
 */

export const getFile = (dir, filename, onSuccess, onError) => {
  fetch(`https://realsmart.global.ssl.fastly.net/v/${dir}/${filename}`)
  .then(resp => {
    return resp.text();
  }).then(txt => {
    onSuccess(txt);
  }).catch(err => {
    console.log('Error', err);
    onError?.(err);
  });
};

export const getJsonFile = (dir, filename, onSuccess, onError) => {
  fetch(`https://realsmart.global.ssl.fastly.net/v/${dir}/${filename}`)
  .then(resp => {
    return resp.json();
  }).then(json => {
    onSuccess(json);
  }).catch(err => {
    console.log('Error', err);
    onError?.(err);
  });
};

/* autocomplete */

export const searchQuery = (term, limit, onSuccess, onError, specificType = null, additionalFilters = null) => {
  const host = 'realsense.global.ssl.fastly.net';
  const apiKey = '5XRXUw4HPHlAJ4EmJWVsqryAeGH4glsP';
  const headers = {
    'X-TYPESENSE-API-KEY': apiKey
  };
  const sanitizedTerm = term.replace('#', '');
  const typeFilter = specificType
    ? (additionalFilters ? `&filter_by=type:=${specificType}&&${additionalFilters}` : `&filter_by=type:=${specificType}`)
    : (additionalFilters ? `&filter_by=${additionalFilters}` : '');
  // const typeFilter = specificType ? `&filter_by=type:=${specificType}&&subtype:!=HDB` : '&filter_by=subtype:!=HDB';
  const url = `https://${host}/collections/searchv2/documents/search?q=${sanitizedTerm}&query_by=marker,names,street,postal&sort_by=_text_match:desc&per_page=${limit}&prefix=true&num_typos=3&split_join_tokens=always${typeFilter}`;
  fetch(url, { headers }).then(response => {
    return response.json();
  }).then(data => {
    onSuccess?.(data.hits?.map(v => v['document']) ?? []);
  }).catch(err => {
    onError?.(err);
  });
};

/**
 * API Functions
 */

const requestFunc = (func, params, onSuccess, onError) => {
  const request = httpsCallable(getFunctions(app, REGION), func);
  request(params)
  .then((result) => {
    let err = result.err ?? result.error;
    if (err) {
      onError?.(err);
    } else {
      const data = result.data;
      err = data.err ?? data.error;
      if (err) {
        onError?.(err);
      } else {
        onSuccess?.(data);
      }
    }
  }).catch((err) => {
    if (onError) {
      let errStr = err.toString();
      if (errStr.toLowerCase() === 'FirebaseError: internal'.toLowerCase()) {
        errStr = 'Internal error'
      }
      onError?.(errStr);
    } else {
      console.log('Error', err);
      onError?.(err);
    }
  });
};

// export const debugCall = (params, onSuccess, onError) => {
//   requestFunc('debug_user', params, onSuccess, onError);
// }

// export const addSuperUser = (email, onSuccess, onError) => {
//   requestFunc('debug_user', {
//     e: email,
//   }, onSuccess, onError);
// }

// export const getUserInfo = (onSuccess, onError) => {
//   requestFunc('get_user', {}, onSuccess, onError);
// };

// export const getProfitsTable = (params, onSuccess, onError) => {
//   requestFunc('query_profits', params, onSuccess, onError);
// };

// export const getTransactionsTable = (params, onSuccess, onError) => {
//   requestFunc('query_transactions', params, onSuccess, onError);
// };

// export const getRentalsTable = (params, onSuccess, onError) => {
//   requestFunc('query_rentals', params, onSuccess, onError);
// };

// export const getUserHistory = (type, onSuccess) => {
//   requestFunc('get_user_config', {
//     t: type,
//   }, onSuccess);
// };

// export const saveUserHistory = (type, newConfig) => {
//   requestFunc('set_user_config', {
//     t: type,
//     c: newConfig,
//   });
// };

// export const getUserPaymentId = (onSuccess, onError) => {
//   requestFunc('get_stripe_session', {}, onSuccess, onError);
// };

// export const refreshPaymentStatus = (params, onSuccess, onError) => {
//   requestFunc('refresh_payment_status', params, onSuccess, onError);
// };

// export const getBillingPortalLink = (onSuccess, onError) => {
//   requestFunc('get_billing_portal_link', {}, onSuccess, onError);
// };

/* social */

// export const getPostsAPI = (propertyId, params, onSuccess, onError) => {
//   requestFunc('get_posts', {
//     ...params,
//     id: propertyId,
//     type: 'p',
//   }, onSuccess, onError);
// };

// export const getRepliesAPI = (propertyId, postId, params, onSuccess, onError) => {
//   requestFunc('get_posts', {
//     ...params,
//     id: propertyId,
//     type: 'p',
//     post: postId,
//   }, onSuccess, onError);
// };

// export const createPostAPI = (propertyId, content, onSuccess, onError) => {
//   requestFunc('create_post', {
//     id: propertyId,
//     type: 'p',
//     content,
//   }, onSuccess, onError);
// };

// export const createReplyAPI = (propertyId, postId, content, onSuccess, onError) => {
//   requestFunc('create_post', {
//     id: propertyId,
//     type: 'r',
//     content,
//     post: postId,
//   }, onSuccess, onError);
// };

// export const deletePostAPI = (postId, onSuccess, onError) => {
//   requestFunc('delete_post', {
//     id: postId,
//     type: 'p',
//   }, onSuccess, onError);
// };

// export const deleteReplyAPI = (replyId, onSuccess, onError) => {
//   requestFunc('delete_post', {
//     id: replyId,
//     type: 'r',
//   }, onSuccess, onError);
// };

// export const votePostAPI = (postId, score, onSuccess, onError) => {
//   requestFunc('vote', {
//     id: postId,
//     type: 'p',
//     score,
//   }, onSuccess, onError);
// };

// export const voteReplyAPI = (replyId, score, onSuccess, onError) => {
//   requestFunc('vote', {
//     id: replyId,
//     type: 'r',
//     score,
//   }, onSuccess, onError);
// };

/* analytics */

export const logAnalytics = (event, session, params) => {
  if (isDevMode) {
    console.log('LOG', event, session, params);
    return;  // do not log for development
  }
  requestFunc('log_analytics', {
    params,
    event,
    session,
  });
};

export const logPropSearchFilter = (session, params) => {
  logAnalytics('PROPERTY_FILTER', session, params);
};

export const logNameSearch = (page, session, input) => {
  logAnalytics(`${page}_NAME_SEARCH`, session, { input });
};

export const logLanding = (session, user) => {
  logAnalytics('LANDING', session, Object.keys(user).length > 0 ? {
    user: {
      id: user?.claims?.user_id,
      name: user?.claims?.name,
      email: user?.claims?.email,
    },
    type: 'member'
  } : {
    type: 'guest'
  });
};

export const logLogin = (session, user) => {
  logAnalytics('LOGIN', session, {
    user: {
      id: user?.multiFactor?.user?.uid,
      name: user?.multiFactor?.user?.displayName,
      email: user?.multiFactor?.user?.email,
    },
    type: 'member'
  });
};

export const logPageView = (page, session) => {
  logAnalytics(`${page}_VIEW`, session, null);
};

export const logPropertyTabView = (tab, session, marker, project) => {
  logAnalytics(`DETAIL_${tab}_VIEW`, session, { project });
};

export const logProfitSearchFilter = (session, params) => {
  logAnalytics('PROFIT_FILTER', session, params);
};

export const logTransactionSearchFilter = (session, params) => {
  logAnalytics('TRANSACTION_FILTER', session, params);
};

export const logRentalSearchFilter = (session, params) => {
  logAnalytics('RENTAL_FILTER', session, params);
};

export const logMapMarkerSelect = (session, params) => {
  logAnalytics('MAP_MARKER', session, params);
};

export const logComparePro = (session, params) => {
  logAnalytics('MAP_COMPARE_PRO', session, params);
};

/**
 * Storage
 */

export const downloadFileUrl = (folder, filekey, extension, onSuccess, onError) => {
  const fileRef = ref(storage, `${folder}/${filekey}${extension ? `.${extension}` : ''}`);
  getBlob(fileRef)
    .then((blob) => {
      const url = URL.createObjectURL(blob);
      onSuccess(url);
    })
    .catch((err) => {
      return onError('Property does not exist');
    });
};
