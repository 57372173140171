import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import UserPanel from "../UserPanel";

import MobileMenu from "../MobileMenu";
import Menu from "../Menu";
import MobileNav from "../MobileNav";
import { getUserPhotoUrl, isGuest, isLoggedIn } from "@/utils/user";
import NotificationPanel from "@/components/account/NotificationPanel";

const Header1 = ({
  user,
  session,
  hideUserButton,
  goToProperty,
  goToHdb,
  onUnlikeProperty,
  isMap = false
}) => {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 10) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  const isUserLoggedIn = isLoggedIn(user);
  const isUserGuest = isGuest(user);

  const isSmallScreen = window.innerWidth < 400;

  return (
    <>
      <header className={`header front-header bg-white ${navbar ? "is-sticky" : ""} slim-header noselect`}>
        <div className="header__container px-20 sm:px-20">
          <div className="row justify-between items-center">
            <div className="col-auto">
              <div className="d-flex items-center">
                <Link to="/" className="header-logo mr-20">
                  <img src="/img/general/logo-rs-light.png" alt="logo icon" />
                </Link>

                {!isSmallScreen
                  && <div className="text-dark-1 fw-600 text-16 d-none xl:d-flex noselect">
                    REALSMART.SG
                  </div>
                }

                <div className="header-menu">
                  <div className="header-menu__content">
                    <Menu style="text-dark-1" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-auto">
              <div className="d-flex items-center">
                <div className="row x-gap-20 items-center xl:d-none">

                  {/* Start vertical devider*/}
                  {/* <div className="col-auto">
                    <div className="w-1 h-20 bg-white-20" />
                  </div> */}
                  {/* End vertical devider*/}

                  {isUserLoggedIn
                    && <UserPanel
                      textClass="text-dark-1"
                      user={user}
                      session={session}
                      goToProperty={goToProperty}
                      goToHdb={goToHdb}
                      onUnlikeProperty={onUnlikeProperty}
                      isMap={isMap}
                    />
                  }
                </div>

                <div className="d-flex items-center ml-20 is-menu-opened-hide xl:d-none">
                  {isUserGuest
                    && <Link
                      to="/login"
                      className="button px-30 fw-400 text-14 -outline-blue-1 h-50 text-blue-1 ml-20"
                    >
                      Sign In / Register
                    </Link>
                  }
                </div>

                <div className="d-flex items-center is-menu-opened-hide xl:d-none">
                  {isUserLoggedIn
                    && <NotificationPanel
                      textClass="text-dark-1"
                      user={user}
                      session={session}
                    />
                  }
                  <Link
                    className="p-2"
                    to="/contact"
                  >
                    <img className="ic_info" src="/img/general/info_dark.svg" />
                  </Link>
                </div>

                <div className="d-none xl:d-flex x-gap-20 items-center pl-30 text-dark-1">
                  <div>
                    {/* TODO */}
                    {!hideUserButton && isUserGuest
                      && <Link
                        to="/login"
                        className="d-flex items-center icon-user text-inherit text-22"
                      />
                    }
                  </div>
                  {isUserLoggedIn
                    && <NotificationPanel
                      textClass="text-dark-1"
                      user={user}
                      session={session}
                    />
                  }
                  <div>
                    <button
                      data-bs-toggle="offcanvas"
                      aria-controls="mobile-sidenav_menu"
                      data-bs-target="#mobile-sidenav_menu"
                    >
                      <img className="ic_menu" src="/img/general/menu_dark.svg" />
                    </button>

                    <div
                      className="offcanvas offcanvas-start mobile_menu-contnet"
                      tabIndex="-1"
                      id="mobile-sidenav_menu"
                      aria-labelledby="offcanvasNavLabel"
                      data-bs-scroll="true"
                    >
                      <MobileNav user={user} />
                    </div>
                  </div>
                  <div>
                    {isUserLoggedIn
                      && <img
                        src={getUserPhotoUrl(user)}
                        alt="image"
                        referrerPolicy="no-referrer"
                        className="rounded-full mr-10"
                        height={30}
                        width={30}
                        data-bs-toggle="offcanvas"
                        aria-controls="mobile-sidebar_menu"
                        data-bs-target="#mobile-sidebar_menu"
                      />
                    }

                    <div
                      className="offcanvas offcanvas-end mobile_menu-contnet"
                      tabIndex="-1"
                      id="mobile-sidebar_menu"
                      aria-labelledby="offcanvasMenuLabel"
                      data-bs-scroll="true"
                    >
                      <MobileMenu
                        user={user}
                        goToProperty={goToProperty}
                        goToHdb={goToHdb}
                        onUnlikeProperty={onUnlikeProperty}
                        isMap={isMap}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </header>
    </>
  );
};

Header1.propTypes = {
  user: PropTypes.object,
  hideUserButton: PropTypes.bool,
};

Header1.defaultProps = {
  hideUserButton: false,
};

export default Header1;
