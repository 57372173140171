import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";

const homeItems = [
  // {
  //   name: "Property Search",
  //   routePath: "/search",
  //   // default: true
  // },
  {
    name: "Home",
    routePath: "/",
    default: true
  },
  {
    name: "Map",
    routePath: "/map",
  },
  // {
  //   name: "Compare",
  //   routePath: "/compare",
  // },
  // {
  //   name: "Profits",
  //   routePath: "/profits",
  // },
  // {
  //   name: "Transaction",
  //   routePath: "/transactions",
  // },
  // {
  //   name: "Rental",
  //   routePath: "/rentals",
  // },
  // {
  //   name: "Units View",
  //   routePath: "/units",
  // },
  // {
  //   name: "Upcoming",
  //   routePath: "/upcoming",
  // },
  {
    name: "About Us",
    routePath: "/about",
  },
];

const MobileNav = ({ user }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isSelected = (item) => pathname === item.routePath || (pathname === '/' && item.default);

  return (
    <>
      <div className="pro-header d-flex align-items-center justify-between border-bottom-light">
        <p></p>

        <div
          className="fix-icon"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <i className="icon icon-close"></i>
        </div>
        {/* icon close */}
      </div>
      {/* End pro-header */}

      <Sidebar width="400" backgroundColor="#fff">
        <Menu>
          {homeItems.map((item, i) => (
              <MenuItem
                key={i}
                onClick={() => {
                  if (!isSelected(item))
                    navigate(item.routePath);
                }}
                className={
                  isSelected(item)
                    ? "menu-active-link"
                    : "inactive-menu"
                }
                data-bs-dismiss="offcanvas"
              >
                {item.name}
              </MenuItem>
          ))}
        </Menu>
      </Sidebar>
    </>
  );
};

MobileNav.propTypes = {
  user: PropTypes.object,
};

export default MobileNav;
