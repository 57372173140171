import LoginBox from "./LoginBox";

const LoginPopup = ({
  show,
  setShow,
  session,
  onLoggedIn
}) => {
  const handleCarding = () => {
    setShow((prevState) => !prevState);
  };

  return (
    <div className={`langMenu langMenuTop js-langMenu ${show ? "" : "is-hidden"}`}>
      <div className="currencyMenu__bg" onClick={handleCarding}></div>
      <div className="langMenu__content bg-white rounded-4 rel-content pb-20">
        <div className="d-flex items-center flex-row-reverse px-30 py-10 sm:px-15">
          <button className="pointer" onClick={handleCarding}>
            <i className="icon-close" />
          </button>
        </div>
        <LoginBox session={session} onLoggedIn={onLoggedIn} />
      </div>
    </div>
  );
};

export default LoginPopup;
