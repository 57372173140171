import { useState, useEffect, useCallback } from "react";
import {
  useNavigate,
  useLocation,
  useSearchParams
} from "react-router-dom";
import { debounce } from 'lodash';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import DefaultHeader from "@/components/header/default-header";
import MetaComponent from "@/components/common/MetaComponent";
import Loader from "@/components/common/Loader";
import FilterBox from "@/components/mapv2/search/FilterBox";
import Carousel from "@/components/newsfeed/Carousel";
import Post from "@/components/newsfeed/Post";
import PostView from "@/components/newsfeed/PostView";
import { checkMediaQuery } from "@/utils/user";
import MenuButton from "@/components/newsfeed/MenuButton";
import BotPanel from "@/components/mapv2/BotPanel";

const DEFAULT_METADATA = {
  title: "REALSMART.SG | Latest Property News | Supercharge your property search",
  description: "REALSMART.SG - Supercharge your property search",
};

// generate random background gradients if necessary
const generateRandomBgId = () => Math.floor(Math.random() * 8) + 1;

const mock = [
  {
    id: 0,
    type: 'post',
    img: '/img/gallery/1/1.png',
    gallery: ['/img/gallery/1/1.png', '/img/gallery/1/1.png', '/img/gallery/1/1.png'],
    author: 'Tester1',
    avatar: '/img/general/navi.png',
    likes: 100,
    text: 'Test',
    tags: [
      {
        id: 'abc',
        label: 'ABC',
        type: 'g'
      },
      {
        id: 'def',
        label: 'DEF',
        type: 'g'
      }
    ],
    createdAt: 1737601200000,
    bgId: generateRandomBgId()
  },
  {
    id: 1,
    type: 'post',
    img: '/img/gallery/1/2.png',
    author: 'Tester2',
    text: 'This is just a Very very Very very Very very Very very very long long Test text that is meant for testing test',
    likes: 100,
    createdAt: 1737561600000,
    bgId: generateRandomBgId()
  },
  {
    id: 2,
    type: 'post',
    author: 'REALSMART',
    avatar: '/rs.png',
    text: 'This is just a Very very Very very Very very Very very very long long Test text that is meant for testing test',
    likes: 100,
    createdAt: 1737302400000,
  },
  {
    id: 3,
    type: 'post',
    author: 'Tester4',
    avatar: '/img/general/navi.png',
    text: 'This is just a Very very Very very Very very Very very very long long Test text that is meant for testing test to test This is just a Very very Very very Very very Very very very long long Test text that is meant for testing test',
    likes: 100,
    createdAt: 1735660800000,
    bgId: generateRandomBgId()
  },
  {
    id: 4,
    type: 'post',
    img: '/img/avatars/1.png',
    author: 'Avatar',
    avatar: '/img/general/navi.png',
    likes: 100,
    createdAt: 1734624000000,
    bgId: generateRandomBgId()
  },
];

const clonedMock = Array(10).fill(mock).flat();

// const clonedMock = Array(10).fill(mockData).flat();


const NewsfeedPage = ({
  user,
  session
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);

  const [selectedPost, setSelectedPost] = useState(null);

  const [showBotPanelTs, setShowBotPanelTs] = useState(null);

  const [lightboxImages, setLightboxImages] = useState(null);

  const [observer, setObserver] = useState(null);
  let fetching = false;

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const updateScreenSize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", updateScreenSize);
    window.addEventListener("orientationchange", updateScreenSize);

    return () => {
      window.removeEventListener("resize", updateScreenSize);
      window.removeEventListener("orientationchange", updateScreenSize);
    };
  }, []);

  const onSearch = (selected) => {
    // TODO
  };

  const onClickPost = (post) => {
    // TODO
    setSelectedPost(post);
  };

  const parseData = (data) => data ? data.map(d => {
    const jsonData = JSON.parse(d.data);
    return {
      id: d.id,
      type: 'post',
      author: d.system > 0 ? 'REALSMART' : d.author.name,
      authorId: d.system > 0 ? '0' : d.author.id,
      avatar: d.system > 0 ? '/rs.png' : d.author.avatar,
      likes: d.likes,
      views: d.views,
      comments: d.comments,
      tags: d.tags,
      text: jsonData.text,
      img: jsonData.imgs ? jsonData.imgs[0] : null,
      gallery: jsonData.imgs ? (
        jsonData.imgs.length === 1
         ? null
         : jsonData.imgs
      ) : null,
      createdAt: d.createdAt,
      updatedAt: d.updatedAt,
    };
  }) : [];

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = () => {
    if (fetching) return;
    fetching = true;
    console.log('test????', 'load')
    setTimeout(() => {
      setData(prev => [...prev, ...clonedMock]);
      fetching = false;
    }, 800);
  };

  const onDebouncedLoad = useCallback(debounce(onLoad, 200), []);

  const onSeenPost = (idx) => {
    if (!idx || data.length < 50) return;
    if (idx >= (data.length - 25)) {
      fetching = true;
      onDebouncedLoad();
    }
  };

  const onRefresh = () => {
    console.log('test????', 'refresh')
  };

  const mediaQuery = checkMediaQuery();

  return (
    <>
      <MetaComponent meta={DEFAULT_METADATA} />
      <div className="header-margin"></div>
      <DefaultHeader user={user} />

      <div className="py-10 bg-dark-2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <FilterBox
                onSearch={onSearch}
                hint="Search for post tags, users, and properties by project, street or postal code"
              />
            </div>
          </div>
        </div>
      </div>

      {loading && <div className="loader-container"><Loader /></div>}

      {!loading
        && <div className="watermark">

          <div className="newsfeed-content">
            {/* carousel */}
            <Carousel
              onClickItem={(item) => {
                if (item.s) {
                  navigate(`/map?s=${encodeURIComponent(item.n)}`);
                }
              }}
            />

            {/* buttons */}
            <div className="mt-5 mb-5 container">
              <div className="row px-10 pt-5 pb-10">
                <div
                  className="col-8 flex-grow-1 px-0"
                  style={{
                    position: 'relative',
                    color: '#6c5ce7'
                  }}
                >
                  <div className="row ml-5 mr-5 pt-15 px-10"
                    style={{
                      border: '2px solid #6c5ce7',
                      borderRadius: '12px',
                      position: 'relative'
                    }}
                  >
                    <MenuButton img="map" color="#6c5ce7" size={3} label="Map" />
                    <MenuButton img="notebook" color="#6c5ce7" size={3} label="Activity" />
                    <MenuButton img="user" color="#6c5ce7" size={3} label="Follows" />
                    <MenuButton img="robot" color="#6c5ce7" size={3} label="Assistant" onClick={() => setShowBotPanelTs(new Date())} />
                  </div>
                  <div
                    className="text-12 fw-600"
                    style={{
                      position: 'absolute',
                      top: '-15px',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      background: 'white',
                      padding: '6px',
                      fontWeight: 'bold',
                      borderRadius: '8px',
                    }}
                  >
                    VIEW
                  </div>
                </div>
                <div
                  className="col-4 flex-grow-1 px-0"
                  style={{
                    position: 'relative',
                    color: '#e84393'
                  }}
                >
                  <div className="row ml-5 mr-5 pt-15 px-10"
                    style={{
                      border: '2px solid #e84393',
                      borderRadius: '12px',
                      position: 'relative'
                    }}
                  >
                    <MenuButton img="house" color="#e84393" size={6} label="Properties" />
                    <MenuButton img="chat" color="#e84393" size={6} label="Posts" />
                  </div>
                  <div
                    className="text-12 fw-600"
                    style={{
                      position: 'absolute',
                      top: '-15px',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      background: 'white',
                      padding: '6px',
                      fontWeight: 'bold',
                      borderRadius: '8px',
                    }}
                  >
                    LIKED
                  </div>
                </div>
              </div>
            </div>

            {/* gallery */}
            <div className="d-flex justify-content-center">
              <div
                className="mb-10 container"
                style={{
                  overflowX: 'hidden'
                }}
              >
                <ResponsiveMasonry
                  columnsCountBreakPoints={{ 350: 2, 750: 3, 900: 4, 1000: 5 }}
                >
                  <Masonry gutter="8px">
                    {data.map((post, idx) => (
                      post.type === 'post'
                        ? <Post
                            id={idx}
                            content={post}
                            onClick={onClickPost}
                            onSeenPost={onSeenPost}
                          />
                        : <></>
                    ))}
                  </Masonry>
                </ResponsiveMasonry>

              </div>
            </div>

          </div>

        </div>
      }

      <div className={`langMenu langMenuTop js-langMenu newsfeed-view ${mediaQuery ? 'newsfeed-view-full' : ''} ${selectedPost ? "" : "is-hidden"}`}>
        <div className="currencyMenu__bg" onClick={() => setSelectedPost(null)}></div>
        <div className="langMenu__content bg-white rounded-4 rel-content">
          {selectedPost
            && <PostView
              content={selectedPost}
              onClose={() => setSelectedPost(null)}
              setLightboxImages={setLightboxImages}
            />
          }
        </div>
      </div>

      <BotPanel showBotPanelTs={showBotPanelTs} hidden />

      <Lightbox
        open={!!lightboxImages}
        close={() => setLightboxImages(null)}
        slides={lightboxImages?.gallery ?? []}
        index={lightboxImages?.idx ?? 0}
        portal={{ target: document.body }}
        plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
      />
    </>
  );
};

export default NewsfeedPage;
